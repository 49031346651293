import {
  GetAddressOutput,
  getAddress,
} from "@/shared/services/viacep-api/get-address";
import { useMutation } from "@tanstack/react-query";

type Payload = {
  cep: string;
};

export const useGetAddress = () => {
  return useMutation<GetAddressOutput, Error, Payload>({
    mutationFn: async ({ cep }) => {
      const { data } = await getAddress(cep);
      return data;
    },
  });
};
