import { FullscreenLoading } from "@/shared/components/loadings/fullscreen-loading";
import CreateEstablishmentDrawer from "@/shared/drawers/manage-establishment/create-establishment-drawer";
import { Establishment } from "@/shared/types/establishments";
import { StoreOutlined } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { Suspense, useState } from "react";

type Props = {
  establishments: Establishment[];
};

export const FabNewSpace = ({ establishments }: Props) => {
  const [showEstablishmentModal, setShowEstablishmentModal] = useState(false);

  const handleOpenEstablishmentModal = () => {
    setShowEstablishmentModal(true);
  };

  const handleCloseEstablishmentModal = () => {
    setShowEstablishmentModal(false);
  };

  return (
    <>
      <Fab
        color="primary"
        aria-label="add"
        className="bottom-20 right-4"
        style={{ position: "fixed" }}
        variant="extended"
        onClick={handleOpenEstablishmentModal}
      >
        <StoreOutlined className="mr-1" />
        Novo espaço
      </Fab>

      <Suspense fallback={<FullscreenLoading />}>
        <CreateEstablishmentDrawer
          open={showEstablishmentModal}
          onClose={handleCloseEstablishmentModal}
          hasEstablishment={establishments.length > 0}
        />
      </Suspense>
    </>
  );
};
