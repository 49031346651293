import { API_URL } from "@/shared/constants/env";
import { authenticatedAxios } from "../../fetch";
import { LinkInvitesStatus } from "../link-invites/list";

export type ListLinkedInvitesOutput = {
  companyId: string;
  establishmentId: string;
  linkedCompanyId: string;
  linkedEmail: string;
  status: LinkInvitesStatus;
  createdAt: string;
}[];

export const listLinkedInvites = async ({
  establishmentId,
}: {
  establishmentId: string;
}) =>
  (await authenticatedAxios()).get<ListLinkedInvitesOutput>(
    `${API_URL}/admin/establishments/${establishmentId}/linked-invites`
  );
