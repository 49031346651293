import { API_URL } from "@/shared/constants/env";
import { authenticatedAxios } from "../../fetch";

export type BodyPayload = {
  paymentMethods: {
    method: string;
    complement?: string;
  }[];
};

export const savePaymentMethods = async (
  establishmentId: string,
  { paymentMethods }: BodyPayload
) => {
  return (await authenticatedAxios()).post(
    `${API_URL}/admin/establishments/${establishmentId}/payment-methods`,
    paymentMethods
  );
};
