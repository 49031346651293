import { Establishment } from "@/shared/types/establishments";
import { createContext } from "react";

type ContextProps = {
  establishment: Establishment;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
};

export const AttendingContext = createContext<ContextProps>({
  establishment: {} as Establishment,
  selectedDate: new Date(),
  setSelectedDate: () => {},
});
