import { apiCustomer } from "@/shared/services/api";
import { useMutation } from "@tanstack/react-query";

type BodyPayload = {
  givenName: string;
  familyName: string;
  phoneNumber?: string;
  address?: string;
};

export const useUpdateProfile = () => {
  return useMutation<unknown, Error, BodyPayload>({
    mutationFn: async (payload) => {
      const http = await apiCustomer();
      return http.put("/profile", payload);
    },
  });
};
