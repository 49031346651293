import {
  EstablishmentConfigs,
  WorkingHourItem,
} from "@/shared/types/establishments";
import { useMutation } from "@tanstack/react-query";
import { createEstablishmentAction } from "@/shared/services/barx-api/establishments/create";
import { LocationFields } from "@/shared/drawers/manage-establishment/components/types";

type MutationParams = {
  name: string;
  location: LocationFields["location"];
  workingHour?: WorkingHourItem;
  configs?: EstablishmentConfigs;
};

export function useCreateEstablishment() {
  return useMutation<unknown, unknown, MutationParams>({
    mutationFn: ({ name, workingHour, location, configs }) => {
      return createEstablishmentAction({
        name,
        workingHour,
        location,
        configs,
      });
    },
  });
}
