import { updateVapidAction } from "@/shared/services/barx-api/companies/update-vapid";
import { Alert, AlertTitle, Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useLocalMessagingVapid } from "@/shared/hooks/use-local-messaging-vapid";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";

export const EnableNotification = () => {
  const [currentVapid, setCurrentVapid] = useState<string>();
  const { showSnackbar } = useGlobalSnackbar();
  const { requestMessagingToken } = useLocalMessagingVapid();
  const [manualRequest, setManualRequest] = useState(false);
  const [permissionState, setPermissionState] = useState<
    PermissionState | NotificationPermission
  >();

  const enableNotification = useCallback(() => {
    requestMessagingToken()
      .then((vapidToken) => {
        if (currentVapid === vapidToken) return;
        if (permissionState !== "granted") setPermissionState("granted");
        setCurrentVapid(vapidToken);
        updateVapidAction({ vapidToken })
          .then(() => {
            if (manualRequest) {
              showSnackbar({
                message: "Notificações habilitadas com sucesso",
                severity: "success",
              });
            }
          })
          .catch(() => {
            if (manualRequest) {
              showSnackbar({
                message: "Falha ao habilitar notificações",
                severity: "error",
              });
            }
          });
      })
      .catch(() => {
        showSnackbar({
          message: "Falha ao habilitar notificações",
          severity: "error",
        });
      });
  }, [
    manualRequest,
    permissionState,
    requestMessagingToken,
    showSnackbar,
    currentVapid,
  ]);

  const handleRequestPermission = () => {
    setManualRequest(true);
    enableNotification();
  };

  useEffect(() => {
    navigator.permissions
      ?.query({ name: "notifications" })
      .then((permission) => {
        setPermissionState(permission.state);
      });
  }, []);

  useEffect(() => {
    if (permissionState === "granted") enableNotification();
  }, [enableNotification, permissionState]);

  if (!("Notification" in window) || permissionState !== "prompt") {
    return <span />;
  }

  return (
    <Alert
      severity="warning"
      action={
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleRequestPermission}
        >
          Habilitar
        </Button>
      }
    >
      <AlertTitle>Ative as notificações</AlertTitle>
      Receba notificações de novos clientes em sua fila.
    </Alert>
  );
};
