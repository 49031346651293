import { Check, Delete } from "@mui/icons-material";
import { Card, CardContent, Chip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAcceptLinkInvite } from "@/shared/hooks/companies/link-invites/use-accept-link-invite";
import { captureException } from "@sentry/react";
import {
  getChipColorByLinkInviteStatus,
  translateLinkInviteStatus,
} from "@/shared/helpers/link-invites";
import { useRejectLinkInvite } from "@/shared/hooks/companies/link-invites/use-reject-link-invite";
import { LinkInvite } from "@/shared/types/invite-link";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";

type Props = {
  invite: LinkInvite;
  email: string;
};

export const ListInvites = ({ invite, email }: Props) => {
  const { mutate: acceptLinkInvite, isPending: isPendingAcceptLinkInvite } =
    useAcceptLinkInvite();
  const { mutate: rejectLinkInvite, isPending: isPendingRejectLinkInvite } =
    useRejectLinkInvite();
  const { showSnackbar } = useGlobalSnackbar();

  const handleAcceptInvite = () => {
    const { originEstablishmentId } = invite;
    acceptLinkInvite(
      {
        email,
        originEstablishmentId,
      },
      {
        onSuccess() {
          showSnackbar({
            message: "Convite aceito com sucesso",
            severity: "success",
          });
        },
        onError(error) {
          captureException(error);
          showSnackbar({
            message: "Erro ao aceitar convite",
            severity: "error",
          });
        },
      }
    );
  };

  const handleRejectInvite = () => {
    const { originEstablishmentId } = invite;
    rejectLinkInvite(
      {
        linkedEmail: email,
        originEstablishmentId,
      },
      {
        onSuccess() {
          showSnackbar({
            message: "Convite cancelado com sucesso",
            severity: "success",
          });
        },
        onError(error) {
          captureException(error);
          showSnackbar({
            message: "Erro ao cancelar convite",
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <Card>
      <CardContent className="grid grid-cols-1 sm:grid-cols-2 justify-between gap-4">
        <div>
          <Typography>
            {invite.originEstablishmentName}
            <Chip
              label={translateLinkInviteStatus(invite.status)}
              size="small"
              color={getChipColorByLinkInviteStatus(invite.status)}
              className="ml-2"
            />
          </Typography>
        </div>
        <div className="flex items-center justify-end gap-2">
          {invite.status === "PENDING" ? (
            <LoadingButton
              onClick={handleAcceptInvite}
              loading={isPendingAcceptLinkInvite}
              startIcon={<Check />}
              variant="contained"
              color="success"
            >
              Aceitar
            </LoadingButton>
          ) : null}
          {invite.status !== "REJECTED" ? (
            <LoadingButton
              onClick={handleRejectInvite}
              loading={isPendingRejectLinkInvite}
              startIcon={<Delete />}
              variant="outlined"
              color="error"
            >
              Cancelar
            </LoadingButton>
          ) : null}
        </div>
      </CardContent>
    </Card>
  );
};
