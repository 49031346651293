import { useUpdateEstablishment } from "@/shared/hooks/establishment/use-update-establishment ";
import { Establishment } from "@/shared/types/establishments";
import { yupResolver } from "@hookform/resolvers/yup";
import { CloseOutlined } from "@mui/icons-material";
import { Drawer, IconButton, Typography } from "@mui/material";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormStepper } from "./components/form-stepper";
import { workingHourMap } from "@/shared/helpers/working-hour";
import { allSchemas } from "./components/schemas";
import { EstablishmentFields } from "./components/types";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { useInvalidateGetEstablishments } from "@/shared/hooks/establishment/use-get-establishments";
import { useInvalidateGetEstablishment } from "@/shared/hooks/establishment/use-get-establishment";

type UpdateEstablishmentDrawerProps = {
  open: boolean;
  onClose(): void;
  establishment?: Establishment;
};

const UpdateEstablishmentDrawer: FC<UpdateEstablishmentDrawerProps> = ({
  onClose,
  open,
  establishment,
}) => {
  const { showSnackbar } = useGlobalSnackbar();
  const invalidateGetEstablishments = useInvalidateGetEstablishments();
  const invalidateGetEstablishment = useInvalidateGetEstablishment();

  function handleClose() {
    onClose();
  }

  const DrawerContent = () => {
    if (!establishment) throw new Error("Establishment not received");

    const form = useForm<EstablishmentFields>({
      resolver: yupResolver(allSchemas),
      defaultValues: {
        establishmentName: establishment?.name,
        workingHour: establishment ? workingHourMap(establishment) : {},
        location: establishment?.location ?? {},
        configs: {
          appointmentDuration:
            establishment?.configs?.appointmentDuration ?? "60",
        },
      },
    });

    const { handleSubmit } = form;

    const { mutate: updateEstablishment } = useUpdateEstablishment({
      establishmentId: establishment.establishmentId,
    });

    async function handleSubmitForm({
      establishmentName,
      workingHour,
      configs,
      location,
    }: EstablishmentFields) {
      if (!establishment) throw new Error("Establishment not received");

      updateEstablishment(
        { name: establishmentName, workingHour, location, configs },
        {
          onSuccess() {
            showSnackbar({
              message: "Espaço alterado",
              severity: "success",
            });
            invalidateGetEstablishments();
            invalidateGetEstablishment({
              companyId: establishment.companyId,
              establishmentId: establishment.establishmentId,
            });
            handleClose();
          },
          onError() {
            showSnackbar({
              message: "Erro ao alterar espaço",
              severity: "error",
            });
          },
        }
      );
    }

    return (
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(handleSubmitForm)}
          className="flex flex-col gap-y-4"
        >
          <FormStepper />
        </form>
      </FormProvider>
    );
  };

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <div className="p-4 flex flex-col gap-y-6 w-screen max-w-[300px] sm:max-w-md">
        <header className="flex justify-between items-center">
          <Typography variant="h5" fontWeight="bold">
            Alterar espaço
          </Typography>
          <IconButton onClick={handleClose} color="error">
            <CloseOutlined />
          </IconButton>
        </header>

        {open ? <DrawerContent /> : null}
      </div>
    </Drawer>
  );
};

export default UpdateEstablishmentDrawer;
