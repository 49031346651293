import { translateConnectionState } from "@/shared/helpers/establishment-queue";
import type { ConnectionState } from "@/shared/hooks/queue/use-realtime-establishment-queue";
import { Typography } from "@mui/material";

type ConnectionStateProps = {
  state: ConnectionState;
};

export function ConnectionState({
  state: connectionState,
}: ConnectionStateProps) {
  const realTimeConnection = connectionState
    ? translateConnectionState(connectionState)
    : undefined;

  return (
    <div className="flex items-center gap-x-2">
      {realTimeConnection ? (
        <realTimeConnection.icon color={realTimeConnection.color} />
      ) : null}
      <Typography variant="body2">
        {realTimeConnection ? realTimeConnection.message : null}
      </Typography>
    </div>
  );
}
