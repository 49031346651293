"use client";

import { Card, CardContent, Typography, Alert, Button } from "@mui/material";
import { ChevronLeftRounded } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RegisterConfirmForm } from "../components/register-confirm-form";
import { ROUTES_PATH } from "@/shared/constants/routes";

export function AuthRegisterConfirm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  if (!email) {
    throw new Error("E-mail not provided via query string");
  }

  return (
    <div className="flex w-full justify-center items-center">
      <Card className="w-full max-w-[400px]">
        <CardContent>
          <div className="flex flex-col gap-y-4">
            <Button
              variant="text"
              className="w-max"
              onClick={() => navigate(ROUTES_PATH.AUTH_REGISTER)}
            >
              <div className="flex items-center">
                <ChevronLeftRounded />
                <Typography variant="body2">Voltar</Typography>
              </div>
            </Button>

            <Typography variant="h5" className="mb-2" fontWeight="bold">
              Confirme seu cadastro
            </Typography>

            <Alert severity="info">
              <Typography variant="body1">
                Um código de confirmação foi enviado para o email {email}
              </Typography>
            </Alert>

            <RegisterConfirmForm email={email} />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
