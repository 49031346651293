import { add, format, subDays } from "date-fns";

export type State = {
  startDate: string;
  endDate: string;
};

export type Action = {
  type: "today" | "last-7-days" | "last-30-days";
};

const getEndDate = () => format(add(new Date(), { hours: 3 }), "yyyy-MM-dd");

export const getTodayDateState = (): State => {
  return {
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: getEndDate(),
  };
};

const getLastSevenDaysDateState = (): State => {
  return {
    startDate: format(subDays(new Date(), 7), "yyyy-MM-dd"),
    endDate: getEndDate(),
  };
};

const getLastThirtyDaysDateState = (): State => {
  return {
    startDate: format(subDays(new Date(), 30), "yyyy-MM-dd"),
    endDate: getEndDate(),
  };
};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "today":
      return getTodayDateState();

    case "last-7-days":
      return getLastSevenDaysDateState();

    case "last-30-days":
      return getLastThirtyDaysDateState();

    default:
      return state;
  }
};
