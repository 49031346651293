import { API_URL } from "@/shared/constants/env";
import { authenticatedAxios } from "../../fetch";

export const sendLinkInvite = async ({
  establishmentId,
  email,
}: {
  establishmentId: string;
  email: string;
}) => {
  return (await authenticatedAxios()).post(
    `${API_URL}/admin/companies/link-invites`,
    { email, establishmentId }
  );
};
