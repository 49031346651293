import { useUserCompany } from "@/contexts/user/hook";
import { WorkspacePremium } from "@mui/icons-material";
import { Paper, Typography, Button } from "@mui/material";
import { STRIPE_SUBSCRIPTION_PAYMENT_LINK } from "@/shared/constants/env";

export const SubscribePlan = () => {
  const { companyAttributes, hasSubscription } = useUserCompany();

  const handleNewSubscription = () => {
    if (!companyAttributes?.sub || !companyAttributes?.email)
      throw new Error("Missing company attributes");

    const urlParams = new URLSearchParams([
      ["client_reference_id", companyAttributes?.sub],
      ["locale", "pt"],
      ["prefilled_email", companyAttributes?.email],
    ]);
    window.open(
      `${STRIPE_SUBSCRIPTION_PAYMENT_LINK}?${urlParams.toString()}`,
      "_blank"
    );
  };

  if (hasSubscription) return null;

  return (
    <Paper variant="outlined" className="py-6 flex flex-col p-4 gap-y-6 mx-4">
      <div className="flex items-center justify-center gap-2">
        <WorkspacePremium color="secondary" fontSize="large" />
        <Typography variant="h6" fontWeight="bold">
          Plano ULTRA
        </Typography>
      </div>
      <div className="flex items-center justify-center gap-4">
        <Typography variant="body2">
          Experimente a melhor experiência da plataforma com acesso total a tudo
          que ela oferece.
        </Typography>

        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleNewSubscription}
          >
            Assinar
          </Button>
        </div>
      </div>
    </Paper>
  );
};
