import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { ROUTES_PATH } from "./shared/constants/routes";
import { adminLayoutLoader } from "./modules/layouts/admin-layout/loader";
import { AuthLayout } from "./modules/layouts/auth-layout";
import { AuthLogin } from "./modules/auth/pages/login";
import { authLoader } from "./modules/auth/auth-loader";
import { FullscreenLoading } from "./shared/components/loadings/fullscreen-loading";
import { Menu } from "./modules/menu/pages";
import { Profile } from "./modules/profile/pages";
import { menuManageInvitesLoader } from "./modules/manage-invites/loader";
import { ManageInvites } from "./modules/manage-invites/pages";
import { AdminLayout } from "./modules/layouts/admin-layout";
import { Establishments } from "./modules/establishments/pages";
import { establishmentsLoader } from "./modules/establishments/loader";
import { Suspense } from "react";
import { Establishment } from "./modules/establishment/pages";
import { establishmentLoader } from "./modules/layouts/establishment-layout/loader";
import { EstablishmentLayout } from "./modules/layouts/establishment-layout";
import { EstablishmentServiceTypes } from "./modules/service-types/pages";
import { EstablishmentPaymentMethod } from "./modules/payment-methods/pages";
import { EstablishmentCustomers } from "./modules/customers/pages";
import { EstablishmentManageBarbers } from "./modules/manage-barbers/pages";
import { EstablishmentPendingAppointments } from "./modules/appointments/pages";
import { AuthForgotPassword } from "./modules/auth/pages/forgot-password";
import { AuthForgotPasswordConfirm } from "./modules/auth/pages/forgot-password-confirm";
import { AuthRegister } from "./modules/auth/pages/register";
import { AuthRegisterConfirm } from "./modules/auth/pages/register-confirm";
import { SetupLayout } from "./modules/layouts/setup-layout";

const router = createBrowserRouter([
  {
    element: <SetupLayout />,
    children: [
      {
        path: ROUTES_PATH.HOME,
        loader: async () => redirect(ROUTES_PATH.AUTH),
      },
      {
        element: <AuthLayout />,
        loader: authLoader,
        children: [
          {
            path: ROUTES_PATH.AUTH,
            element: <AuthLogin />,
          },
          {
            path: ROUTES_PATH.AUTH_REGISTER,
            element: <AuthRegister />,
          },
          {
            path: ROUTES_PATH.AUTH_REGISTER_CONFIRM,
            element: <AuthRegisterConfirm />,
          },
          {
            path: ROUTES_PATH.AUTH_FORGOT_PASSWORD,
            element: <AuthForgotPassword />,
          },
          {
            path: ROUTES_PATH.AUTH_FORGOT_PASSWORD_CONFIRM,
            element: <AuthForgotPasswordConfirm />,
          },
        ],
      },
      {
        loader: adminLayoutLoader,
        element: <AdminLayout />,
        children: [
          {
            children: [
              {
                path: ROUTES_PATH.MENU,
                element: <Menu />,
              },
              {
                path: ROUTES_PATH.MENU_PROFILE,
                element: <Profile />,
              },
              {
                loader: menuManageInvitesLoader,
                path: ROUTES_PATH.MENU_MANAGE_INVITES,
                element: <ManageInvites />,
              },
            ],
          },
          {
            loader: establishmentsLoader,
            path: ROUTES_PATH.ESTABLISHMENTS,
            element: <Establishments />,
          },
          {
            element: <EstablishmentLayout />,
            loader: establishmentLoader,
            children: [
              {
                path: ROUTES_PATH.ESTABLISHMENT,
                element: <Establishment />,
              },
              {
                path: ROUTES_PATH.ESTABLISHMENT_SERVICE_TYPES,
                element: <EstablishmentServiceTypes />,
              },
              {
                path: ROUTES_PATH.ESTABLISHMENT_PAYMENT_METHODS,
                element: <EstablishmentPaymentMethod />,
              },
              {
                path: ROUTES_PATH.ESTABLISHMENT_CUSTOMERS,
                element: <EstablishmentCustomers />,
              },
              {
                path: ROUTES_PATH.ESTABLISHMENT_MANAGE_BARBERS,
                element: <EstablishmentManageBarbers />,
              },
              {
                path: ROUTES_PATH.ESTABLISHMENT_PENDING_APPOINTMENTS,
                element: <EstablishmentPendingAppointments />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export const AppRouter = () => {
  return (
    <Suspense fallback={<FullscreenLoading />}>
      <RouterProvider router={router} fallbackElement={<FullscreenLoading />} />
    </Suspense>
  );
};
