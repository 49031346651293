import { useGlobalPageLoading } from "@/contexts/global-page-loading/hook";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { ArrowBack } from "@mui/icons-material";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const navigate = useNavigate();
  const { pageTransition } = useGlobalPageLoading();

  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton
          onClick={() =>
            pageTransition(() => {
              navigate(ROUTES_PATH.MENU, { replace: true });
            })
          }
          className="mr-2"
          color="inherit"
          edge="start"
          size="large"
        >
          <ArrowBack />
        </IconButton>
        <Typography
          variant="h6"
          fontWeight="bold"
          component="div"
          className="text-amber-800 dark:text-white overflow-hidden text-ellipsis"
          flexGrow={1}
        >
          Meu perfil
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
