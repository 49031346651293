import { apiAdmin } from "@/shared/services/api";
import { useMutation } from "@tanstack/react-query";

type ResponseData = {
  id: string;
  object: string;
  configuration: string;
  created: number;
  customer: string;
  flow: null;
  livemode: false;
  locale: string;
  on_behalf_of: null;
  return_url: string;
  url: string;
};

export const useGenerateStripePortalSession = () => {
  return useMutation({
    mutationFn: async () => {
      const http = await apiAdmin();
      return http.post<ResponseData>("/payments/generate-portal-session");
    },
  });
};
