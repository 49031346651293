import { useGetAddress } from "@/shared/hooks/viacep/use-get-address";
import { TextField } from "@mui/material";
import { captureException } from "@sentry/react";
import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { LocationFields } from "./types";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";

export const EstablishmentLocationInputs = () => {
  const { showSnackbar } = useGlobalSnackbar();
  const { register, watch, setValue, resetField } =
    useFormContext<LocationFields>();
  const { mutate } = useGetAddress();
  const cep = watch("location.cep");

  const getAddress = useCallback(() => {
    if (cep?.length !== 8)
      return resetField("location", {
        defaultValue: {
          cep,
          state: "",
          city: "",
          neighborhood: "",
          street: "",
          number: "",
          complement: "",
        },
      });

    mutate(
      { cep },
      {
        onSuccess(response) {
          setValue("location.state", response.uf);
          setValue("location.city", response.localidade);
          setValue("location.neighborhood", response.bairro);
          setValue("location.street", response.logradouro);
        },
        onError(error) {
          captureException(error);
          showSnackbar({
            message: "Erro ao buscar CEP.",
            severity: "error",
          });
        },
      }
    );
  }, [cep, mutate, resetField, setValue, showSnackbar]);

  useEffect(() => {
    getAddress();
  }, [getAddress]);

  return (
    <>
      <TextField
        {...register("location.cep")}
        label="CEP"
        fullWidth
        inputMode="numeric"
      />
      <TextField
        {...register("location.state")}
        InputLabelProps={{ shrink: !!watch("location.state") }}
        label="Estado"
        fullWidth
        disabled
      />
      <TextField
        {...register("location.city")}
        InputLabelProps={{ shrink: !!watch("location.city") }}
        label="Cidade"
        fullWidth
        disabled
      />
      <TextField
        {...register("location.neighborhood")}
        InputLabelProps={{ shrink: !!watch("location.neighborhood") }}
        label="Bairro"
        fullWidth
        disabled
      />
      <TextField
        {...register("location.street")}
        InputLabelProps={{ shrink: !!watch("location.street") }}
        label="Rua"
        fullWidth
        disabled
      />
      <TextField {...register("location.number")} label="Número" fullWidth />
      <TextField
        {...register("location.complement")}
        label="Complemento"
        fullWidth
      />
    </>
  );
};
