import {
  listHours,
  listMinutes,
  workingHoursInputs,
} from "@/shared/helpers/establishment";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { WorkingHourFields } from "./types";

const listOfHours = listHours();
const listOfMinutes = listMinutes();

export const EstablishmentWorkingHourInputs = () => {
  const { resetField, register, watch, setValue } =
    useFormContext<WorkingHourFields>();

  const handleSelectHour = (checked: boolean, key: string) => {
    if (!checked) {
      resetField(`workingHour.${key}`, {
        defaultValue: {
          enabled: false,
          endTime: "",
          startTime: "",
          attendingType: null,
        },
      });
    } else {
      if (key === "Lunch") {
        setValue(`workingHour.${key}.startTime`, "12:00");
        setValue(`workingHour.${key}.endTime`, "13:00");
        return;
      }
      setValue(`workingHour.${key}.startTime`, "8:00");
      setValue(`workingHour.${key}.endTime`, "20:00");
      setValue(`workingHour.${key}.attendingType`, "BOOKING");
    }
  };

  return (
    <div className="flex flex-col gap-y-3">
      <Divider>
        <Typography variant="body2" color="textSecondary">
          Configurações gerais
        </Typography>
      </Divider>

      <FormControl className="min-w-28" fullWidth>
        <InputLabel id="establishment-appointment-duration-label">
          Duração do atendimento
        </InputLabel>
        <Select
          id="establishment-appointment-duration"
          labelId="establishment-appointment-duration-label"
          label="Duração do atendimento"
          defaultValue="60"
          value={watch(`configs.appointmentDuration`)}
          {...register(`configs.appointmentDuration`)}
        >
          {listOfMinutes.map((minute) => (
            <MenuItem key={minute} value={minute}>
              {minute} minutos
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              {...register(`workingHour.Lunch.enabled`, {
                onChange(event) {
                  handleSelectHour(event.target.checked, "Lunch");
                },
              })}
              defaultChecked={watch(`workingHour.Lunch.enabled`)}
            />
          }
          label="Almoço"
        />
      </FormGroup>

      {watch("workingHour.Lunch.enabled") ? (
        <div className="grid grid-cols-2 justify-between gap-4">
          <FormControl className="min-w-28" fullWidth>
            <InputLabel id="establishment-lunch-time-label">
              Começa às
            </InputLabel>
            <Select
              id="establishment-lunch-time"
              labelId="establishment-lunch-time-label"
              label="Começa às"
              value={watch(`workingHour.Lunch.startTime`)}
              {...register(`workingHour.Lunch.startTime`)}
            >
              <MenuItem value="">
                <em>Nenhum</em>
              </MenuItem>
              {listOfHours.map((hour) => (
                <MenuItem key={hour} value={hour}>
                  {hour}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="min-w-28" fullWidth>
            <InputLabel id="establishment-lunch-time-label">
              Acaba às
            </InputLabel>
            <Select
              id="establishment-lunch-time"
              labelId="establishment-lunch-time-label"
              label="Acaba às"
              value={watch(`workingHour.Lunch.endTime`)}
              {...register(`workingHour.Lunch.endTime`)}
            >
              {listOfHours
                .filter((hour) => {
                  const currentHour = Number(hour.split(":")[0]);
                  const startTime = Number(
                    watch("workingHour.Lunch.startTime")?.split(":")[0] ?? 0
                  );

                  return currentHour > startTime;
                })
                .map((hour) => (
                  <MenuItem key={hour} value={hour}>
                    {hour}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      ) : null}

      <Divider>
        <Typography variant="body2" color="textSecondary">
          Horários de atendimento
        </Typography>
      </Divider>

      {workingHoursInputs.map(({ dayOfWeek, value }) => (
        <div key={value}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  {...register(`workingHour.${value}.enabled`, {
                    onChange(event) {
                      handleSelectHour(event.target.checked, value);
                    },
                  })}
                  defaultChecked={watch(`workingHour.${value}.enabled`)}
                />
              }
              label={dayOfWeek}
            />
          </FormGroup>

          {watch(`workingHour.${value}.enabled`) ? (
            <div className="grid grid-cols-2 justify-between gap-4">
              <FormControl className="min-w-28" fullWidth>
                <InputLabel id="establishment-start-time-label">
                  Abre às
                </InputLabel>
                <Select
                  id="establishment-start-time"
                  labelId="establishment-start-time-label"
                  label="Abre às"
                  value={watch(`workingHour.${value}.startTime`)}
                  {...register(`workingHour.${value}.startTime`)}
                >
                  {listOfHours.map((hour) => (
                    <MenuItem key={hour} value={hour}>
                      {hour}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className="min-w-28" fullWidth>
                <InputLabel id="establishment-end-time-label">
                  Fecha às
                </InputLabel>
                <Select
                  id="establishment-end-time"
                  labelId="establishment-end-time-label"
                  label="Fecha às"
                  value={watch(`workingHour.${value}.endTime`)}
                  {...register(`workingHour.${value}.endTime`)}
                >
                  {listOfHours
                    .filter((hour) => {
                      const currentHour = Number(hour.split(":")[0]);
                      const startTime = Number(
                        watch(`workingHour.${value}.startTime`)?.split(
                          ":"
                        )[0] ?? 0
                      );

                      return currentHour > startTime;
                    })
                    .map((hour) => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl className="min-w-28 col-span-2" fullWidth>
                <InputLabel id="establishment-attending-type-label">
                  Tipo de atendimento
                </InputLabel>
                <Select
                  labelId="establishment-attending-type-label"
                  label="Tipo de atendimento"
                  value={watch(`workingHour.${value}.attendingType`)}
                  {...register(`workingHour.${value}.attendingType`)}
                >
                  <MenuItem value="QUEUE">Ordem de chegada</MenuItem>
                  <MenuItem value="BOOKING">Agendamento</MenuItem>
                </Select>
              </FormControl>
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};
