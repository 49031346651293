import { CircularProgress, Typography } from "@mui/material";
import { Suspense } from "react";
import { PendingAppointments } from "../components/pending-appointments";
import { ErrorBoundary } from "@sentry/react";
import { Header } from "../components/header";

export function EstablishmentPendingAppointments() {
  return (
    <>
      <Header />

      <div className="mt-4 flex flex-col">
        <ErrorBoundary
          fallback={
            <Typography>Algo deu errado, tente recarregar a página.</Typography>
          }
        >
          <Suspense fallback={<CircularProgress className="mx-auto mt-4" />}>
            <PendingAppointments />
          </Suspense>
        </ErrorBoundary>
      </div>
    </>
  );
}
