import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const HandleAsyncData = ({
  data,
  children,
}: PropsWithChildren<{ data: unknown | Response }>) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (data instanceof Response) {
      const redirectPath = data.headers.get("Location");
      navigate(redirectPath as string);
    }
  }, [data, navigate]);

  if (data instanceof Response) return null;

  return children;
};
