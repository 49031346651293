import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { Header } from "../components/header";
import { ListLinkedInvite } from "../components/list-linked-invite";
import { SendInvite } from "../components/send-invite";
import { useUserCompany } from "@/contexts/user/hook";
import { useListLinkedInvites } from "@/shared/hooks/companies/linked-invites/use-list-linked-invites";
import { useParams } from "react-router-dom";
import { ROUTES_PATH } from "@/shared/constants/routes";

export function EstablishmentManageBarbers() {
  const { establishmentId } = useParams<{ establishmentId: string }>();

  if (!establishmentId) {
    throw new Error("establishmentId is required");
  }

  const {
    data: { data: linkedInvites },
  } = useListLinkedInvites({ establishmentId });
  const { companyAttributes } = useUserCompany();
  const given_name = companyAttributes?.given_name;
  const canSeeLinkedInvites = !!given_name;

  return (
    <>
      <Header />

      {!canSeeLinkedInvites ? (
        <Alert
          severity="warning"
          action={
            <Button href={ROUTES_PATH.MENU_PROFILE}>Atualizar perfil</Button>
          }
        >
          <AlertTitle>Complete o seu perfil</AlertTitle>
          Para poder visualizar, adicionar ou remover os barbeiros vinculados a
          este estabelecimento, complete o seu perfil.
        </Alert>
      ) : null}

      <div className="m-4">
        <Typography color="GrayText">
          Convide outros barbeiros para atender no seu estabelecimento.
        </Typography>

        <Typography color="GrayText">
          Os barbeiros convidados poderão visualizar e atender os clientes na
          fila de espera e agendamentos.
        </Typography>
      </div>

      {!linkedInvites.length ? (
        <Alert severity="info">
          <AlertTitle>Barbeiros vinculados</AlertTitle>
          Não há nenhum outro barbeiro vinculado a este estabelecimento ainda.
        </Alert>
      ) : null}

      {canSeeLinkedInvites ? (
        <div className="m-4">
          {linkedInvites.map((linkedInvite) => (
            <ListLinkedInvite
              key={linkedInvite.linkedEmail}
              linkedInvite={linkedInvite}
            />
          ))}
        </div>
      ) : null}

      {canSeeLinkedInvites ? <SendInvite /> : null}
    </>
  );
}
