import { websocketClient } from "@/shared/services/api";
import { useEffect, useState } from "react";
import { GetEstablishmentQueueResponseData } from "./use-get-establishment-queue";

export type ConnectionState = "CONNECTED" | "DISCONNECTED" | "LOADING";

type Params = {
  establishmentId: string;
};

export function useRealtimeEstablishmentQueue({ establishmentId }: Params) {
  const [establishmentQueue, setEstablishmentQueue] =
    useState<GetEstablishmentQueueResponseData>();
  const [connectionState, setConnectionState] =
    useState<ConnectionState>("LOADING");
  const [hadData, setHadData] = useState(false);

  function handleOpenSocket() {
    setConnectionState("CONNECTED");
  }

  function handleCloseSocket() {
    setConnectionState("DISCONNECTED");
  }

  function handleMessageSocket(message: MessageEvent<string>) {
    const data = JSON.parse(message.data) as GetEstablishmentQueueResponseData;
    setEstablishmentQueue(data);
  }

  useEffect(() => {
    if (establishmentQueue) {
      setHadData(true);
    }
  }, [establishmentQueue]);

  useEffect(() => {
    const socket = websocketClient(establishmentId);

    socket.addEventListener("open", handleOpenSocket);
    socket.addEventListener("close", handleCloseSocket);
    socket.addEventListener("message", handleMessageSocket);

    return () => {
      socket.close();
      socket.removeEventListener("open", handleOpenSocket);
      socket.removeEventListener("close", handleCloseSocket);
      socket.removeEventListener("message", handleMessageSocket);
    };
  }, [establishmentId]);

  return { establishmentQueue, connectionState, hadData };
}
