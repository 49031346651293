import {
  BodyPayload,
  savePaymentMethods,
} from "@/shared/services/barx-api/establishments/payment-methods/save";
import { useMutation } from "@tanstack/react-query";

type Params = {
  establishmentId: string;
};

export const useManagePaymentMethods = ({ establishmentId }: Params) => {
  return useMutation<unknown, unknown, BodyPayload>({
    mutationFn: async ({ paymentMethods }) =>
      savePaymentMethods(establishmentId, { paymentMethods }),
  });
};
