import { useInitHotjar } from "@/shared/hooks/use-init-hotjar";
import { useInitializeFirebase } from "@/shared/hooks/use-initialize-firebase";
import { Outlet } from "react-router-dom";

export const SetupLayout = () => {
  useInitHotjar();
  useInitializeFirebase();

  return <Outlet />;
};
