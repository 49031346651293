import { WorkingHourItem } from "../types/establishments";
import { isAfter, format, isBefore, parseISO } from "date-fns";

export const workingHoursInputs = [
  {
    dayOfWeek: "Segunda",
    value: "Monday",
  },
  {
    dayOfWeek: "Terça",
    value: "Tuesday",
  },
  {
    dayOfWeek: "Quarta",
    value: "Wednesday",
  },
  {
    dayOfWeek: "Quinta",
    value: "Thursday",
  },
  {
    dayOfWeek: "Sexta",
    value: "Friday",
  },
  {
    dayOfWeek: "Sábado",
    value: "Saturday",
  },
  {
    dayOfWeek: "Domingo",
    value: "Sunday",
  },
];

export const listHours = ({ withMinutes }: { withMinutes?: boolean } = {}) => {
  const hours = [];

  for (let i = 0; i < 24; i++) {
    hours.push(`${i}:00`);
    if (withMinutes) {
      hours.push(`${i}:30`);
    }
  }

  return hours;
};

export const listMinutes = (): number[] => {
  const minutes = [];

  for (let i = 10; i <= 60; i += 10) {
    minutes.push(i);
  }

  return minutes;
};

export const weekNameTranslationMap: Record<string, string> = {
  Monday: "Segunda",
  Tuesday: "Terça",
  Wednesday: "Quarta",
  Thursday: "Quinta",
  Friday: "Sexta",
  Saturday: "Sábado",
  Sunday: "Domingo",
  Lunch: "Almoço",
};

export const hasAnyEnabledDay = (workingHour: WorkingHourItem = {}) => {
  const keys = Object.keys(workingHour);

  return keys.some((key) => workingHour[key].enabled);
};

export const isUnderWorkingTime = (workingHour: WorkingHourItem = {}) => {
  const currentDay = format(new Date(), "EEEE");
  const currentDayWorkingHour = workingHour[currentDay];

  if (!currentDayWorkingHour || !currentDayWorkingHour.enabled) return false;

  if (!currentDayWorkingHour.endTime || !currentDayWorkingHour.startTime) {
    throw new Error("Invalid working hour format");
  }

  const startStringDate = `${format(new Date(), "yyyy-MM-dd")}T${normalizeTime(
    currentDayWorkingHour.startTime
  )}:00`;
  const endStringDate = `${format(new Date(), "yyyy-MM-dd")}T${normalizeTime(
    currentDayWorkingHour.endTime
  )}:00`;

  const startDate = parseISO(startStringDate);
  const endDate = parseISO(endStringDate);

  return isAfter(new Date(), startDate) && isBefore(new Date(), endDate);
};

export const normalizeTime = (time: string) => {
  const [hours, minutes] = time.split(":");

  return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
};
