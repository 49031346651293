import { apiAdmin } from "@/shared/services/api";
import { useQuery } from "@tanstack/react-query";

export type GetResultsOutput = {
  clients: {
    total: number;
    served: number;
    notServed: number;
  };
  services: {
    total: number;
    details: {
      [k: string]: {
        serviceId: string;
        name: string;
        amount: number;
      };
    };
  };
  consumedItems: {
    total: Record<string, { quantity: number; unity: string; name: string }>;
    details: Record<
      string,
      Record<string, { quantity: number; unity: string; name: string }>
    >;
  };
};

type UseGetResultsParams = {
  establishmentId: string;
  startDate: string;
  endDate: string;
  originCompanyId?: string;
  linkedCompanyId?: string;
};

const endOfStartDate = "T00:00:00.000Z";
const endOfEndDate = "T23:59:59.999Z";

export const useGetResults = ({
  establishmentId,
  startDate,
  endDate,
  linkedCompanyId,
  originCompanyId,
}: UseGetResultsParams) => {
  const startDateWithTimezone = startDate + endOfStartDate;
  const endDateWithTimezone = endDate + endOfEndDate;

  return useQuery({
    queryKey: mountGetResultsCacheKey({
      establishmentId,
      startDate: startDateWithTimezone,
      endDate: endDateWithTimezone,
      linkedCompanyId,
      originCompanyId,
    }),
    queryFn: async () => {
      const admin = await apiAdmin();
      return admin.get<GetResultsOutput>(
        `/establishments/${establishmentId}/served-clients`,
        {
          params: {
            startDate: startDateWithTimezone,
            endDate: endDateWithTimezone,
            originCompanyId,
            linkedCompanyId,
          },
        }
      );
    },
  });
};

const mountGetResultsCacheKey = ({
  establishmentId,
  startDate,
  endDate,
  linkedCompanyId,
  originCompanyId,
}: UseGetResultsParams) => {
  return [
    "establishments",
    establishmentId,
    startDate,
    endDate,
    linkedCompanyId,
    originCompanyId,
  ];
};
