import { sendLinkInvite } from "@/shared/services/barx-api/companies/link-invites/send";
import { useMutation } from "@tanstack/react-query";

type Payload = {
  establishmentId: string;
  email: string;
};

export const useSendLinkInvite = () => {
  return useMutation<unknown, Error, Payload>({
    mutationFn: ({ email, establishmentId }) =>
      sendLinkInvite({ email, establishmentId }),
  });
};
