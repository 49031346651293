import "@fontsource-variable/montserrat";

import { AppProviders } from "@/contexts/app/provider";
import { AppRouter } from "@/router";

function App() {
  return (
    <AppProviders>
      <AppRouter />
    </AppProviders>
  );
}

export default App;
