import { Card, Typography, CardContent, Button } from "@mui/material";
import { SignInForm } from "../components/sign-in-form";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "@/shared/constants/routes";

export const AuthLogin = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex w-full justify-center items-center">
        <Card className="w-full max-w-[400px]">
          <CardContent>
            <div className="flex flex-col gap-y-4">
              <Typography variant="h5" fontWeight="bold">
                Entrar
              </Typography>

              <SignInForm />

              <div className="border-0 border-solid border-t border-slate-300" />

              <div className="flex justify-center">
                <Button
                  variant="text"
                  onClick={() => navigate(ROUTES_PATH.AUTH_REGISTER)}
                >
                  Criar nova conta
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};
