import { API_URL } from "@/shared/constants/env";
import { authenticatedAxios } from "../fetch";

export const updateVapidAction = async ({
  vapidToken,
}: {
  vapidToken: string;
}) =>
  (await authenticatedAxios()).put(`${API_URL}/admin/companies/vapid`, {
    vapidToken,
  });
