import { Establishment } from "@/shared/types/establishments";
import {
  Straighten,
  ShareRounded,
  QrCode,
  Edit,
  Delete,
  MoreVert,
  People,
  Pause,
  PlayArrow,
  Payment,
  SwitchAccount,
} from "@mui/icons-material";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import { MouseEvent, Suspense, useState } from "react";
import { BackdropLoading } from "@/shared/components/loadings/backdrop-loading";
import { useUserCompany } from "@/contexts/user/hook";
import { PremiumChip } from "@/shared/components/premium-chip";
import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { useNavigate } from "react-router-dom";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import UpdateEstablishmentDrawer from "@/shared/drawers/manage-establishment/update-establishment-drawer";
import DeleteEstablishmentDialog from "./delete-dialog";
import { CloseEstablishmentDialog } from "./close-establishment-dialog";
import { ResumeEstablishmentDialog } from "./resume-establishment-dialog";
import { replaceWithObject } from "@/shared/utils/url";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { CLIENT_APP_URL } from "@/shared/constants/env";
import { useGlobalPageLoading } from "@/contexts/global-page-loading/hook";

type Props = {
  establishment: Establishment;
};

export const TopBarMenu = ({ establishment }: Props) => {
  const { hasSubscription } = useUserCompany();
  const { linkInvite, establishmentSlug } = useEstablishmentContext();
  const navigate = useNavigate();
  const [moreOptionsButton, setMoreOptionsButton] =
    useState<HTMLButtonElement | null>(null);
  const [editEstablishment, setEditEstablishment] = useState<Establishment>();
  const [deleteEstablishment, setDeleteEstablishment] =
    useState<Establishment>();
  const [openCloseEstablishmentDialog, setOpenCloseEstablishmentDialog] =
    useState(false);
  const [openResumeEstablishmentDialog, setOpenResumeEstablishmentDialog] =
    useState(false);
  const { showSnackbar } = useGlobalSnackbar();
  const { pageTransition } = useGlobalPageLoading();

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setMoreOptionsButton(event.currentTarget);
  };

  const closeMenu = () => {
    setMoreOptionsButton(null);
  };

  const goToServiceTypes = () => {
    closeMenu();

    pageTransition(() => {
      navigate(
        replaceWithObject(ROUTES_PATH.ESTABLISHMENT_SERVICE_TYPES, {
          establishmentId: establishment.establishmentId,
        })
      );
    });
  };

  const shareEstablishment = () => {
    if (!establishmentSlug) {
      showSnackbar({
        severity: "warning",
        message:
          "Atualize o nome do seu estabelecimento para gerar um novo link.",
      });
      return;
    }

    const publicBarbershopURL = `${CLIENT_APP_URL}/barbershop/${establishmentSlug.slug}`;

    if (navigator.share) {
      navigator.share({ url: publicBarbershopURL });
    } else if (navigator.clipboard) {
      navigator.clipboard.writeText(publicBarbershopURL);
      showSnackbar({
        severity: "success",
        message: "Link copiado para a área de transferência.",
      });
    } else {
      showSnackbar({
        severity: "error",
        message: "Não foi possível compartilhar o link.",
      });
      return;
    }

    closeMenu();
  };

  const openDeleteDialog = () => {
    closeMenu();
    setDeleteEstablishment(establishment);
  };

  const closeDeleteDialog = () => {
    setDeleteEstablishment(undefined);
  };

  const openEditDrawer = () => {
    closeMenu();
    setEditEstablishment(establishment);
  };

  const closeEditDrawer = () => {
    setEditEstablishment(undefined);
  };

  const goToCustomers = () => {
    closeMenu();

    pageTransition(() => {
      navigate(
        replaceWithObject(ROUTES_PATH.ESTABLISHMENT_CUSTOMERS, {
          establishmentId: establishment.establishmentId,
        })
      );
    });
  };

  const goToPaymentMethods = () => {
    closeMenu();

    pageTransition(() => {
      navigate(
        replaceWithObject(ROUTES_PATH.ESTABLISHMENT_PAYMENT_METHODS, {
          establishmentId: establishment.establishmentId,
        })
      );
    });
  };

  const goToManageBarbers = () => {
    closeMenu();

    pageTransition(() => {
      navigate(
        replaceWithObject(ROUTES_PATH.ESTABLISHMENT_MANAGE_BARBERS, {
          establishmentId: establishment.establishmentId,
        })
      );
    });
  };

  const showCloseEstablishmentDialog = () => {
    closeMenu();
    setOpenCloseEstablishmentDialog(true);
  };
  const closeCloseEstablishmentDialog = () =>
    setOpenCloseEstablishmentDialog(false);

  const showResumeEstablishmentDialog = () => {
    closeMenu();
    setOpenResumeEstablishmentDialog(true);
  };
  const closeResumeEstablishmentDialog = () =>
    setOpenResumeEstablishmentDialog(false);

  return (
    <>
      <IconButton edge="end" onClick={openMenu}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={moreOptionsButton}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!moreOptionsButton}
        onClose={closeMenu}
      >
        {!linkInvite ? (
          <MenuItem onClick={goToServiceTypes}>
            <ListItemIcon>
              <Straighten />
            </ListItemIcon>
            <ListItemText>Tipos de serviço</ListItemText>
          </MenuItem>
        ) : null}

        {!linkInvite ? (
          <MenuItem onClick={goToPaymentMethods}>
            <ListItemIcon>
              <Payment />
            </ListItemIcon>
            <ListItemText>Formas de pagamento</ListItemText>
          </MenuItem>
        ) : null}

        <MenuItem onClick={goToCustomers} disabled={!hasSubscription}>
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <ListItemText>
            Clientes {!hasSubscription ? <PremiumChip /> : null}
          </ListItemText>
        </MenuItem>

        {!linkInvite ? (
          <MenuItem onClick={goToManageBarbers} disabled={!hasSubscription}>
            <ListItemIcon>
              <SwitchAccount />
            </ListItemIcon>
            <ListItemText>
              Gerenciar barbeiros {!hasSubscription ? <PremiumChip /> : null}
            </ListItemText>
          </MenuItem>
        ) : null}

        <Divider />

        <MenuItem onClick={shareEstablishment}>
          <ListItemIcon>
            <ShareRounded />
          </ListItemIcon>
          <ListItemText>Compartilhar</ListItemText>
        </MenuItem>

        <MenuItem disabled>
          <ListItemIcon>
            <QrCode />
          </ListItemIcon>
          <ListItemText>Baixar QRCode</ListItemText>
        </MenuItem>

        {!linkInvite ? (
          <>
            <Divider />

            {establishment.status === "OPEN" ? (
              <MenuItem
                onClick={showCloseEstablishmentDialog}
                disabled={!hasSubscription}
              >
                <ListItemIcon>
                  <Pause />
                </ListItemIcon>
                <ListItemText>
                  Pausar fila {!hasSubscription ? <PremiumChip /> : null}
                </ListItemText>
              </MenuItem>
            ) : null}

            {establishment.status === "CLOSED" ? (
              <MenuItem onClick={showResumeEstablishmentDialog}>
                <ListItemIcon>
                  <PlayArrow />
                </ListItemIcon>
                <ListItemText>Retomar fila</ListItemText>
              </MenuItem>
            ) : null}

            <MenuItem onClick={openEditDrawer}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText>Alterar espaço</ListItemText>
            </MenuItem>

            <MenuItem onClick={openDeleteDialog}>
              <ListItemIcon>
                <Delete color="error" />
              </ListItemIcon>
              <ListItemText>
                <Typography color="error">Deletar espaço</Typography>
              </ListItemText>
            </MenuItem>
          </>
        ) : null}
      </Menu>

      <Suspense fallback={<BackdropLoading />}>
        <UpdateEstablishmentDrawer
          establishment={editEstablishment}
          open={!!editEstablishment}
          onClose={closeEditDrawer}
        />
      </Suspense>

      <Suspense fallback={<BackdropLoading />}>
        <DeleteEstablishmentDialog
          open={!!deleteEstablishment}
          onClose={closeDeleteDialog}
          establishment={deleteEstablishment}
        />
      </Suspense>

      <Suspense fallback={<BackdropLoading />}>
        <CloseEstablishmentDialog
          open={openCloseEstablishmentDialog}
          onClose={closeCloseEstablishmentDialog}
          establishment={establishment}
        />
      </Suspense>

      <Suspense fallback={<BackdropLoading />}>
        <ResumeEstablishmentDialog
          open={openResumeEstablishmentDialog}
          onClose={closeResumeEstablishmentDialog}
          establishment={establishment}
        />
      </Suspense>
    </>
  );
};
