import { API_URL } from "@/shared/constants/env";
import { authenticatedAxios } from "../fetch";

export const resumeEstablishmentAction = async ({
  establishmentId,
}: {
  establishmentId: string;
}) => {
  return (await authenticatedAxios()).patch(
    `${API_URL}/admin/establishments/${establishmentId}/open`
  );
};
