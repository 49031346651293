import { FC, Suspense } from "react";
import {
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import {
  Establishment,
  QueueAddedBy,
  WorkingHourItem,
} from "@/shared/types/establishments";
import AddClientQueue from "./add-client-queue";
import AddClientBooking from "./add-client-booking";

type Props = {
  open: boolean;
  onClose(): void;
  establishment: Establishment;
  attendingType: WorkingHourItem[string]["attendingType"];
  addedBy: QueueAddedBy;
  withSignIn?: boolean;
};

export const AttendingClientDrawer: FC<Props> = ({
  open,
  onClose,
  establishment,
  attendingType,
  addedBy,
}) => {
  function handleClose() {
    onClose();
  }

  const handleEnterQueue = () => {
    handleClose();
  };

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <div className="p-4 flex flex-col gap-y-6 w-screen max-w-[300px] sm:max-w-md">
        <header className="flex justify-between items-center">
          <Typography variant="h5" fontWeight="bold">
            {attendingType === "QUEUE"
              ? "Entrar na fila"
              : "Reservar horário para atendimento"}
          </Typography>
          <IconButton onClick={handleClose} color="error">
            <CloseOutlined />
          </IconButton>
        </header>

        <>
          {attendingType === "QUEUE" ? (
            <Suspense fallback={<CircularProgress className="mx-auto" />}>
              <AddClientQueue
                addedBy={addedBy}
                establishment={establishment}
                onFinish={handleEnterQueue}
              />
            </Suspense>
          ) : null}

          {attendingType === "BOOKING" ? (
            <Suspense fallback={<CircularProgress className="mx-auto" />}>
              <AddClientBooking
                establishment={establishment}
                onFinish={handleClose}
              />
            </Suspense>
          ) : null}
        </>
      </div>
    </Drawer>
  );
};

export default AttendingClientDrawer;
