import { Typography } from "@mui/material";
import { ReactNode } from "react";

export const TextLabel = ({
  label,
  text,
}: {
  label: string;
  text: ReactNode;
}) => {
  return (
    <div>
      <Typography color="GrayText">{label}</Typography>
      <Typography fontWeight="bold">{text}</Typography>
    </div>
  );
};
