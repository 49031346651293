import { ROUTES_PATH } from "@/shared/constants/routes";
import {
  FetchUserAttributesOutput,
  fetchUserAttributes,
} from "aws-amplify/auth";
import { redirect } from "react-router-dom";

export const authLoader = async () => {
  let userAttributes: FetchUserAttributesOutput;

  try {
    userAttributes = await fetchUserAttributes();

    if (userAttributes) {
      return redirect(ROUTES_PATH.ESTABLISHMENTS);
    }

    return { userAttributes };
  } catch (e) {
    const err = e as Error;

    if (err.name === "UserUnAuthenticatedException") {
      return {};
    }

    throw e;
  }
};
