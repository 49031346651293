import { Alert, Button, Card, CardContent, Typography } from "@mui/material";
import { ChevronLeftRounded } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ForgotPasswordConfirmForm } from "../components/forgot-password-confirm-form";
import { ROUTES_PATH } from "@/shared/constants/routes";

export function AuthForgotPasswordConfirm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  if (!email) {
    throw new Error("E-mail was not provided via query string");
  }

  return (
    <div className="flex w-full justify-center items-center">
      <Card className="w-full max-w-[400px]">
        <CardContent>
          <div className="flex flex-col gap-y-4">
            <Button
              variant="text"
              className="w-max"
              onClick={() => navigate(ROUTES_PATH.AUTH_FORGOT_PASSWORD)}
            >
              <div className="flex items-center">
                <ChevronLeftRounded />
                <Typography variant="body2">Voltar</Typography>
              </div>
            </Button>

            <Typography variant="h5" className="mb-2" fontWeight="bold">
              Forgot password
            </Typography>

            <Alert severity="info">
              <Typography variant="body1">
                Um e-mail de confirmação foi enviado para {email}.
              </Typography>
            </Alert>

            <ForgotPasswordConfirmForm email={email} />

            <div className="border-0 border-solid border-t border-slate-200" />

            <div className="flex justify-center">
              <Button variant="text" onClick={() => navigate(ROUTES_PATH.AUTH)}>
                Entrar
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
