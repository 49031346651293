import { apiAdmin } from "@/shared/services/api";
import { useMutation } from "@tanstack/react-query";

type MutationParams = {
  establishmentId: string;
  name: string;
  amount: number;
  consumedItems?: {
    name: string;
    quantity: number;
    unity: string;
  }[];
};

export const useCreateServiceType = () => {
  return useMutation<unknown, unknown, MutationParams>({
    mutationFn: async ({ establishmentId, ...bodyPayload }) => {
      const admin = await apiAdmin();
      return admin.post(
        `/establishments/${establishmentId}/service-types`,
        bodyPayload
      );
    },
  });
};
