import useLocalStorage from "@rehooks/local-storage";
import { getToken, getMessaging } from "firebase/messaging";
import { FIREBASE_VAPID_KEY } from "../constants/env";

const LOCAL_MESSAGING_VAPID_KEY = "local_messaging_vapid_key";

export function useLocalMessagingVapid() {
  const [vapid, setVapid] = useLocalStorage<string>(LOCAL_MESSAGING_VAPID_KEY);

  const requestMessagingToken = () => {
    return getToken(getMessaging(), {
      vapidKey: FIREBASE_VAPID_KEY,
    }).then((currentToken) => {
      setVapid(currentToken.toString());
      return currentToken;
    });
  };

  return {
    vapid,
    requestMessagingToken,
  };
}
