import {
  useInvalidateListAppointments,
  useListAppointments,
} from "@/shared/hooks/establishment/appointments/use-list-appointments";
import { Appointment } from "@/shared/types/appointments";
import { AppointmentItem } from "@/modules/establishment/components/attending/appointment-item";
import { format, parseISO } from "date-fns";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEstablishmentContext } from "@/contexts/establishment/hook";

export const PendingAppointments = () => {
  const { establishmentId } = useParams<{ establishmentId: string }>();

  if (!establishmentId) {
    throw new Error("establishmentId is required");
  }

  const { linkInvite } = useEstablishmentContext();
  const { data, fetchNextPage, hasNextPage } = useListAppointments({
    establishmentId,
    status: "PENDING",
    linkedEmail: linkInvite?.email,
  });
  const invalidateListAppointments = useInvalidateListAppointments();

  const appointments = data.pages.reduce((acc, page) => {
    return [...acc, ...page.data.appointments];
  }, [] as Appointment[]);

  const appointmentsGrouped = appointments.reduce((acc, appointment) => {
    const appointmentAtString = format(
      parseISO(appointment.appointmentAt),
      "dd/MM/yyyy"
    );
    if (!acc[appointmentAtString]) {
      acc[appointmentAtString] = [appointment];
      return acc;
    }

    acc[appointmentAtString].push(appointment);

    return acc;
  }, {} as Record<string, Appointment[]>);

  useEffect(() => {
    if (!hasNextPage) return;

    fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  if (!appointments.length) {
    return (
      <Typography textAlign="center">Nenhum agendamento pendente</Typography>
    );
  }

  const handleInvalidateListAppointments = () => {
    invalidateListAppointments({
      establishmentId,
      status: "PENDING",
      linkedEmail: linkInvite?.email,
    });
  };

  return (
    <div className="flex flex-col gap-y-8 mx-2">
      {Object.keys(appointmentsGrouped).map((date) => {
        return (
          <div key={date} className="flex flex-col gap-y-4">
            <div className="text-lg font-semibold">
              <Typography variant="h6" fontWeight="bold">
                {date}
              </Typography>
            </div>
            {appointmentsGrouped[date].map((appointment) => (
              <AppointmentItem
                appointment={appointment}
                onAppointmentAccepted={handleInvalidateListAppointments}
                onAppointmentRejected={handleInvalidateListAppointments}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};
