import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { useGetResults } from "@/shared/hooks/establishment/use-get-results";
import { Action, getTodayDateState, reducer } from "./reducer";
import { useReducer, useState } from "react";
import { Clients } from "./clients";
import { Filters } from "./filters";
import { Services } from "./services";
import { Consume } from "./consume";

export const ContentByPeriod = () => {
  const [state, dispatch] = useReducer(reducer, getTodayDateState());
  const [period, setPeriod] = useState<Action["type"]>("today");
  const { linkInvite, establishment } = useEstablishmentContext();
  const { data } = useGetResults({
    establishmentId: establishment.establishmentId,
    startDate: state.startDate,
    endDate: state.endDate,
    linkedCompanyId: linkInvite?.linkedCompanyId,
    originCompanyId: linkInvite?.originCompanyId,
  });

  const handleFilterByPeriod = (period: Action["type"]) => {
    dispatch({ type: period });
    setPeriod(period);
  };

  return (
    <div className="flex flex-col gap-y-4">
      <Filters period={period} onFilter={handleFilterByPeriod} />
      <Clients clients={data?.data.clients} />
      <Services state={state} services={data?.data.services} />
      <Consume
        state={state}
        consumedItems={data?.data.consumedItems}
        services={data?.data.services}
      />
    </div>
  );
};
