import { Await, useLoaderData } from "react-router-dom";
import { Establishment } from "@/shared/types/establishments";
import { LinkInvite } from "@/shared/types/invite-link";
import { Header } from "../components/header";
import { ListEstablishments } from "../components/list-establishments";
import { FabNewSpace } from "../components/fab-new-space";
import { Suspense } from "react";
import { FullscreenLoading } from "@/shared/components/loadings/fullscreen-loading";
import { ErrorBoundary } from "@sentry/react";
import { Typography } from "@mui/material";

export function Establishments() {
  const { data } = useLoaderData() as {
    data: {
      establishments: Establishment[];
      externalEstablishments: (Establishment & { linkInvite: LinkInvite })[];
    };
  };

  return (
    <>
      <Header />

      <ErrorBoundary
        fallback={<Typography>Erro ao carregar os dados</Typography>}
      >
        <Suspense fallback={<FullscreenLoading />}>
          <Await resolve={data}>
            {({ establishments, externalEstablishments }) => (
              <>
                <ListEstablishments establishments={establishments} />

                {externalEstablishments.length ? (
                  <ListEstablishments establishments={externalEstablishments} />
                ) : null}

                <FabNewSpace establishments={establishments} />
              </>
            )}
          </Await>
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
