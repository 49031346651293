import { FetchUserAttributesOutput } from "aws-amplify/auth";
import { createContext } from "react";

type CustomAttributes = {
  "custom:type"?: "COMPANY";
  "custom:stripeCustomerId"?: string;
  "custom:stripeSubscription"?: "ACTIVE" | "INACTIVE";
  "custom:vapid"?: string;
};

export const UserCompanyContext = createContext<{
  companyAttributes: (FetchUserAttributesOutput & CustomAttributes) | null;
  hasSubscription: boolean;
}>({ companyAttributes: null, hasSubscription: false });
