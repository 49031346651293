import { apiAdmin } from "@/shared/services/api";
import { AxiosResponse } from "axios";
import { useMutation } from "@tanstack/react-query";
import { LinkInvite } from "@/shared/types/invite-link";

type BodyPayload = {
  wasServed: boolean;
  linkInvite?: LinkInvite;
};

type RequestParams = {
  establishmentId: string;
  queueId: string;
} & BodyPayload;

export function useRemoveClientFromEstablishmentQueue() {
  return useMutation<AxiosResponse, unknown, RequestParams>({
    mutationFn: async ({
      establishmentId,
      queueId: queueId,
      wasServed,
      linkInvite = null,
    }) => {
      const admin = await apiAdmin();
      return admin.delete(
        `/establishments/${establishmentId}/queue/${queueId}`,
        { data: { wasServed, linkInvite } }
      );
    },
  });
}
