import { useListAppointments } from "@/shared/hooks/establishment/appointments/use-list-appointments";
import { Typography } from "@mui/material";
import { addDays, format } from "date-fns";
import { AppointmentItem } from "./appointment-item";
import { Appointment } from "@/shared/types/appointments";
import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { useAttending } from "@/contexts/attending/hook";
import { FabCreateBooking } from "./fab-create-booking";
import { LoadingButton } from "@mui/lab";

export const BookingView = () => {
  const { establishment, linkInvite } = useEstablishmentContext();
  const { selectedDate } = useAttending();
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useListAppointments({
      establishmentId: establishment.establishmentId,
      startDate: format(selectedDate, "yyyy-MM-dd'T03:00:00'"),
      endDate: format(addDays(selectedDate, 1), "yyyy-MM-dd'T02:59:59'"),
    });

  const { appointments } = data.pages.reduce(
    (acc, page) => {
      return {
        appointments: [...acc.appointments, ...page.data.appointments],
      };
    },
    { appointments: [] as Appointment[] }
  );

  return (
    <>
      <div className="flex flex-col gap-y-4">
        <div className="mx-4">
          <Typography variant="h6">Horários agendados</Typography>
        </div>

        <div className="mx-4 flex flex-col gap-y-4 mb-20">
          {!appointments.length ? (
            <div className="mt-8 flex flex-col items-center">
              <img
                className="max-w-[200px]"
                src="/images/undraw_booking.svg"
                alt="Imagem representando agendamento"
              />
              <Typography
                className="text-center mt-4"
                textAlign="center"
                color="GrayText"
              >
                Não há agendamentos para este dia.
              </Typography>
            </div>
          ) : null}

          {appointments.map((appointment) => (
            <AppointmentItem
              key={appointment.appointmentId}
              appointment={appointment}
            />
          ))}

          {hasNextPage ? (
            <LoadingButton
              loading={isFetchingNextPage}
              onClick={() => fetchNextPage()}
            >
              Carregar mais
            </LoadingButton>
          ) : null}
        </div>
      </div>

      {!linkInvite ? <FabCreateBooking /> : null}
    </>
  );
};
