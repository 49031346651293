import { confirmSignUp, ConfirmSignUpOutput } from "aws-amplify/auth";
import { useMutation } from "@tanstack/react-query";

type UseSignUpConfirmationPayload = {
  email: string;
  confirmationCode: string;
};

type UseSignUpConfirmationData = {
  nextStep: ConfirmSignUpOutput["nextStep"];
};

export function useSignUpConfirmation() {
  return useMutation<
    UseSignUpConfirmationData,
    Error,
    UseSignUpConfirmationPayload
  >({
    mutationFn: ({ email, confirmationCode }) =>
      confirmSignUp({ username: email, confirmationCode }),
  });
}
