import { EstablishmentProvider } from "@/contexts/establishment/provider";
import { FullscreenLoading } from "@/shared/components/loadings/fullscreen-loading";
import { LinkInvitesOutput } from "@/shared/services/barx-api/companies/link-invites/list";
import {
  Establishment,
  EstablishmentSlug,
} from "@/shared/types/establishments";
import { Suspense } from "react";
import { Await, Outlet, useLoaderData } from "react-router-dom";

export const EstablishmentLayout = () => {
  const { data } = useLoaderData() as {
    data: {
      establishment: Establishment;
      invite: LinkInvitesOutput[number];
      establishmentSlug: EstablishmentSlug;
    };
  };

  return (
    <Suspense fallback={<FullscreenLoading />}>
      <Await resolve={data}>
        {({ establishment, invite, establishmentSlug }) => (
          <EstablishmentProvider
            establishment={establishment}
            linkInvite={invite}
            establishmentSlug={establishmentSlug}
          >
            <Outlet />
          </EstablishmentProvider>
        )}
      </Await>
    </Suspense>
  );
};
