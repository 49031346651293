import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { Info } from "@mui/icons-material";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Tooltip,
  Skeleton,
} from "@mui/material";

export const LastWeekSummary = () => {
  const { establishment } = useEstablishmentContext();

  const waitingTimeAverage = Number(establishment.minutesWaitingTimeAverage);
  const totalWaitingTimeAverage =
    waitingTimeAverage > 60
      ? `${(waitingTimeAverage / 60).toFixed(0)} hora(s)`
      : `${waitingTimeAverage.toFixed(0)} minuto(s)`;

  const servingTimeAverage = Number(establishment.minutesServingTimeAverage);
  const totalServingTimeAverage =
    servingTimeAverage > 60
      ? `${(servingTimeAverage / 60).toFixed(0)} hora(s)`
      : `${servingTimeAverage.toFixed(0)} minuto(s)`;

  const journeyTimeAverage = Number(establishment.minutesJourneyTimeAverage);
  const totalJourneyTimeAverage =
    journeyTimeAverage > 60
      ? `${(journeyTimeAverage / 60).toFixed(0)} hora(s)`
      : `${journeyTimeAverage.toFixed(0)} minuto(s)`;

  return (
    <Card>
      <CardHeader title="Resumo da última semana" />
      <CardContent className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <Typography className="text-gray-500 flex items-center gap-2">
            Tempo médio de espera
            <Tooltip title="Este é o tempo médio que seus clientes aguardam na fila até iniciar o atendimento.">
              <Info color="info" />
            </Tooltip>
          </Typography>
          <Typography variant="h6">
            {totalWaitingTimeAverage ?? <Skeleton variant="rounded" />}
          </Typography>
        </div>
        <div>
          <Typography className="text-gray-500 flex items-center gap-2">
            Tempo médio de atendimento
            <Tooltip title="Este é o tempo médio que leva para concluir o serviço dos clientes.">
              <Info color="info" />
            </Tooltip>
          </Typography>
          <Typography variant="h6">
            {totalServingTimeAverage ?? <Skeleton variant="rounded" />}
          </Typography>
        </div>
        <div>
          <Typography className="text-gray-500 flex items-center gap-2">
            Tempo médio da jornada
            <Tooltip title="Este é o tempo médio desde o cliente entrar na fila até concluir o atendimento.">
              <Info color="info" />
            </Tooltip>
          </Typography>
          <Typography variant="h6">
            {totalJourneyTimeAverage ?? <Skeleton variant="rounded" />}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};
