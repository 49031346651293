import { cancelLinkedInvite } from "@/shared/services/barx-api/companies/linked-invites/cancel";
import { useMutation } from "@tanstack/react-query";

type Payload = {
  linkedEmail: string;
  originEstablishmentId: string;
};

export const useCancelLinkedInvite = () => {
  return useMutation<unknown, Error, Payload>({
    mutationFn: ({ linkedEmail, originEstablishmentId }) =>
      cancelLinkedInvite({ linkedEmail, originEstablishmentId }),
  });
};
