import { PropsWithChildren, useTransition } from "react";
import { GlobalPageLoadingContext } from "./context";
import { LinearProgress } from "@mui/material";

export const GlobalPageLoadingProvider = ({ children }: PropsWithChildren) => {
  const [isPending, startTransition] = useTransition();
  const pageTransition = (callback: () => void) => {
    startTransition(() => {
      callback();
    });
  };

  return (
    <GlobalPageLoadingContext.Provider value={{ pageTransition }}>
      {isPending ? (
        <LinearProgress
          className="fixed top-0 left-0 z-[1500] w-full"
          color="secondary"
        />
      ) : null}
      {children}
    </GlobalPageLoadingContext.Provider>
  );
};
