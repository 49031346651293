import { deleteEstablishment } from "@/shared/services/barx-api/establishments/delete";
import { useMutation } from "@tanstack/react-query";

type MutationParams = {
  establishmentId: string;
};

export const useDeleteEstablishment = () => {
  return useMutation<unknown, unknown, MutationParams>({
    mutationFn: ({ establishmentId }) =>
      deleteEstablishment({ establishmentId }),
  });
};
