import { Typography } from "@mui/material";
import { Header } from "../components/header";
import { ListPaymentMethods } from "../components/list";
import { FabManagePaymentMethod } from "../components/fab-manage-payment-method";

export const EstablishmentPaymentMethod = () => {
  return (
    <>
      <Header />

      <section className="p-4 flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-2">
          <Typography>
            Cadastre as formas de pagamento aceitas pelo estabelecimento.
          </Typography>
          <Typography variant="body2" color="GrayText">
            As formas de pagamento cadastradas serão exibidas no aplicativo para
            que os clientes saibam quais formas de pagamento seu estabelecimento
            aceita.
          </Typography>
        </div>

        <ListPaymentMethods />
      </section>

      <FabManagePaymentMethod />
    </>
  );
};
