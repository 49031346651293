import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { useCloseEstablishment } from "@/shared/hooks/establishment/use-close-establishment";
import { useInvalidateGetEstablishment } from "@/shared/hooks/establishment/use-get-establishment";
import { Establishment } from "@/shared/types/establishments";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";

type Props = {
  open: boolean;
  onClose(): void;
  establishment: Establishment;
};

type FormData = {
  message?: string;
};

export const CloseEstablishmentDialog = ({
  onClose,
  open,
  establishment,
}: Props) => {
  const { handleSubmit, register, reset } = useForm<FormData>();
  const { mutate, isPending } = useCloseEstablishment();
  const { showSnackbar } = useGlobalSnackbar();
  const invalidateGetEstablishment = useInvalidateGetEstablishment();

  const handleClose = () => {
    reset();
    onClose();
  };

  const submit = handleSubmit(({ message }) => {
    mutate(
      {
        establishmentId: establishment.establishmentId,
        message: message,
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: "Fila pausada com sucesso",
            severity: "success",
          });
          invalidateGetEstablishment({
            companyId: establishment.companyId,
            establishmentId: establishment.establishmentId,
          });
          handleClose();
        },
        onError() {
          showSnackbar({
            message: "Erro ao pausar fila",
            severity: "error",
          });
        },
      }
    );
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      component="form"
      onSubmit={submit}
    >
      <DialogTitle>Pausar fila</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Seus clientes não poderão entrar na fila enquanto ela estiver pausada.
        </DialogContentText>

        <TextField
          {...register("message")}
          className="mt-4"
          fullWidth
          label="Gostaria de mostrar uma mensagem?"
          variant="filled"
          multiline
          helperText="A mensagem será exibida para os clientes que tentarem entrar na fila."
        />
      </DialogContent>
      <DialogActions className="flex flex-row-reverse justify-start gap-x-3">
        <LoadingButton type="submit" variant="contained" loading={isPending}>
          Pausar
        </LoadingButton>
        <Button onClick={handleClose} disabled={isPending}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
