import { CalendarMonth } from "@mui/icons-material";
import { Chip, Paper, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { format, isToday } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useEffect, useRef, useState } from "react";
import {
  getBookingWeekDays,
  getWorkingHour,
  translateAttendingType,
} from "@/shared/helpers/working-hour";
import { twMerge } from "tailwind-merge";
import { Establishment } from "@/shared/types/establishments";

type Props = {
  establishment: Establishment;
  onSelectedDate: (date: Date) => void;
  disablePast?: boolean;
};

export const DatesView = ({
  onSelectedDate,
  establishment,
  disablePast = true,
}: Props) => {
  const todayDate = new Date();
  const [selectedDate, setSelectedDate] = useState<Date>(todayDate);
  const ref = useRef<HTMLDivElement>(null);
  const enabledWeekDays = getBookingWeekDays(establishment);
  const isCustomDate = !isToday(selectedDate);
  const highlightCard =
    "border-2 border-solid border-gray-400 bg-gray-50 dark:bg-gray-900";

  const selectDate = (date: Date) => {
    setSelectedDate(date);
  };
  const { attendingType: todayAttendingType, enabled: todayEnabled } =
    getWorkingHour(establishment, todayDate);
  const { attendingType: selectedAttendingType } = getWorkingHour(
    establishment,
    selectedDate
  );
  const isSameDate = selectedDate?.getDate() === todayDate.getDate();

  useEffect(() => {
    onSelectedDate(selectedDate);
  }, [selectedDate, onSelectedDate]);

  return (
    <>
      <div className="grid grid-cols-2 gap-x-3 gap-y-4 mx-4">
        <Paper
          key={todayDate.getDate()}
          component="button"
          variant={isSameDate ? "elevation" : "outlined"}
          className={twMerge(
            "px-4 py-2 flex flex-col justify-center items-center cursor-pointer",
            isSameDate && highlightCard
          )}
          onClick={() => selectDate(todayDate)}
        >
          <Typography textAlign="center" variant="h6" fontWeight="bold">
            Hoje
          </Typography>
          <Typography
            textAlign="center"
            textTransform="capitalize"
            color="GrayText"
          >
            {format(todayDate, "dd")},{" "}
            {format(todayDate, "EEEEEE", { locale: ptBR })}
          </Typography>
          {todayEnabled ? (
            <Chip
              className="self-center mt-2"
              label={translateAttendingType(todayAttendingType)}
              size="small"
            />
          ) : (
            <Chip className="self-center mt-2" label="Fechado" size="small" />
          )}
        </Paper>

        <Paper
          component="button"
          variant="outlined"
          className={twMerge(
            "px-4 py-2 flex flex-col items-center justify-center",
            isCustomDate ? highlightCard : "",
            !enabledWeekDays.length ? "opacity-40" : "cursor-pointer"
          )}
          onClick={() => {
            ref.current?.click();
          }}
          disabled={!enabledWeekDays.length}
        >
          <CalendarMonth className="mb-2" />
          <Typography textAlign="center" color="GrayText" lineHeight="16px">
            Ver agendamentos
          </Typography>
        </Paper>

        <div className="hidden">
          <MobileDatePicker
            ref={ref}
            disablePast={disablePast}
            value={selectedDate}
            shouldDisableDate={(date) =>
              !enabledWeekDays.includes(date.getDay())
            }
            onAccept={(value) => {
              if (value) selectDate(value);
            }}
          />
        </div>

        {!isToday(selectedDate) ? (
          <div className="col-span-2 justify-self-center">
            <Typography variant="body2" color="GrayText" textAlign="center">
              Você está visualizando o dia
            </Typography>
            <Typography fontWeight="bold">
              {format(selectedDate, "dd/MM/yyyy")} -{" "}
              {translateAttendingType(selectedAttendingType)}
            </Typography>
          </div>
        ) : null}
      </div>
    </>
  );
};
