import { TextLabel } from "@/shared/components/text-label";
import { ListEstablishmentCustomerOutput } from "@/shared/hooks/establishment/use-list-establishment-customers";
import { Card, CardHeader, CardContent } from "@mui/material";
import { parseISO, differenceInDays, isToday } from "date-fns";

type Props = {
  customer: ListEstablishmentCustomerOutput["customers"][number];
};

export const CardItem = ({
  customer: { name, timesServed, updatedAt },
}: Props) => {
  const lastTimeServed = buildLastTimeServedText(updatedAt);

  const hasUndefinedName = name.includes("undefined undefined");
  const customerName = hasUndefinedName ? "Nome não informado" : name;

  return (
    <Card>
      <CardHeader title={customerName} />
      <CardContent className="grid grid-cols-2 gap-4">
        <TextLabel label="Vezes que voltou" text={timesServed} />
        <TextLabel label="Último atendimento" text={lastTimeServed} />
      </CardContent>
    </Card>
  );
};

function buildLastTimeServedText(updatedAt: string): string {
  let lastTimeServed: string;
  const date = parseISO(updatedAt);
  const difference = differenceInDays(
    new Date(),
    new Date(date.getFullYear(), date.getMonth(), date.getDate())
  );

  if (isToday(date)) {
    lastTimeServed = "Hoje";
  } else if (difference === 1) {
    lastTimeServed = "Ontem";
  } else {
    lastTimeServed = `${difference} dias atrás`;
  }

  return lastTimeServed;
}
