import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { useListEstablishmentCustomers } from "@/shared/hooks/establishment/use-list-establishment-customers";
import { Typography, Alert, AlertTitle } from "@mui/material";
import { CardItem } from "./card-item";

export const ListCustomers = () => {
  const { establishment, linkInvite } = useEstablishmentContext();
  const { establishmentId } = establishment;
  const { data } = useListEstablishmentCustomers({
    establishmentId,
    linkInvite,
  });
  const customers = data?.data.customers;

  return (
    <div className="flex flex-col gap-y-3 p-4">
      {customers?.length ? (
        <Typography>{customers?.length} cliente(s) encontrado(s).</Typography>
      ) : null}

      {!customers?.length ? (
        <Alert severity="warning">
          <AlertTitle>Nenhum cliente encontrado</AlertTitle>
          <Typography variant="body2" className="mb-1">
            Os clientes que você atender aparecerão aqui
          </Typography>
        </Alert>
      ) : null}

      {customers?.map((customer) => {
        return <CardItem key={customer.customerId} customer={customer} />;
      })}
    </div>
  );
};
