import { acceptLinkInvite } from "@/shared/services/barx-api/companies/link-invites/accept";
import { useMutation } from "@tanstack/react-query";

type Payload = {
  email: string;
  originEstablishmentId: string;
};

export const useAcceptLinkInvite = () => {
  return useMutation<unknown, Error, Payload>({
    mutationFn: ({ email, originEstablishmentId }) =>
      acceptLinkInvite({ linkedEmail: email, originEstablishmentId }),
  });
};
