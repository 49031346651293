import { API_URL } from "@/shared/constants/env";
import { authenticatedAxios } from "../../fetch";

export const acceptLinkInvite = async ({
  linkedEmail,
  originEstablishmentId,
}: {
  linkedEmail: string;
  originEstablishmentId: string;
}) => {
  return (await authenticatedAxios()).patch(
    `${API_URL}/admin/companies/link-invites/${linkedEmail}/accept`,
    { originEstablishmentId }
  );
};
