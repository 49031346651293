import { listLinkInvites } from "@/shared/services/barx-api/companies/link-invites/list";
import { getEstablishment } from "@/shared/services/barx-api/establishments/get";
import { listEstablishments } from "@/shared/services/barx-api/establishments/list";
import { fetchUserAttributes } from "aws-amplify/auth";
import { defer } from "react-router-dom";

export const establishmentsLoader = async () => {
  return defer({
    data: getData(),
  });
};

const getData = async () => {
  const { email } = await fetchUserAttributes();

  if (!email) throw new Error("email not found.");

  const { data: invites } = await listLinkInvites({ linkedEmail: email });
  const externalEstablishmentsPromise = invites
    .filter((invite) => invite.status !== "PENDING")
    .map(async (invite) => {
      return getEstablishment({
        companyId: invite.originCompanyId,
        establishmentId: invite.originEstablishmentId,
      }).then(({ data }) => ({
        ...data.establishment,
        linkInvite: invite,
      }));
    });

  const [{ data: establishments }, externalEstablishments] = await Promise.all([
    listEstablishments(),
    Promise.all(externalEstablishmentsPromise),
  ]);

  return {
    establishments,
    externalEstablishments,
  };
};
