import { apiPublic } from "@/shared/services/api";
import { AxiosResponse } from "axios";
import { useMutation } from "@tanstack/react-query";
import { useLocalCompanyData } from "../auth/companies/use-local-company-data";
import { useLocalMessagingVapid } from "../use-local-messaging-vapid";
import { QueueAddedBy } from "@/shared/types/establishments";

export type MutationParams = {
  companyId: string;
  establishmentId: string;
  clientName: string;
  addedBy: QueueAddedBy;
  serviceTypes: string;
  barber: {
    givenName: string;
    familyName: string;
    companyId: string;
  } | null;
  customerId?: string;
};

type ResponseData = {
  queueId: string;
};

export function useAddClientInEstablishmentQueue() {
  const { company } = useLocalCompanyData();
  const { vapid } = useLocalMessagingVapid();
  const messagingVapid = !company ? vapid ?? undefined : undefined;
  return useMutation<AxiosResponse<ResponseData>, unknown, MutationParams>({
    mutationFn: async ({
      companyId,
      establishmentId,
      clientName,
      serviceTypes,
      barber,
      customerId,
      addedBy,
    }) => {
      const client = await apiPublic();
      return client.post(
        `/companies/${companyId}/establishments/${establishmentId}/queue`,
        {
          clientName,
          serviceTypes,
          barber,
          customerId,
          messagingVapid,
          addedBy,
        }
      );
    },
  });
}
