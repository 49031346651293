import {
  Establishment,
  EstablishmentSlug,
} from "@/shared/types/establishments";
import { LinkInvite } from "@/shared/types/invite-link";
import { PropsWithChildren } from "react";
import { EstablishmentContext } from "./context";

type Props = {
  linkInvite?: LinkInvite;
  establishment: Establishment;
  establishmentSlug: EstablishmentSlug;
};

export const EstablishmentProvider = ({
  children,
  linkInvite,
  establishment,
  establishmentSlug,
}: PropsWithChildren<Props>) => {
  return (
    <EstablishmentContext.Provider
      value={{ linkInvite, establishment, establishmentSlug }}
    >
      {children}
    </EstablishmentContext.Provider>
  );
};
