import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ObjectSchema, object, string } from "yup";
import ReactInputMask from "@mona-health/react-input-mask";
import { useUpdateProfile } from "@/shared/hooks/use-update-profile";
import { LoadingButton } from "@mui/lab";
import { captureException } from "@sentry/react";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { useUserCompany } from "@/contexts/user/hook";

type Fields = {
  given_name: string;
  family_name: string;
  phone_number: string;
};

const schema: ObjectSchema<Fields> = object({
  given_name: string().required("Primeiro nome é obrigatório"),
  family_name: string().required("Último nome é obrigatório"),
  phone_number: string()
    .transform((value) => String(value).replace(/\D/g, ""))
    .length(11, "Telefone inválido")
    .required("Telefone é obrigatório"),
});

export const InfoForm = () => {
  const { companyAttributes } = useUserCompany();
  const { register, control, formState, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      given_name: companyAttributes?.given_name ?? "",
      family_name: companyAttributes?.family_name ?? "",
      phone_number: companyAttributes?.phone_number?.substring(3) ?? "",
    },
  });

  const { mutate, isPending } = useUpdateProfile();
  const { showSnackbar } = useGlobalSnackbar();

  const submit = handleSubmit(({ family_name, given_name, phone_number }) => {
    const phoneNumber = phone_number
      ? `+55${phone_number.replace(/\D/g, "")}`
      : undefined;

    mutate(
      {
        familyName: family_name,
        givenName: given_name,
        phoneNumber,
      },
      {
        onSuccess() {
          showSnackbar({
            message: "Perfil atualizado com sucesso",
            severity: "success",
          });
        },
        onError(error) {
          captureException(error);
          showSnackbar({
            message: "Ocorreu um erro ao atualizar o perfil",
            severity: "error",
          });
        },
      }
    );
  });

  return (
    <form className="flex flex-col gap-y-4 m-4" onSubmit={submit}>
      <Typography fontWeight="bold" color="GrayText">
        Informações básicas
      </Typography>

      <TextField
        label="E-mail"
        fullWidth
        value={companyAttributes?.email}
        disabled
      />

      <div className="grid grid-cols-2 gap-x-3">
        <TextField
          {...register("given_name")}
          label="Primeiro nome"
          error={!!formState.errors.given_name}
          helperText={formState.errors.given_name?.message}
        />
        <TextField
          {...register("family_name")}
          label="Último nome"
          error={!!formState.errors.family_name}
          helperText={formState.errors.family_name?.message}
        />
      </div>

      <Controller
        control={control}
        name="phone_number"
        render={({ field: { ref, ...field } }) => (
          <ReactInputMask
            {...field}
            mask="(99) 99999-9999"
            maskPlaceholder={null}
          >
            <TextField
              ref={ref}
              label="Telefone"
              fullWidth
              inputMode="numeric"
              error={!!formState.errors.phone_number}
              helperText={
                formState.errors.phone_number?.message ??
                "O telefone é utilizado para receber informações importantes"
              }
            />
          </ReactInputMask>
        )}
      />

      <LoadingButton
        className="self-end"
        variant="contained"
        type="submit"
        color="primary"
        disabled={!formState.isValid}
        loading={isPending}
      >
        Salvar alterações
      </LoadingButton>
    </form>
  );
};
