"use client";

import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import { useSuspenseQuery, useQuery } from "@tanstack/react-query";

export function useCurrentUserAttributes() {
  return useQuery({
    queryKey: ["currentUser"],
    queryFn: () => fetchUserAttributes(),
  });
}

export const useCurrentUser = () => {
  return useSuspenseQuery({
    queryKey: ["currentUser"],
    queryFn: async () => {
      const result = await getCurrentUser();
      return result;
    },
  });
};
