import { Edit } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { DrawerAddPaymentMethod } from "./drawer-add";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useEstablishmentContext } from "@/contexts/establishment/hook";

const DRAWER_ADD_PAYMENT_METHOD_HASH = "#manage-payment-methods";

export const FabManagePaymentMethod = () => {
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();
  const [URLHash, setURLHash] = useState<string>();
  const { establishment } = useEstablishmentContext();

  const paymentMethods = establishment.paymentMethods;

  const showDrawer = !!URLHash?.includes(DRAWER_ADD_PAYMENT_METHOD_HASH);

  const handleOpenDrawer = () => {
    navigate(`${pathname}${DRAWER_ADD_PAYMENT_METHOD_HASH}`);
  };
  const handleCloseDrawer = () => {
    navigate(pathname, { replace: true });
  };

  useEffect(() => {
    setURLHash(hash);
  }, [hash]);

  return (
    <>
      <Fab
        className="right-4 bottom-20"
        style={{ position: "fixed" }}
        variant="extended"
        color="primary"
        onClick={handleOpenDrawer}
      >
        <Edit className="mr-1" />
        Alterar
      </Fab>

      <DrawerAddPaymentMethod
        open={showDrawer}
        onClose={handleCloseDrawer}
        paymentMethods={paymentMethods}
      />
    </>
  );
};
