import { apiAdmin } from "@/shared/services/api";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

type MutationParams = {
  establishmentId: string;
  appointmentId: string;
  linkedEmail?: string;
};

export const useConcludeAppointment = () =>
  useMutation<AxiosResponse, Error, MutationParams>({
    mutationFn: async ({ establishmentId, appointmentId, linkedEmail }) => {
      const http = await apiAdmin();
      return http.patch(
        `/establishments/${establishmentId}/appointments/${appointmentId}/conclude`,
        undefined,
        { params: { linkedEmail } }
      );
    },
  });
