import { AppointmentInput } from "@/shared/drawers/attending-client-drawer/use-form-schema";
import { apiAdmin } from "@/shared/services/api";
import { useMutation } from "@tanstack/react-query";

type MutationParams = {
  establishmentId: string;
  appointmentAt: string;
  barber: null | {
    givenName: string;
    familyName: string;
    companyId: string;
  };
} & Omit<AppointmentInput, "barberId" | "appointmentAt">;

export const useCreateAppointment = () =>
  useMutation<void, Error, MutationParams>({
    mutationFn: async ({ establishmentId, ...values }) => {
      const http = await apiAdmin();
      return http.post(`/establishments/${establishmentId}/appointments`, {
        establishmentId,
        ...values,
      });
    },
  });
