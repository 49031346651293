import { Typography, Card, CardContent, Button } from "@mui/material";
import { ChevronLeftRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { RegisterForm } from "../components/register-form";

export function AuthRegister() {
  const navigate = useNavigate();

  return (
    <div className="flex w-full justify-center items-center">
      <Card className="w-full max-w-[400px]">
        <CardContent>
          <div className="flex flex-col gap-y-4">
            <Button
              variant="text"
              className="w-max"
              onClick={() => navigate(ROUTES_PATH.AUTH)}
            >
              <div className="flex items-center">
                <ChevronLeftRounded />
                <Typography variant="body2">Voltar</Typography>
              </div>
            </Button>

            <Typography variant="h5" className="mb-2" fontWeight="bold">
              Criar nova conta
            </Typography>

            <RegisterForm />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
