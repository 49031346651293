import { useEffect, useState } from "react";
import {
  GetEstablishmentQueueResponseData,
  useGetEstablishmentQueue,
} from "./use-get-establishment-queue";
import { useRealtimeEstablishmentQueue } from "./use-realtime-establishment-queue";

type Params = {
  companyId: string;
  establishmentId: string;
};

export function useWatchEstablishmentQueue({
  companyId,
  establishmentId,
}: Params) {
  const [queue, setQueue] = useState<GetEstablishmentQueueResponseData>([]);
  const { data, status, refetch } = useGetEstablishmentQueue({
    companyId,
    establishmentId,
  });
  const { establishmentQueue, connectionState } = useRealtimeEstablishmentQueue(
    { establishmentId }
  );

  useEffect(() => {
    if (!data) return;
    setQueue(data.data);
  }, [data]);

  useEffect(() => {
    if (!establishmentQueue) return;
    setQueue(establishmentQueue);
  }, [establishmentQueue]);

  return { establishmentQueue: queue, connectionState, status, refetch };
}
