import Hotjar from "@hotjar/browser";
import { useEffect } from "react";

const siteId = 5030552;
const hotjarVersion = 6;

export const useInitHotjar = () => {
  useEffect(() => {
    Hotjar.init(siteId, hotjarVersion);
  }, []);
};
