import {
  COGNITO_USER_POOL_ID,
  COGNITO_USER_POOL_CLIENT_ID,
  DOMAIN,
} from "@/shared/constants/env";
import { Amplify, ResourcesConfig } from "aws-amplify";

export const authConfig: ResourcesConfig["Auth"] = {
  Cognito: {
    userPoolId: COGNITO_USER_POOL_ID,
    userPoolClientId: COGNITO_USER_POOL_CLIENT_ID,
    loginWith: {
      oauth: {
        domain: "auth.barx.com.br",
        redirectSignIn: [`${DOMAIN}/customers/login`],
        redirectSignOut: [`${DOMAIN}/customers/login`, `${DOMAIN}/auth`],
        responseType: "code",
        scopes: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      },
    },
  },
};

Amplify.configure({ Auth: authConfig });
