import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { listOfPaymentMethods } from "@/shared/helpers/company";
import { useManagePaymentMethods } from "@/shared/hooks/establishment/use-manage-payment-methods";
import { PaymentMethod } from "@/shared/types/establishments";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

type Props = {
  open: boolean;
  onClose(): void;
  paymentMethods: PaymentMethod[];
};

type FormValues = {
  paymentMethods: PaymentMethod[];
};

export const DrawerAddPaymentMethod = ({
  onClose,
  open,
  paymentMethods,
}: Props) => {
  const { establishmentId } = useParams<{ establishmentId: string }>();

  if (!establishmentId) {
    throw new Error("establishmentId is required");
  }

  const { showSnackbar } = useGlobalSnackbar();
  const { mutate: managePaymentMethod, isPending } = useManagePaymentMethods({
    establishmentId,
  });

  const { register, handleSubmit, reset, resetField, control } =
    useForm<FormValues>({
      mode: "all",
      shouldUnregister: true,
      defaultValues: {
        paymentMethods: [],
      },
    });

  const loadDefaultValues = useCallback(() => {
    return listOfPaymentMethods.map((item) => {
      const defaultValue = paymentMethods.find(
        ({ method }) => method === item.value
      );

      return {
        method: defaultValue?.method,
        complement: defaultValue?.complement,
      };
    });
  }, [paymentMethods]);

  useEffect(() => {
    reset({ paymentMethods: loadDefaultValues() });
  }, [loadDefaultValues, reset]);

  const handleClose = () => {
    onClose();
  };

  const submit = handleSubmit((props) => {
    const paymentMethods = props.paymentMethods.filter((item) => !!item.method);
    managePaymentMethod(
      { paymentMethods },
      {
        onSuccess() {
          showSnackbar({
            message: "Forma de pagamento alterada.",
            severity: "success",
          });

          handleClose();
        },
        onError() {
          showSnackbar({
            message: "Erro ao alterar forma de pagamento.",
            severity: "error",
          });
        },
      }
    );
  });

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      keepMounted={false}
    >
      <div className="p-4 w-screen max-w-[300px] sm:max-w-md">
        <header className="flex items-center justify-between gap-6">
          <Typography variant="h6">Editar forma de pagamento</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </header>

        <form className="flex flex-col gap-y-4" onSubmit={submit}>
          <FormGroup>
            {listOfPaymentMethods.map(({ label, value }, index) => (
              <Controller
                key={value}
                control={control}
                name={`paymentMethods.${index}.method`}
                render={({ field }) => {
                  const savedValue = paymentMethods.find(
                    (item) => item.method === value
                  );

                  return (
                    <>
                      <FormControlLabel
                        control={
                          <Switch
                            {...field}
                            onChange={({ target }) => {
                              const isChecked = target.checked;
                              if (!isChecked) {
                                resetField(
                                  `paymentMethods.${index}.complement`
                                );
                              }
                              field.onChange(isChecked ? target.value : null);
                            }}
                            value={value}
                            defaultChecked={!!savedValue}
                          />
                        }
                        label={label}
                      />
                      {["pix"].includes(value) && field.value ? (
                        <TextField
                          {...register(`paymentMethods.${index}.complement`)}
                          label="Informe sua chave Pix"
                          variant="outlined"
                          fullWidth
                          className="mb-4"
                          autoComplete="off"
                        />
                      ) : null}
                    </>
                  );
                }}
              />
            ))}
          </FormGroup>

          <LoadingButton
            type="submit"
            variant="contained"
            className="self-end"
            loading={isPending}
            fullWidth
          >
            Salvar
          </LoadingButton>
        </form>
      </div>
    </Drawer>
  );
};
