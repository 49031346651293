import { object, string, ObjectSchema } from "yup";
import { InfoFields, LocationFields, WorkingHourFields } from "./types";

export const firstStepSchema: ObjectSchema<InfoFields> = object({
  establishmentName: string().required(),
});

export const secondStepSchema: ObjectSchema<WorkingHourFields> = object({
  workingHour: object(),
  configs: object({
    appointmentDuration: string().required(),
  }).required(),
});

export const thirdStepSchema: ObjectSchema<LocationFields> = object({
  location: object({
    cep: string().required(),
    state: string().required(),
    city: string().required(),
    neighborhood: string().required(),
    street: string().required(),
    number: string().required(),
    complement: string(),
  }).required(),
});

export const allSchemas = firstStepSchema
  .concat(secondStepSchema)
  .concat(thirdStepSchema);
