import { useGlobalPageLoading } from "@/contexts/global-page-loading/hook";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { useConfirmPassword } from "@/shared/hooks/auth/use-confirm-password";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { AuthError } from "aws-amplify/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { object, ObjectSchema, ref, string } from "yup";

type Props = {
  email: string;
};

type Inputs = {
  email: string;
  confirmationCode: string;
  newPassword: string;
  passwordConfirmation: string;
};

const schema: ObjectSchema<Inputs> = object({
  email: string().email("E-mail inválido").required("Campo obrigatório"),
  confirmationCode: string()
    .min(6, "Mínimo de 6 caracteres")
    .required("Campo obrigatório"),
  newPassword: string()
    .min(8, "Deve ter no mínimo 8 caracteres")
    .required("Campo obrigatório"),
  passwordConfirmation: string()
    .min(8, "Deve ter no mínimo 8 caracteres")
    .oneOf([ref("newPassword")], "Senha diferente.")
    .required("Campo obrigatório"),
});

export const ForgotPasswordConfirmForm = ({ email }: Props) => {
  const { mutate: forgotPassword, status } = useConfirmPassword();
  const navigate = useNavigate();
  const { formState, handleSubmit, register } = useForm<Inputs>({
    defaultValues: {
      email,
    },
    resolver: yupResolver(schema),
  });
  const { showSnackbar } = useGlobalSnackbar();
  const { pageTransition } = useGlobalPageLoading();

  const submit = ({ email, newPassword, confirmationCode }: Inputs) => {
    forgotPassword(
      { email, confirmationCode, newPassword },
      {
        onSuccess() {
          showSnackbar({
            message:
              "Senha recuperada. Redirecionando para página de Entrar...",
            severity: "success",
          });

          pageTransition(() => {
            navigate(ROUTES_PATH.AUTH, { replace: true });
          });
        },
        onError(err) {
          const error = err as AuthError;

          if (error.name === "ExpiredCodeException") {
            showSnackbar({
              message:
                "Este código expirou. Volte para a página de Esqueceu a senha.",
              severity: "error",
            });
            return;
          }

          showSnackbar({
            message: "Falha ao recuperar a senha, tente novamente.",
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-y-4">
      <TextField
        {...register("email")}
        label="E-mail"
        defaultValue={email}
        autoComplete="email"
        disabled
        error={!!formState.errors.email}
        helperText={formState.errors.email?.message}
      />

      <TextField
        {...register("newPassword")}
        label="Nova senha"
        type="password"
        autoComplete="new-password"
        placeholder="Digite sua nova senha"
        error={!!formState.errors.newPassword}
        helperText={formState.errors.newPassword?.message}
      />

      <TextField
        {...register("passwordConfirmation")}
        label="Confirme sua senha"
        type="password"
        autoComplete="new-password"
        placeholder="Confirme sua nova senha"
        error={!!formState.errors.passwordConfirmation}
        helperText={formState.errors.passwordConfirmation?.message}
      />

      <TextField
        {...register("confirmationCode")}
        label="Código de confirmação"
        autoComplete="off"
        placeholder="ex: 721721"
        error={!!formState.errors.confirmationCode}
        helperText={formState.errors.confirmationCode?.message}
      />

      <div className="flex w-full justify-end items-center gap-x-4">
        <LoadingButton
          type="submit"
          variant="contained"
          loading={status === "pending"}
          disabled={status === "success"}
        >
          Recuperar senha
        </LoadingButton>
      </div>
    </form>
  );
};
