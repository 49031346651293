import {
  EstablishmentConfigs,
  WorkingHourItem,
} from "@/shared/types/establishments";
import { authenticatedAxios } from "../fetch";
import { LocationFields } from "@/shared/drawers/manage-establishment/components/types";
import { API_URL } from "@/shared/constants/env";

type BodyPayload = {
  name: string;
  workingHour?: WorkingHourItem;
  location: LocationFields["location"];
  configs?: EstablishmentConfigs;
};

export const createEstablishmentAction = async (payload: BodyPayload) => {
  return (await authenticatedAxios()).post(
    `${API_URL}/admin/establishments`,
    payload
  );
};
