import { GetResultsOutput } from "@/shared/hooks/establishment/use-get-results";
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  Skeleton,
} from "@mui/material";

type Props = {
  clients?: GetResultsOutput["clients"];
};

export const Clients = ({ clients }: Props) => {
  const totalClients = clients?.total;
  const clientsServed = clients?.served;
  const clientsNotServed = clients?.notServed;

  return (
    <Card>
      <CardHeader title="Clientes" />
      <Divider />
      <CardContent className="flex flex-wrap items-start justify-between gap-6">
        <div>
          <Typography className="text-gray-500">Total de clientes</Typography>
          <Typography variant="h6">
            {totalClients ?? <Skeleton variant="rounded" />}
          </Typography>
        </div>

        <div>
          <Typography className="text-gray-500">Atendidos</Typography>
          <Typography variant="h6">
            {clientsServed ?? <Skeleton variant="rounded" />}
          </Typography>
        </div>

        <div>
          <Typography className="text-gray-500">Não atendidos</Typography>
          <Typography variant="h6">
            {clientsNotServed ?? <Skeleton variant="rounded" />}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};
