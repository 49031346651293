import { useCurrentUser } from "@/shared/hooks/auth/use-user-attributes";
import { useListServiceTypes } from "@/shared/hooks/establishment/service-types/use-list-service-types";
import { Alert, AlertTitle, Typography } from "@mui/material";
import { CardItems } from "../components/card-item";
import { useParams } from "react-router-dom";
import { Header } from "../components/header";
import { FabNewService } from "../components/fab-new-service";

const TIP_TEXT =
  "Os serviços cadastrados poderão ser escolhidos pelos clientes no momento de entrar na fila ou realizar um agendamento.";

export function EstablishmentServiceTypes() {
  const { establishmentId } = useParams<{ establishmentId: string }>();

  if (!establishmentId) {
    throw new Error("Missing establishmentId");
  }

  const { data: user } = useCurrentUser();
  const companyId = user.userId;
  const {
    data: {
      data: { serviceTypes },
    },
  } = useListServiceTypes({
    companyId,
    establishmentId,
  });

  return (
    <>
      <Header />

      <div className="flex flex-col gap-y-4 p-4">
        {!serviceTypes.length ? (
          <Alert severity="info">
            <AlertTitle>Nenhum tipo de serviço cadastrado</AlertTitle>
            {TIP_TEXT}
          </Alert>
        ) : (
          <div className="flex flex-col gap-y-3 mb-2">
            <Typography color="GrayText">{TIP_TEXT}</Typography>
            <Typography color="GrayText">
              O cliente poderá escolher um ou mais serviços para ser atendido.
            </Typography>
          </div>
        )}

        <div className="flex flex-col gap-y-2">
          {serviceTypes.map((serviceType) => (
            <CardItems
              key={serviceType.serviceTypeId}
              serviceType={serviceType}
              establishmentId={establishmentId}
            />
          ))}
        </div>
      </div>

      <FabNewService establishmentId={establishmentId} />
    </>
  );
}
