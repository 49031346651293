import { signUp, SignUpOutput } from "aws-amplify/auth";
import { useMutation } from "@tanstack/react-query";
import { AUTH_TYPE } from "@/shared/enums/auth";

type UseSignUpPayload = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

type Params = {
  customType: AUTH_TYPE;
};

export function useSignUp(
  { customType }: Params = { customType: AUTH_TYPE.COMPANY },
) {
  return useMutation<SignUpOutput, Error, UseSignUpPayload>({
    mutationFn: ({ email, password, passwordConfirmation }) => {
      if (password !== passwordConfirmation) {
        throw new Error("Passwords do not match");
      }

      return signUp({
        username: email,
        password,
        options: {
          userAttributes: { "custom:type": customType },
        },
      });
    },
  });
}
