import { apiAdmin } from "@/shared/services/api";
import { LinkInvite } from "@/shared/types/invite-link";
import { useSuspenseQuery } from "@tanstack/react-query";

type Params = {
  establishmentId: string;
  linkInvite?: LinkInvite;
};

export type ListEstablishmentCustomerOutput = {
  customers: [
    {
      pk: string;
      sk: string;
      companyId: string;
      establishmentId: string;
      customerId: string;
      name: string;
      timesServed: number;
      createdAt: string;
      updatedAt: string;
    }
  ];
};

export const useListEstablishmentCustomers = ({
  establishmentId,
  linkInvite,
}: Params) => {
  return useSuspenseQuery({
    queryKey: ["list-establishment-customers", establishmentId],
    queryFn: async () => {
      const http = await apiAdmin();
      return http.get<ListEstablishmentCustomerOutput>(
        `/establishments/${establishmentId}/customers`,
        {
          params: {
            linkedEmail: linkInvite?.email,
            originEstablishmentId: linkInvite?.originEstablishmentId,
          },
        }
      );
    },
  });
};
