import { Header } from "../components/header";
import { ListCustomers } from "../components/list-customers";

export function EstablishmentCustomers() {
  return (
    <>
      <Header />
      <ListCustomers />
    </>
  );
}
