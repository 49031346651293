import { Header } from "../components/header";
import { AccountInfo } from "../components/account-info";
import { SubscribePlan } from "../components/subscribe-plan";
import { MenuItems } from "../components/menu-items";

export function Menu() {
  return (
    <>
      <Header />
      <div className="mt-4 flex flex-col gap-y-4">
        <AccountInfo />
        <SubscribePlan />
        <MenuItems />
      </div>
    </>
  );
}
