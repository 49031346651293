import { ObjectSchema, object, string, date } from "yup";

export type Inputs = {
  clientName: string;
  serviceTypes: string;
  barberId?: string | null;
};

export type AppointmentInput = Inputs & {
  appointmentAt: Date;
};

type Params = {
  hasServiceTypes: boolean;
};

const schema: ObjectSchema<{ clientName: Inputs["clientName"] }> = object({
  clientName: string().required("Campo obrigatório"),
});

const serviceTypesSchema: ObjectSchema<{
  serviceTypes: Inputs["serviceTypes"];
}> = object({
  serviceTypes: string().required("Campo obrigatório"),
});

const barberSchema: ObjectSchema<{ barberId: Inputs["barberId"] }> = object({
  barberId: string().nullable(),
});

const appointmentSchema: ObjectSchema<AppointmentInput> = schema
  .concat(serviceTypesSchema)
  .concat(barberSchema)
  .concat(
    object({
      appointmentAt: date().required("Campo obrigatório"),
    })
  );

export const useFormSchema = ({ hasServiceTypes }: Params) => {
  return hasServiceTypes
    ? schema.concat(serviceTypesSchema).concat(barberSchema)
    : schema;
};

export const useAppointmentFormSchema = () => {
  return appointmentSchema;
};
