import { ConnectionState } from "@/shared/components/connection-state";
import { BackdropLoading } from "@/shared/components/loadings/backdrop-loading";
import { PersonAdd } from "@mui/icons-material";
import {
  CircularProgress,
  Alert,
  Button,
  Typography,
  Fab,
} from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import { CardItem } from "./card-item";
import { AlertPausedQueue } from "./paused-queue-alert";
import { useCurrentUser } from "@/shared/hooks/auth/use-user-attributes";
import { useWatchEstablishmentQueue } from "@/shared/hooks/queue/use-watch-establishment-queue";
import { isBefore, parseISO } from "date-fns";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEstablishmentContext } from "@/contexts/establishment/hook";
import AttendingClientDrawer from "@/shared/drawers/attending-client-drawer";

export const QueueView = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [URLHash, setURLHash] = useState<string>();
  const { linkInvite, establishment } = useEstablishmentContext();

  const { data: user } = useCurrentUser();

  const { establishmentId, status: establishmentStatus } = establishment;

  const companyId = user.userId;

  const {
    establishmentQueue = [],
    connectionState,
    status,
    refetch,
  } = useWatchEstablishmentQueue({
    companyId: linkInvite?.originCompanyId ?? companyId,
    establishmentId,
  });

  const queues = establishmentQueue?.filter(
    ({ establishmentId }) => !!establishmentId
  );
  const servingQueue =
    queues?.filter((queue) => queue.status === "SERVING") ?? [];
  const waitingQueue =
    queues
      ?.filter((queue) => queue.status === "WAITING")
      .sort((a, b) =>
        isBefore(parseISO(a.createdAt), parseISO(b.createdAt)) ? -1 : 1
      ) ?? [];

  const openAttendingDrawer = () => {
    navigate(`${pathname}#attending-drawer`);
  };

  const closeAttendingDrawer = () => {
    navigate(pathname, { replace: true });
  };

  useEffect(() => {
    setURLHash(window.location.hash);
  }, [params]);

  return (
    <>
      <div className="flex flex-col gap-y-4 mb-20">
        <div className="mx-4">
          <Typography variant="h6">Clientes na fila</Typography>
        </div>

        <AlertPausedQueue />

        <div className="mx-4">
          <ConnectionState state={connectionState ?? "DISCONNECTED"} />
        </div>

        {status === "pending" ? <CircularProgress className="mx-auto" /> : null}

        {status === "error" ? (
          <Alert
            severity="error"
            action={
              <Button key={1} onClick={() => refetch()}>
                Recarregar fila
              </Button>
            }
          >
            Erro ao carregar a fila
          </Alert>
        ) : null}

        {status === "success" && !queues?.length ? (
          <Alert severity="info">Nenhum cliente na fila por enquanto.</Alert>
        ) : null}

        {servingQueue.length ? (
          <div className="grid grid-cols-1 gap-y-2 mx-4">
            <Typography color="GrayText" fontWeight="bold">
              {servingQueue.length} cliente(s) em atendimento
            </Typography>
            {servingQueue.map((item) => (
              <CardItem key={item.queueId} establishmentQueue={item} />
            ))}
          </div>
        ) : null}

        {waitingQueue.length ? (
          <div className="grid grid-cols-1 gap-y-2 mx-4">
            <Typography color="GrayText" fontWeight="bold">
              {waitingQueue.length} cliente(s) aguardando
            </Typography>
            {waitingQueue.map((item) => (
              <CardItem key={item.queueId} establishmentQueue={item} />
            ))}
          </div>
        ) : null}
      </div>

      <Fab
        color="primary"
        aria-label="add"
        className="bottom-20 right-4"
        style={{ position: "fixed" }}
        variant="extended"
        disabled={establishmentStatus === "CLOSED"}
        onClick={openAttendingDrawer}
      >
        <PersonAdd className="mr-1" />
        Adicionar
      </Fab>

      <Suspense fallback={<BackdropLoading />}>
        <AttendingClientDrawer
          attendingType="QUEUE"
          addedBy="company"
          establishment={establishment}
          open={URLHash?.includes("#attending-drawer") ?? false}
          onClose={closeAttendingDrawer}
        />
      </Suspense>
    </>
  );
};
