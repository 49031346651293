import { apiAdmin } from "@/shared/services/api";
import { LinkInvite } from "@/shared/types/invite-link";
import { useMutation } from "@tanstack/react-query";

type MutationParams = {
  establishmentId: string;
  queueId: string;
};

type MutationProps = {
  linkInvite?: LinkInvite;
};

export const useStartServingClientInQueue = ({
  establishmentId,
  queueId,
}: MutationParams) => {
  return useMutation<unknown, unknown, MutationProps>({
    mutationFn: async ({ linkInvite = null }) => {
      const http = await apiAdmin();
      return http.patch(
        `/establishments/${establishmentId}/queue/${queueId}/start-serving`,
        { linkInvite }
      );
    },
  });
};
