import { FetchUserAttributesOutput, fetchAuthSession } from "aws-amplify/auth";
import { PropsWithChildren, useEffect } from "react";
import Hotjar from "@hotjar/browser";
import { UserCompanyContext } from "./context";

export const UserCompanyProvider = ({
  children,
  userAttributes,
}: PropsWithChildren<{
  userAttributes: FetchUserAttributesOutput | null;
}>) => {
  const attributes = userAttributes;
  const hasSubscription =
    attributes?.["custom:stripeSubscription"] === "ACTIVE";

  useEffect(() => {
    if (userAttributes?.sub) {
      Hotjar.identify(userAttributes.sub, {
        email: userAttributes.email || "",
        address: userAttributes.address || "",
      });
    }
  }, [userAttributes]);

  useEffect(() => {
    if (userAttributes) return;

    fetchAuthSession({ forceRefresh: true }).finally(() => {
      window.location.reload();
    });
  }, [userAttributes]);

  return (
    <UserCompanyContext.Provider
      value={{
        companyAttributes: attributes,
        hasSubscription,
      }}
    >
      {children}
    </UserCompanyContext.Provider>
  );
};
