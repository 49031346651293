import { apiAdmin } from "@/shared/services/api";
import { useMutation } from "@tanstack/react-query";

type MutationParams = {
  establishmentId: string;
  serviceTypeId: string;
};

export const useDeleteServiceType = () => {
  return useMutation<unknown, unknown, MutationParams>({
    mutationFn: async ({ establishmentId, serviceTypeId }) => {
      const admin = await apiAdmin();
      return admin.delete(
        `/establishments/${establishmentId}/service-types/${serviceTypeId}`
      );
    },
  });
};
