import { useMutation } from "@tanstack/react-query";
import { signIn, SignInOutput } from "aws-amplify/auth";

type UseLoginPayload = {
  email: string;
  password: string;
};

export function useSignIn() {
  return useMutation<SignInOutput, Error, UseLoginPayload>({
    mutationFn: ({ email, password }) =>
      signIn({
        username: email,
        password,
      }),
  });
}
