import { Delete } from "@mui/icons-material";
import { Card, CardContent, Chip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  getChipColorByLinkInviteStatus,
  translateLinkInviteStatus,
} from "@/shared/helpers/link-invites";
import { useCancelLinkedInvite } from "@/shared/hooks/companies/linked-invites/use-cancel-linked-invite";
import { captureException } from "@sentry/react";
import { format } from "date-fns";
import { ListLinkedInvitesOutput } from "@/shared/services/barx-api/companies/linked-invites/list";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { useInvalidateListLinkedInvites } from "@/shared/hooks/companies/linked-invites/use-list-linked-invites";

type Props = {
  linkedInvite: ListLinkedInvitesOutput[number];
};

export const ListLinkedInvite = ({
  linkedInvite: { linkedEmail, status, establishmentId, createdAt },
}: Props) => {
  const { mutate, isPending } = useCancelLinkedInvite();
  const invalidateListLinkedInvites = useInvalidateListLinkedInvites();
  const { showSnackbar } = useGlobalSnackbar();
  const handleRejectInvite = () => {
    mutate(
      { linkedEmail, originEstablishmentId: establishmentId },
      {
        onSuccess() {
          showSnackbar({
            message: "Convite cancelado com sucesso!",
            severity: "success",
          });
          invalidateListLinkedInvites({ establishmentId });
        },
        onError(error) {
          captureException(error);
          showSnackbar({
            message: "Erro ao cancelar convite. Tente novamente.",
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <Card>
      <CardContent className="grid grid-cols-1 sm:grid-cols-2 justify-between gap-4">
        <div>
          <Typography>
            {linkedEmail}
            <Chip
              label={translateLinkInviteStatus(status)}
              size="small"
              color={getChipColorByLinkInviteStatus(status)}
              className="ml-2"
            />
          </Typography>
          <Typography color="GrayText" variant="body2">
            Enviado em: {format(createdAt, "dd/MM/yyyy")}
          </Typography>
        </div>
        <div className="flex items-center justify-end gap-2">
          {status !== "REJECTED" ? (
            <LoadingButton
              onClick={handleRejectInvite}
              loading={isPending}
              startIcon={<Delete />}
              variant="outlined"
              color="error"
            >
              Cancelar
            </LoadingButton>
          ) : null}
        </div>
      </CardContent>
    </Card>
  );
};
