import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { useInvalidateGetEstablishment } from "@/shared/hooks/establishment/use-get-establishment";
import { useResumeEstablishment } from "@/shared/hooks/establishment/use-resume-establishment";
import { Establishment } from "@/shared/types/establishments";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { FormEventHandler } from "react";

type Props = {
  open: boolean;
  onClose(): void;
  establishment: Establishment;
};

export const ResumeEstablishmentDialog = ({
  establishment,
  onClose,
  open,
}: Props) => {
  const { mutate, isPending } = useResumeEstablishment();
  const invalidateGetEstablishment = useInvalidateGetEstablishment();
  const { showSnackbar } = useGlobalSnackbar();

  const submit: FormEventHandler = (event) => {
    event.preventDefault();

    mutate(
      {
        establishmentId: establishment.establishmentId,
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: "Fila retomada com sucesso",
            severity: "success",
          });
          invalidateGetEstablishment({
            companyId: establishment.companyId,
            establishmentId: establishment.establishmentId,
          });
          onClose();
        },
        onError() {
          showSnackbar({
            message: "Erro ao retomar fila",
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose} component="form" onSubmit={submit}>
      <DialogTitle>Retomar fila</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Seus clientes poderão entrar na fila normalmente caso estiver dentro
          do horário de funcionamento.
        </DialogContentText>
      </DialogContent>
      <DialogActions className="flex flex-row-reverse justify-start gap-x-3">
        <LoadingButton type="submit" variant="contained" loading={isPending}>
          Retomar
        </LoadingButton>
        <Button onClick={onClose} disabled={isPending}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
