import { API_URL } from "@/shared/constants/env";
import { authenticatedAxios } from "../fetch";

export const pauseEstablishmentAction = async ({
  establishmentId,
  message,
}: {
  establishmentId: string;
  message?: string;
}) => {
  return (await authenticatedAxios()).patch(
    `${API_URL}/admin/establishments/${establishmentId}/close`,
    { message }
  );
};
