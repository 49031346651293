import { CompanyData } from "../hooks/auth/companies/use-local-company-data";
import { JwtPayload } from "@aws-amplify/core/internals/utils";

export type PaymentMethodOption = "pix" | "credit-card" | "debit-card" | "cash";

export const mapOfPaymentMethodToLabel: Record<PaymentMethodOption, string> = {
  cash: "Dinheiro",
  "credit-card": "Cartão de crédito",
  "debit-card": "Cartão de débito",
  pix: "Pix",
};

export const listOfPaymentMethods: {
  value: PaymentMethodOption;
  label: string;
}[] = [
  { value: "pix", label: "Pix" },
  { value: "credit-card", label: "Cartão de crédito" },
  { value: "debit-card", label: "Cartão de débito" },
  { value: "cash", label: "Dinheiro" },
];

export const mapCognitoAttributes = (payload: JwtPayload): CompanyData => {
  const companyData: CompanyData = {
    customType: payload
      ? (payload?.["custom:type"] as CompanyData["customType"])
      : "COMPANY",
    stripeSubscription:
      (payload?.[
        "custom:stripeSubscription"
      ] as CompanyData["stripeSubscription"]) ?? "INACTIVE",
    stripeCustomerId: (payload?.["custom:stripeCustomerId"] as string) ?? "",
    vapid: (payload?.["custom:vapid"] as string) ?? "",
    sub: payload?.sub ?? "",
    emailVerified: Boolean(payload?.email_verified),
    email: payload?.email?.toString() ?? "",
  };

  return companyData;
};
