import { AUTH_TYPE } from "@/shared/enums/auth";
import { mapCognitoAttributes } from "@/shared/helpers/company";
import {
  useLocalStorage,
  writeStorage,
  deleteFromStorage,
} from "@rehooks/local-storage";
import { fetchAuthSession } from "aws-amplify/auth";
import { useCallback, useEffect, useState } from "react";

const COMPANY_DATA_KEY = "company_data";

export type CompanyData = {
  customType: "COMPANY";
  stripeSubscription: "ACTIVE" | "INACTIVE";
  stripeCustomerId: string;
  sub: string;
  emailVerified: boolean;
  email: string;
  vapid: string;
};

export const useLocalCompanyData = () => {
  const [companyData] = useLocalStorage<CompanyData>(COMPANY_DATA_KEY);
  const [company, setCompany] = useState<CompanyData>();

  useEffect(() => {
    setCompany(companyData ?? undefined);
  }, [companyData]);

  const recordData = (data: CompanyData) => {
    writeStorage(COMPANY_DATA_KEY, data);
  };

  const updateAuthSession = useCallback(async () => {
    return fetchAuthSession({ forceRefresh: true }).then((session) => {
      const payload = session.tokens?.idToken?.payload;
      if (!payload?.sub) return;
      if (payload["custom:type"] !== AUTH_TYPE.COMPANY) {
        throw new Error("Invalid company type");
      }
      const companyData = mapCognitoAttributes(payload);
      recordData(companyData);
    });
  }, []);

  const eraseData = () => {
    deleteFromStorage(COMPANY_DATA_KEY);
  };

  return {
    companyData,
    company,
    recordData,
    eraseData,
    mapCognitoAttributes,
    updateAuthSession,
  };
};
