import { listLinkedInvites } from "@/shared/services/barx-api/companies/linked-invites/list";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

type Params = {
  establishmentId: string;
};

const mountKey = (establishmentId: string) => [
  "list-linked-invites",
  establishmentId,
];

export const useListLinkedInvites = ({ establishmentId }: Params) =>
  useSuspenseQuery({
    queryFn: async () => {
      return listLinkedInvites({ establishmentId });
    },
    queryKey: mountKey(establishmentId),
  });

export const useInvalidateListLinkedInvites = () => {
  const queryClient = useQueryClient();

  return ({ establishmentId }: { establishmentId: string }) => {
    queryClient.invalidateQueries({ queryKey: mountKey(establishmentId) });
  };
};
