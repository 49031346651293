import { TextLabel } from "@/shared/components/text-label";
import { GetResultsOutput } from "@/shared/hooks/establishment/use-get-results";
import { toCurrency } from "@/shared/utils";
import { Typography, Skeleton, Divider } from "@mui/material";
import { useIsFetching } from "@tanstack/react-query";

type Props = {
  services?: GetResultsOutput["services"];
};

export const RevenueInfo = ({ services }: Props) => {
  const isFetching = useIsFetching() > 0;
  const totalServicesAmount = services?.total ?? 0;
  const servicesDetail = Object.values(services?.details ?? {});

  return (
    <>
      {!isFetching ? (
        <TextLabel label="Ganho total" text={toCurrency(totalServicesAmount)} />
      ) : (
        <Skeleton variant="rounded" />
      )}

      {!servicesDetail.length && isFetching ? (
        <div className="flex flex-col gap-4">
          <Skeleton variant="rounded" width="100%" />
          <Skeleton variant="rounded" width="100%" />{" "}
        </div>
      ) : (
        <div>
          <Typography>Ganho detalhado</Typography>
          <Divider />

          <div className="grid max-sm:grid-cols-2 sm:grid-cols-3 gap-6 mt-3">
            {!servicesDetail.length && !isFetching ? (
              <Typography
                className="w-full text-center col-span-full"
                color="textSecondary"
              >
                Nenhum ganho registrado até o momento.
              </Typography>
            ) : (
              servicesDetail.map((service) => (
                <TextLabel
                  key={service.serviceId}
                  label={service.name}
                  text={toCurrency(service.amount)}
                />
              ))
            )}
          </div>
        </div>
      )}
    </>
  );
};
