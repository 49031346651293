import {
  EstablishmentConfigs,
  WorkingHourItem,
} from "@/shared/types/establishments";
import { authenticatedAxios } from "../fetch";
import { EstablishmentFields } from "@/shared/drawers/manage-establishment/components/types";
import { API_URL } from "@/shared/constants/env";

type BodyPayload = {
  name: string;
  location: EstablishmentFields["location"];
  workingHour?: WorkingHourItem;
  configs?: EstablishmentConfigs;
};

export const updateEstablishment = async (
  establishmentId: string,
  body: BodyPayload
) => {
  return (await authenticatedAxios()).put(
    `${API_URL}/admin/establishments/${establishmentId}`,
    body
  );
};
