import { Establishment } from "@/shared/types/establishments";
import { PropsWithChildren, useState } from "react";
import { AttendingContext } from "./context";

type Props = {
  establishment: Establishment;
};

export const AttendingProvider = ({
  children,
  establishment,
}: PropsWithChildren<Props>) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  return (
    <AttendingContext.Provider
      value={{ establishment, selectedDate, setSelectedDate }}
    >
      {children}
    </AttendingContext.Provider>
  );
};
