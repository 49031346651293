import { Establishment, WorkingHourItem } from "@/shared/types/establishments";
import { getWeekDayName } from "@/shared/utils/date";

export const workingHourMap = (establishment: Establishment) => {
  const workingHourValues: WorkingHourItem = {};

  Object.keys(establishment?.workingHour ?? {}).forEach((key) => {
    if (!establishment.workingHour) return null;
    const currentValue = establishment.workingHour[key];
    workingHourValues[key] = currentValue;
    workingHourValues[key].enabled =
      !!currentValue.startTime || !!currentValue.endTime;
  });

  return workingHourValues;
};

/**
 * @deprecated Use getWorkingHour instead
 */
export const todayWorkingHour = (establishment: Establishment) => {
  const todayWeekDayName = getWeekDayName();
  return establishment?.workingHour?.[todayWeekDayName];
};

export const getWorkingHour = (establishment: Establishment, date?: Date) => {
  const weekDayName = getWeekDayName(date);
  return establishment?.workingHour?.[weekDayName] ?? {};
};

export const getDayFromWeekName = (weekDayName: string) => {
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return weekDays.indexOf(weekDayName);
};

export const getEnabledWeekDays = (establishment: Establishment) => {
  const workingHour = establishment.workingHour;
  return Object.keys(workingHour)
    .filter((key) => workingHour[key].enabled)
    .map((key) => getDayFromWeekName(key));
};

export const getBookingWeekDays = (establishment: Establishment) => {
  const workingHour = establishment.workingHour;
  return Object.keys(workingHour)
    .filter(
      (key) =>
        workingHour[key].enabled && workingHour[key].attendingType === "BOOKING"
    )
    .map((key) => getDayFromWeekName(key));
};

export const translateAttendingType = (
  attendingType: WorkingHourItem[string]["attendingType"]
) => {
  if (!attendingType) return "";
  return attendingType === "QUEUE" ? "Ordem de chegada" : "Agendamento";
};
