import { getEstablishment } from "@/shared/services/barx-api/establishments/get";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useRevalidator } from "react-router-dom";

type UseGetEstablishmentParams = {
  companyId: string;
  establishmentId: string;
};

export function useGetEstablishment({
  companyId,
  establishmentId,
}: UseGetEstablishmentParams) {
  return useSuspenseQuery({
    queryKey: mountGetEstablishmentCacheKey({ establishmentId, companyId }),
    queryFn: () =>
      getEstablishment({
        companyId,
        establishmentId,
      }),
  });
}

function mountGetEstablishmentCacheKey({
  companyId,
  establishmentId,
}: UseGetEstablishmentParams) {
  return ["establishments", companyId, establishmentId];
}

export function useInvalidateGetEstablishment() {
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();

  return ({ companyId, establishmentId }: UseGetEstablishmentParams) => {
    const cacheKey = mountGetEstablishmentCacheKey({
      companyId,
      establishmentId,
    });

    revalidator.revalidate();
    return queryClient.invalidateQueries({ queryKey: cacheKey });
  };
}
