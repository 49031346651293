import { pauseEstablishmentAction } from "@/shared/services/barx-api/establishments/pause";
import { useMutation } from "@tanstack/react-query";

type MutationParams = {
  establishmentId: string;
  message?: string;
};

export const useCloseEstablishment = () => {
  return useMutation<unknown, unknown, MutationParams>({
    mutationFn: async ({ establishmentId, message }) =>
      pauseEstablishmentAction({ establishmentId, message }),
  });
};
