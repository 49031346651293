import { TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { InfoFields } from "./types";

export const EstablishmentInfoInputs = () => {
  const { formState, register } = useFormContext<InfoFields>();

  return (
    <div>
      <TextField
        {...register("establishmentName")}
        label="Nome do espaço"
        placeholder="ex: Pedro Barbearia"
        variant="filled"
        autoComplete="off"
        fullWidth
        error={!!formState.errors.establishmentName}
        helperText={formState.errors.establishmentName?.message}
      />
    </div>
  );
};
