import { Suspense } from "react";
import { FixedBottomNavigation } from "./fixed-bottom-navigation";
import { Paper } from "@mui/material";
import { FullscreenLoading } from "@/shared/components/loadings/fullscreen-loading";
import * as Sentry from "@sentry/react";
import { UserCompanyProvider } from "@/contexts/user/provider";
import { Await, Outlet, useLoaderData, useNavigate } from "react-router-dom";
import { FetchUserAttributesOutput } from "aws-amplify/auth";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { HandleAsyncData } from "@/shared/components/handle-async-data";

export function AdminLayout() {
  const navigate = useNavigate();
  const data = useLoaderData() as {
    userAttributes: FetchUserAttributesOutput;
  };

  Sentry.setUser({
    id: data.userAttributes.sub,
    email: data.userAttributes.email,
  });

  const handleError = (err: unknown) => {
    const error = err as Error;
    if (["UserUnAuthenticatedException"].includes(error.name)) {
      console.log("caiu aqui");
      navigate(ROUTES_PATH.AUTH, { replace: true });
      return;
    }
  };

  return (
    <Sentry.ErrorBoundary onError={handleError}>
      <Suspense fallback={<FullscreenLoading />}>
        <Await resolve={data.userAttributes}>
          {(userAttributes) => (
            <HandleAsyncData data={userAttributes}>
              <UserCompanyProvider userAttributes={userAttributes}>
                <Paper className="min-h-screen">
                  <div className="w-full h-full pb-16">
                    <Outlet />
                  </div>
                  <FixedBottomNavigation />
                </Paper>
              </UserCompanyProvider>
            </HandleAsyncData>
          )}
        </Await>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
}
