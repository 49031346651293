import { Typography, Button } from "@mui/material";
import { Action } from "../reducer";

type Props = {
  period: Action["type"];
  onFilter: (period: Action["type"]) => void;
};

export const Filters = ({ period, onFilter }: Props) => {
  return (
    <div className="flex flex-col gap-2">
      <Typography variant="h6">Filtrar por período:</Typography>
      <div className="overflow-x-auto whitespace-nowrap">
        <div className="flex gap-3 items-start min-w-max">
          <Button
            variant={period === "today" ? "contained" : "outlined"}
            onClick={() => onFilter("today")}
          >
            Hoje
          </Button>
          <Button
            variant={period === "last-7-days" ? "contained" : "outlined"}
            onClick={() => onFilter("last-7-days")}
          >
            Últimos 7 dias
          </Button>
          <Button
            variant={period === "last-30-days" ? "contained" : "outlined"}
            onClick={() => onFilter("last-30-days")}
          >
            Últimos 30 dias
          </Button>
        </div>
      </div>
    </div>
  );
};
