import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { useLocalCompanyData } from "@/shared/hooks/auth/companies/use-local-company-data";
import { useSignIn } from "@/shared/hooks/auth/use-sign-in";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { TextField, Button } from "@mui/material";
import { AuthError } from "aws-amplify/auth";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { object, ObjectSchema, string } from "yup";

type Inputs = {
  email: string;
  password: string;
};

const schema: ObjectSchema<Inputs> = object({
  email: string().email("E-mail inválido").required("Campo obrigatório"),
  password: string()
    .min(8, "Deve ter no mínimo 8 caracteres")
    .required("Campo obrigatório"),
});

export const SignInForm = () => {
  const navigate = useNavigate();

  const { mutate: signInMutate, status } = useSignIn();

  const { showSnackbar } = useGlobalSnackbar();
  const { register, handleSubmit, formState } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });
  const { updateAuthSession } = useLocalCompanyData();

  const submit: SubmitHandler<Inputs> = ({ email, password }) => {
    signInMutate(
      { email, password },
      {
        onSuccess({ isSignedIn }) {
          showSnackbar({
            message: "Sucesso ao autenticar. Entrando...",
            severity: "success",
          });

          if (isSignedIn) {
            updateAuthSession().then(() => {
              navigate(ROUTES_PATH.ESTABLISHMENTS);
            });
          }
        },
        onError(err) {
          const error = err as AuthError;

          if (error.name === "UserAlreadyAuthenticatedException") {
            return navigate(ROUTES_PATH.ESTABLISHMENTS);
          }

          showSnackbar({
            message: "Falha ao autenticar. Tente novamente.",
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-y-4">
      <TextField
        {...register("email")}
        label="E-mail"
        type="email"
        autoComplete="email"
        error={!!formState.errors.email}
        helperText={formState.errors.email?.message}
      />

      <TextField
        {...register("password")}
        label="Senha"
        type="password"
        autoComplete="current-password"
        error={!!formState.errors.password}
        helperText={formState.errors.password?.message}
      />

      <div className="flex w-full justify-end items-center gap-x-4">
        <Button
          variant="text"
          onClick={() => navigate(ROUTES_PATH.AUTH_FORGOT_PASSWORD)}
        >
          Esqueceu a senha
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          disabled={status === "success"}
          loading={status === "pending"}
        >
          Entrar
        </LoadingButton>
      </div>
    </form>
  );
};
