import { API_URL } from "@/shared/constants/env";
import { authenticatedAxios } from "../../fetch";

export const cancelLinkedInvite = async ({
  linkedEmail,
  originEstablishmentId,
}: {
  originEstablishmentId: string;
  linkedEmail: string;
}) => {
  return (await authenticatedAxios()).patch(
    `${API_URL}/admin/establishments/${originEstablishmentId}/linked-invites/${linkedEmail}`
  );
};
