import axios from "axios";

export type GetAddressOutput = {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
};

export const getAddress = (cep: string) =>
  axios.get<GetAddressOutput>(`https://viacep.com.br/ws/${cep}/json/`);
