import CreateServiceTypeDrawer from "@/shared/drawers/service-types/create-service-type";
import { Straighten } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { Suspense, useState } from "react";

type Props = {
  establishmentId: string;
};

export const FabNewService = ({ establishmentId }: Props) => {
  const [newServiceTypeDrawer, setNewServiceTypeDrawer] = useState(false);

  const openNewServiceTypeDrawer = () => setNewServiceTypeDrawer(true);
  const closeNewServiceTypeDrawer = () => setNewServiceTypeDrawer(false);

  return (
    <>
      <Fab
        color="primary"
        aria-label="add"
        className="bottom-20 right-4"
        style={{ position: "fixed" }}
        variant="extended"
        onClick={() => openNewServiceTypeDrawer()}
      >
        <Straighten className="mr-1" />
        Novo serviço
      </Fab>

      <Suspense>
        <CreateServiceTypeDrawer
          open={newServiceTypeDrawer}
          onClose={() => closeNewServiceTypeDrawer()}
          establishmentId={establishmentId}
        />
      </Suspense>
    </>
  );
};
