import { apiPublic } from "@/shared/services/api";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

type MutationProps = {
  companyId: string;
};

export const useAddCompanyIntoBarbershopGroup = () =>
  useMutation<AxiosResponse, AxiosError, MutationProps>({
    mutationFn: async ({ companyId }) => {
      const http = await apiPublic();
      return http.post(`/companies/${companyId}/barbershop-group`);
    },
  });
