import { useGlobalPageLoading } from "@/contexts/global-page-loading/hook";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { replaceWithObject } from "@/shared/utils/url";
import { ArrowBack } from "@mui/icons-material";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

export const Header = () => {
  const { establishmentId } = useParams<{ establishmentId: string }>();

  if (!establishmentId) {
    throw new Error("establishmentId is required");
  }

  const { pageTransition } = useGlobalPageLoading();
  const navigate = useNavigate();

  const handleBack = () => {
    pageTransition(() => {
      navigate(
        replaceWithObject(ROUTES_PATH.ESTABLISHMENT, { establishmentId })
      );
    });
  };

  return (
    <AppBar position="sticky" color="inherit">
      <Toolbar>
        <IconButton
          className="mr-2"
          edge="start"
          size="large"
          onClick={handleBack}
        >
          <ArrowBack />
        </IconButton>

        <Typography variant="h6">Agendamentos pendentes</Typography>
      </Toolbar>
    </AppBar>
  );
};
