import { useAddClientInEstablishmentQueue } from "@/shared/hooks/queue/use-add-client-in-establishment-queue";
import { LoadingButton } from "@mui/lab";
import { Inputs, useFormSchema } from "./use-form-schema";
import { useLocalQueueId } from "@/shared/hooks/queue/use-local-queue-id";
import { yupResolver } from "@hookform/resolvers/yup";
import { Suspense, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useListServiceTypes } from "@/shared/hooks/establishment/service-types/use-list-service-types";
import { useInvalidateGetEstablishmentQueue } from "@/shared/hooks/queue/use-get-establishment-queue";
import { AxiosError } from "axios";
import { Establishment, QueueAddedBy } from "@/shared/types/establishments";
import { DataClientForm } from "./data-client-form";
import { CircularProgress } from "@mui/material";
import { useListInvitedBarbers } from "@/shared/hooks/establishment/use-list-invited-barbers";
import { getSelectedBarber } from "./helper";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";

type OnlyClientNameInput = { clientName: Inputs["clientName"] };

type Props = {
  establishment: Establishment;
  onFinish(): void;
  addedBy: QueueAddedBy;
};

const NO_PERSON_NAMES_DETECTED = "NO_PERSON_NAMES_DETECTED";

export const AddClientQueue = ({ establishment, onFinish, addedBy }: Props) => {
  const [hasServiceTypes, setHasServiceTypes] = useState(false);

  const { companyId, establishmentId } = establishment;

  const schema = useFormSchema({ hasServiceTypes });
  const formMethods = useForm<Inputs | OnlyClientNameInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      serviceTypes: "",
      clientName: "",
    },
  });
  const { handleSubmit, setError } = formMethods;
  const { updateUserQueueId } = useLocalQueueId();
  const { mutate: mutateAddClientToQueue, status: queueAddClientsStatus } =
    useAddClientInEstablishmentQueue();
  const {
    data: {
      data: { serviceTypes },
    },
  } = useListServiceTypes({
    companyId,
    establishmentId,
  });
  const { invalidate: invalidateEstablishmentQueue } =
    useInvalidateGetEstablishmentQueue({
      companyId,
      establishmentId,
    });
  const {
    data: { data: listInvitedBarbers },
  } = useListInvitedBarbers({ companyId, establishmentId });
  const { showSnackbar } = useGlobalSnackbar();
  const isQueueAddClientsLoading = queueAddClientsStatus === "pending";

  async function submit({
    clientName,
    ...props
  }: Inputs | OnlyClientNameInput) {
    const customerId = "";
    const { serviceTypes } =
      "serviceTypes" in props ? props : { serviceTypes: "" };
    const { barberId } = "barberId" in props ? props : { barberId: null };
    const barber = getSelectedBarber(listInvitedBarbers, barberId ?? "");

    mutateAddClientToQueue(
      {
        clientName,
        serviceTypes,
        barber,
        companyId,
        establishmentId,
        customerId,
        addedBy,
      },
      {
        onSuccess({ data }) {
          showSnackbar({
            message: "Você entrou na fila.",
            severity: "success",
          });

          updateUserQueueId(data.queueId);
          invalidateEstablishmentQueue();

          onFinish();
        },
        onError(error) {
          showSnackbar({
            message: "Erro ao entrar na fila, tente novamente.",
            severity: "error",
          });

          if (error instanceof AxiosError) {
            if (error.response?.data.message === NO_PERSON_NAMES_DETECTED) {
              setError("clientName", {
                message: "Nenhum nome detectado, digite um nome real.",
              });
            }
          }
        },
      }
    );
  }

  useEffect(() => {
    setHasServiceTypes(!!serviceTypes.length);
  }, [serviceTypes]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(submit)}
        className="flex flex-col gap-y-4 w-full"
      >
        <Suspense fallback={<CircularProgress className="mx-auto" />}>
          <DataClientForm
            companyId={companyId}
            establishmentId={establishmentId}
          />

          <div className="self-end">
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isQueueAddClientsLoading}
            >
              Entrar na fila
            </LoadingButton>
          </div>
        </Suspense>
      </form>
    </FormProvider>
  );
};

export default AddClientQueue;
