import { useLocalStorage, writeStorage } from "@rehooks/local-storage";
import { GetEstablishmentQueueResponseData } from "./use-get-establishment-queue";

const USER_QUEUE_ID = "user_queue_id";

export function useLocalQueueId(queueId?: string) {
  const [userQueueId] = useLocalStorage(USER_QUEUE_ID);
  const isMe = queueId === userQueueId;

  const isUserInQueue = (queue: GetEstablishmentQueueResponseData) => {
    return queue.some((item) => item.queueId === userQueueId);
  };

  const getUserFromQueue = (queue: GetEstablishmentQueueResponseData) => {
    const userInQueue = queue.find((item) => item.queueId === userQueueId);
    if (!userInQueue) {
      throw new Error("User not found in queue");
    }
    return userInQueue;
  };

  function updateUserQueueId(queueId: string) {
    writeStorage(USER_QUEUE_ID, queueId);
  }

  return {
    isMe,
    userQueueId,
    updateUserQueueId,
    isUserInQueue,
    getUserFromQueue,
  };
}
