import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { listOfPaymentMethods } from "@/shared/helpers/company";

import { Alert, Card, CardContent, Typography } from "@mui/material";

export const ListPaymentMethods = () => {
  const { establishment } = useEstablishmentContext();
  const paymentMethods = establishment.paymentMethods;
  if (!paymentMethods.length) {
    return <Alert severity="info">Nenhuma forma de pagamento cadastrada</Alert>;
  }

  return (
    <div className="flex flex-col gap-y-2">
      {paymentMethods.map(({ method, complement }) => {
        const label =
          listOfPaymentMethods.find((item) => item.value === method)?.label ??
          "";

        return (
          <Card key={method}>
            <CardContent>
              <Typography>{label}</Typography>
              {complement ? (
                <Typography variant="body2" color="GrayText">
                  {complement}
                </Typography>
              ) : null}
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};
