import { rejectLinkInvite } from "@/shared/services/barx-api/companies/link-invites/reject";
import { useMutation } from "@tanstack/react-query";

type Payload = {
  linkedEmail: string;
  originEstablishmentId: string;
};

export const useRejectLinkInvite = () => {
  return useMutation<unknown, Error, Payload>({
    mutationFn: ({ linkedEmail, originEstablishmentId }) =>
      rejectLinkInvite({ linkedEmail, originEstablishmentId }),
  });
};
