import { useGlobalPageLoading } from "@/contexts/global-page-loading/hook";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { MenuOutlined, StoreOutlined } from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  BottomNavigationOwnProps,
  Paper,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NAVIGATION_ESTABLISHMENTS = 0;
const NAVIGATION_MENU = 1;

const routeMap = (pathname: string) => {
  if (pathname.includes(ROUTES_PATH.ESTABLISHMENTS)) return 0;
  else if (pathname.includes(ROUTES_PATH.MENU)) return 1;
};

export function FixedBottomNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pageTransition } = useGlobalPageLoading();

  const [nav, setNav] = useState(() => routeMap(location.pathname));

  const handleChange: BottomNavigationOwnProps["onChange"] = (_, newValue) => {
    setNav(newValue);

    pageTransition(() => {
      if (newValue === NAVIGATION_ESTABLISHMENTS) {
        navigate(ROUTES_PATH.ESTABLISHMENTS);
      } else if (newValue === NAVIGATION_MENU) {
        navigate(ROUTES_PATH.MENU);
      }
    });
  };

  useEffect(() => {
    setNav(routeMap(location.pathname));
  }, [location.pathname]);

  return (
    <Paper className="fixed left-0 bottom-0 w-full h-max" elevation={1}>
      <BottomNavigation value={nav} onChange={handleChange}>
        <BottomNavigationAction label="Barbearias" icon={<StoreOutlined />} />
        <BottomNavigationAction label="Menu" icon={<MenuOutlined />} />
      </BottomNavigation>
    </Paper>
  );
}
