import {
  EstablishmentConfigs,
  WorkingHourItem,
} from "@/shared/types/establishments";
import { useMutation } from "@tanstack/react-query";
import { updateEstablishment } from "@/shared/services/barx-api/establishments/update";
import { LocationFields } from "@/shared/drawers/manage-establishment/components/types";

type MutationParams = {
  name: string;
  location: LocationFields["location"];
  workingHour?: WorkingHourItem;
  configs?: EstablishmentConfigs;
};

type UseUpdateEstablishmentParams = {
  establishmentId: string;
};

export function useUpdateEstablishment({
  establishmentId,
}: UseUpdateEstablishmentParams) {
  return useMutation<unknown, unknown, MutationParams>({
    mutationFn: async ({ name, workingHour, location, configs }) =>
      updateEstablishment(establishmentId, {
        name,
        workingHour,
        location,
        configs,
      }),
  });
}
