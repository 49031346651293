import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { useSendLinkInvite } from "@/shared/hooks/companies/link-invites/use-send-link-invite";
import { useInvalidateListLinkedInvites } from "@/shared/hooks/companies/linked-invites/use-list-linked-invites";
import { yupResolver } from "@hookform/resolvers/yup";
import { CloseOutlined, Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Drawer, Fab, IconButton, TextField, Typography } from "@mui/material";
import { captureException } from "@sentry/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ObjectSchema, object, string } from "yup";

type Fields = {
  email: string;
};

const schema: ObjectSchema<Fields> = object({
  email: string()
    .email("Digite um e-mail válido")
    .required("E-mail é um campo obrigatório"),
});

export const SendInvite = () => {
  const { establishmentId } = useParams<{ establishmentId: string }>();

  if (!establishmentId) {
    throw new Error("establishmentId is required");
  }

  const { showSnackbar } = useGlobalSnackbar();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { mutate, isPending } = useSendLinkInvite();
  const { register, handleSubmit, formState, reset } = useForm<Fields>({
    resolver: yupResolver(schema),
  });
  const invalidateListLinkedInvites = useInvalidateListLinkedInvites();

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    reset();
    setOpenDrawer(false);
  };

  const submit = handleSubmit(({ email }) => {
    mutate(
      { email, establishmentId },
      {
        onSuccess() {
          showSnackbar({
            message: "Convite enviado com sucesso",
            severity: "success",
          });
          invalidateListLinkedInvites({ establishmentId });
          handleCloseDrawer();
        },
        onError(error) {
          captureException(error);
          showSnackbar({
            message: "Erro ao enviar convite",
            severity: "error",
          });
        },
      }
    );
  });

  return (
    <>
      <Drawer open={openDrawer} onClose={handleCloseDrawer} anchor="right">
        <div className="p-3 w-screen max-w-[300px] sm:max-w-md">
          <header className="flex justify-between items-center">
            <Typography variant="h5" fontWeight="bold">
              Enviar convite
            </Typography>
            <IconButton onClick={handleCloseDrawer} color="error">
              <CloseOutlined />
            </IconButton>
          </header>

          <Typography variant="body2" className="mt-2" color="GrayText">
            Envie um convite para o barbeiro para que ele possa ser vinculado e
            começar a atender na sua barbearia.
          </Typography>

          <form className="mt-4 flex flex-col gap-y-2" onSubmit={submit}>
            <TextField
              {...register("email")}
              label="E-mail"
              autoComplete="email"
              fullWidth
              error={!!formState.errors.email}
              helperText={formState.errors.email?.message}
            />
            <LoadingButton
              fullWidth
              color="primary"
              variant="contained"
              type="submit"
              loading={isPending}
            >
              Convidar
            </LoadingButton>
          </form>
        </div>
      </Drawer>

      <Fab
        color="primary"
        aria-label="add"
        className={"bottom-20 right-4"}
        style={{ position: "fixed" }}
        variant="extended"
        onClick={handleOpenDrawer}
      >
        <Send className="mr-2" />
        Convidar barbeiro
      </Fab>
    </>
  );
};
