import { useContext } from "react";
import { GlobalPageLoadingContext } from "./context";

export const useGlobalPageLoading = () => {
  const context = useContext(GlobalPageLoadingContext);
  if (!context) {
    throw new Error(
      "useGlobalPageLoading must be used within a GlobalPageLoadingProvider"
    );
  }
  return context;
};
