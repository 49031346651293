import { apiAdmin } from "@/shared/services/api";
import { GetEstablishmentOutput } from "@/shared/services/barx-api/establishments/get";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useRevalidator } from "react-router-dom";

export type GetEstablishmentsResponseData =
  GetEstablishmentOutput["establishment"][];

export const getEstablishments = async () => {
  const admin = await apiAdmin();
  return admin.get<GetEstablishmentsResponseData>("/establishments");
};

export function useGetEstablishments() {
  return useSuspenseQuery({
    queryKey: mountGetEstablishmentsCacheKey(),
    queryFn: getEstablishments,
  });
}

function mountGetEstablishmentsCacheKey() {
  return ["establishments"];
}

export function useInvalidateGetEstablishments() {
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();
  const cacheKey = mountGetEstablishmentsCacheKey();

  return () => {
    revalidator.revalidate();
    return queryClient.invalidateQueries({ queryKey: cacheKey });
  };
}
