import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { API_URL, WEBSOCKET_URL } from "../constants/env";

export const adminHttp = ({ accessToken }: { accessToken: string }) => {
  const http = axios.create({
    baseURL: `${API_URL}/admin`,
    headers: {
      Authorization: accessToken,
    },
  });

  http.interceptors.request.use(
    (config) => config,
    (error) => {
      Sentry.captureException(error);
      return Promise.reject(error);
    }
  );

  http.interceptors.response.use(
    (response) => response,
    (error) => {
      Sentry.captureException(error);
      return Promise.reject(error);
    }
  );

  return http;
};

export const apiAdmin = async ({
  serverAccessToken,
}: {
  serverAccessToken?: string;
} = {}) => {
  try {
    let clientAccessToken: string | undefined;

    if (!serverAccessToken) {
      const session = await fetchAuthSession();
      const tokens = session.tokens;
      clientAccessToken = tokens?.accessToken.toString();
      const userId = tokens?.idToken?.payload.sub ?? "not-logged";

      Sentry.setUser({
        id: userId,
      });

      if (!clientAccessToken) {
        throw new Error("No client access token found");
      }
    }

    const accessToken = serverAccessToken ?? clientAccessToken;

    if (!accessToken) {
      throw new Error("No access token found");
    }

    const http = adminHttp({ accessToken });
    return http;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const apiCustomer = async () => {
  try {
    const tokens = (await fetchAuthSession()).tokens;
    const accessToken = tokens?.accessToken;
    const userId = tokens?.idToken?.payload.sub ?? "not-logged";
    Sentry.setUser({
      id: userId,
    });

    if (!accessToken) {
      throw new Error("No access token found");
    }

    const http = axios.create({
      baseURL: `${API_URL}/customers`,
      headers: {
        Authorization: accessToken.toString(),
      },
    });

    http.interceptors.request.use(
      (config) => config,
      (error) => {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
    );

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
    );

    return http;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export const apiPublic = async () => {
  return axios.create({
    baseURL: `${API_URL}/public`,
  });
};

export const websocketClient = (establishmentId: string) => {
  return new WebSocket(`${WEBSOCKET_URL}?establishmentId=${establishmentId}`);
};
