import { useGlobalPageLoading } from "@/contexts/global-page-loading/hook";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { Establishment } from "@/shared/types/establishments";
import { LinkInvite } from "@/shared/types/invite-link";
import { replaceWithObject } from "@/shared/utils/url";
import { Alert, List } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ListItem } from "./list-item";

type ListEstablishmentsProps = {
  establishments:
    | Establishment[]
    | (Establishment & { linkInvite: LinkInvite })[];
};

export const ListEstablishments = ({
  establishments,
}: ListEstablishmentsProps) => {
  const navigate = useNavigate();
  const { pageTransition } = useGlobalPageLoading();

  const handleNavigateToEstablishment = (establishment: Establishment) => {
    pageTransition(() => {
      navigate(
        replaceWithObject(ROUTES_PATH.ESTABLISHMENT, {
          establishmentId: establishment.establishmentId,
        })
      );
    });
  };

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col gap-y-6">
        <div className="grid grid-cols-1 gap-y-3">
          {!establishments?.length ? (
            <Alert severity="info">Você não possui espaços ainda.</Alert>
          ) : null}

          <List disablePadding>
            {establishments?.map((establishment) => (
              <ListItem
                key={establishment.establishmentId}
                establishment={establishment}
                onClick={handleNavigateToEstablishment}
              />
            ))}
          </List>
        </div>
      </div>
    </div>
  );
};
