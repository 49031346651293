import { apiPublic } from "@/shared/services/api";
import { ServiceType } from "@/shared/types/establishments";
import { LinkInvite } from "@/shared/types/invite-link";
import { useQuery, useQueryClient } from "@tanstack/react-query";

type UseGetEstablishmentQueue = {
  companyId: string;
  establishmentId: string;
};

export type GetEstablishmentQueueResponseData = {
  pk: string;
  sk: string;
  companyId: string;
  establishmentId: string;
  queueId: string;
  clientName: string;
  customerId: string;
  createdAt: string;
  servingAt: string;
  serviceTypes: ServiceType[];
  position: number;
  status: "WAITING" | "SERVING";
  linkInvite?: LinkInvite;
  barber: {
    givenName: string;
    familyName: string;
    companyId: string;
  } | null;
}[];

export function useGetEstablishmentQueue({
  companyId,
  establishmentId,
}: UseGetEstablishmentQueue) {
  return useQuery({
    queryKey: mountUseGetEstablishmentQueue({ companyId, establishmentId }),
    queryFn: async () => {
      const client = await apiPublic();
      return client.get<GetEstablishmentQueueResponseData>(
        `/companies/${companyId}/establishments/${establishmentId}/queue`
      );
    },
  });
}

function mountUseGetEstablishmentQueue({
  companyId,
  establishmentId,
}: UseGetEstablishmentQueue) {
  return ["establishment-queue", companyId, establishmentId];
}

export function useInvalidateGetEstablishmentQueue({
  companyId,
  establishmentId,
}: UseGetEstablishmentQueue) {
  const queryClient = useQueryClient();
  const queryKey = mountUseGetEstablishmentQueue({
    companyId,
    establishmentId,
  });

  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
  };
}
