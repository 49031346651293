import { ChipProps } from "@mui/material";
import { LinkInvite } from "../types/invite-link";

export const translateLinkInviteStatus = (status: LinkInvite["status"]) => {
  switch (status) {
    case "PENDING":
      return "Pendente";
    case "ACCEPTED":
      return "Aceito";
    case "REJECTED":
      return "Cancelado";
    default:
      return "Desconhecido";
  }
};

export const getChipColorByLinkInviteStatus = (
  status: LinkInvite["status"]
): ChipProps["color"] => {
  switch (status) {
    case "PENDING":
      return "warning";
    case "ACCEPTED":
      return "success";
    case "REJECTED":
      return "default";
    default:
      return "default";
  }
};
