import {
  Establishment,
  EstablishmentSlug,
} from "@/shared/types/establishments";
import { LinkInvite } from "@/shared/types/invite-link";
import { createContext } from "react";

type EstablishmentContextData = {
  linkInvite?: LinkInvite;
  establishment: Establishment;
  establishmentSlug: EstablishmentSlug;
};

export const EstablishmentContext = createContext<EstablishmentContextData>({
  establishment: {} as Establishment,
  establishmentSlug: null as EstablishmentSlug,
});
