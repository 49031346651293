import { apiPublic } from "@/shared/services/api";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

export type ResponseData = {
  invitedBarbers: {
    linkedBarber: {
      givenName: string;
      familyName: string;
    };
    companyId: string;
    establishmentId: string;
    linkedCompanyId: string;
    linkedEmail: string;
    status: "ACCEPTED" | "PENDING" | "REJECTED";
    createdAt: string;
    updatedAt: string;
  }[];
  ownerBarber: {
    givenName: string;
    familyName: string;
    companyId: string;
  };
};

type Params = {
  companyId: string;
  establishmentId: string;
};

type CacheKeyValues = Params;

const mountCacheKey = ({ companyId, establishmentId }: CacheKeyValues) => [
  "invited-barbers",
  companyId,
  establishmentId,
];

export const useListInvitedBarbers = ({ companyId, establishmentId }: Params) =>
  useSuspenseQuery({
    queryKey: mountCacheKey({ companyId, establishmentId }),
    queryFn: async () =>
      (await apiPublic()).get<ResponseData>(
        `/companies/${companyId}/establishments/${establishmentId}/invited-barbers`
      ),
  });

export const useInvalidateListInvitedBarbers = ({
  companyId,
  establishmentId,
}: Params) => {
  const queryClient = useQueryClient();
  return {
    invalidateQuery: () =>
      queryClient.invalidateQueries({
        queryKey: mountCacheKey({ companyId, establishmentId }),
      }),
  };
};
