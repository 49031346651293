import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import {
  Person,
  Send,
  EventRepeat,
  YouTube,
  PowerSettingsNewOutlined,
} from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
} from "@mui/material";
import { useGenerateStripePortalSession } from "@/shared/hooks/companies/use-generate-stripe-portal-session";
import { useUserCompany } from "@/contexts/user/hook";
import { useLogout } from "@/shared/hooks/auth/use-logout";
import { useNavigate } from "react-router-dom";
import { EXTERNAL_LINKS, ROUTES_PATH } from "@/shared/constants/routes";

export const MenuItems = () => {
  const { hasSubscription } = useUserCompany();
  const { showSnackbar } = useGlobalSnackbar();
  const navigate = useNavigate();
  const { mutate: logoutMutate } = useLogout();
  const { mutate: generateStripePortalSession } =
    useGenerateStripePortalSession();

  const handleLogout = () => {
    logoutMutate(undefined, {
      onSuccess() {
        navigate(ROUTES_PATH.AUTH, { replace: true });
      },
    });
  };

  const handleMenuSubscriptions = () => {
    showSnackbar({
      message: "Gerando link para acessar o portal de assinaturas...",
      severity: "info",
    });
    generateStripePortalSession(undefined, {
      onSuccess(data) {
        window.open(data.data.url, "_blank");
      },
      onError() {
        showSnackbar({
          message: "Erro ao gerar link para acessar o portal de assinaturas",
          severity: "error",
        });
      },
    });
  };

  const handleGoToUpdates = () => {
    window.open(EXTERNAL_LINKS.YOUTUBE_UPDATES, "_blank");
  };

  return (
    <List>
      <ListItemButton href={ROUTES_PATH.MENU_PROFILE}>
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        <ListItemText primary="Meu perfil" />
      </ListItemButton>

      <ListItemButton href={ROUTES_PATH.MENU_MANAGE_INVITES}>
        <ListItemIcon>
          <Send />
        </ListItemIcon>
        <ListItemText primary="Gerenciar convites" />
      </ListItemButton>

      {hasSubscription ? (
        <ListItem disablePadding>
          <ListItemButton onClick={handleMenuSubscriptions}>
            <ListItemIcon>
              <EventRepeat />
            </ListItemIcon>
            <ListItemText primary="Minha assinatura" />
          </ListItemButton>
        </ListItem>
      ) : null}

      <ListItemButton onClick={handleGoToUpdates}>
        <ListItemIcon>
          <YouTube color="error" />
        </ListItemIcon>
        <ListItemText primary="Atualizações do sistema" />
      </ListItemButton>

      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <PowerSettingsNewOutlined color="error" />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </ListItemButton>
    </List>
  );
};
