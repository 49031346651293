import { useContext } from "react";
import { GlobalSnackbarContext } from "./context";

export const useGlobalSnackbar = () => {
  const context = useContext(GlobalSnackbarContext);
  if (!context) {
    throw new Error(
      "useGlobalSnackbar must be used within a GlobalSnackbarProvider"
    );
  }
  return context;
};
