import { useEstablishmentContext } from "@/contexts/establishment/hook";
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { State } from "../reducer";
import { GetResultsOutput } from "@/shared/hooks/establishment/use-get-results";
import { RevenueInfo } from "./revenue-info";

type Props = {
  state: State;
  services?: GetResultsOutput["services"];
};

const ISOToDateFormatted = (isoDate: string) =>
  format(parseISO(isoDate), "dd/MM/yyyy");

export const Services = ({ state, services }: Props) => {
  const { linkInvite } = useEstablishmentContext();

  if (linkInvite) return;

  return (
    <Card>
      <CardHeader title="Serviços" />
      <Divider />

      <CardContent className="flex flex-col gap-4">
        <Typography color="textSecondary">
          As informações abaixo são referente ao período de{" "}
          <b>{ISOToDateFormatted(state.startDate)}</b> à{" "}
          <b>{ISOToDateFormatted(state.endDate)}</b>
        </Typography>

        <RevenueInfo services={services} />
      </CardContent>
    </Card>
  );
};
