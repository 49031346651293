import { resumeEstablishmentAction } from "@/shared/services/barx-api/establishments/resume";
import { useMutation } from "@tanstack/react-query";

type MutationParams = {
  establishmentId: string;
};

export const useResumeEstablishment = () => {
  return useMutation<unknown, unknown, MutationParams>({
    mutationFn: async ({ establishmentId }) =>
      resumeEstablishmentAction({ establishmentId }),
  });
};
