export const COGNITO_USER_POOL_ID = import.meta.env
  .VITE_COGNITO_USER_POOL_ID as string;

export const COGNITO_USER_POOL_CLIENT_ID = import.meta.env
  .VITE_COGNITO_USER_POOL_CLIENT_ID as string;

export const DOMAIN = import.meta.env.VITE_DOMAIN as string;

export const STRIPE_SUBSCRIPTION_PAYMENT_LINK = import.meta.env
  .VITE_STRIPE_SUBSCRIPTION_PAYMENT_LINK as string;

export const API_URL = import.meta.env.VITE_API_URL as string;

export const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_URL as string;

export const CLIENT_APP_URL = import.meta.env.VITE_CLIENT_APP_URL as string;

export const FIREBASE_CONFIG = import.meta.env.VITE_FIREBASE_CONFIG as string;

export const FIREBASE_VAPID_KEY = import.meta.env
  .VITE_FIREBASE_VAPID_KEY as string;

export const SENTRY_DNS = import.meta.env.VITE_SENTRY_DNS as string;
