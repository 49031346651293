import { Policy, RemoveModerator, VerifiedUser } from "@mui/icons-material";
import { GetEstablishmentQueueResponseData } from "../hooks/queue/use-get-establishment-queue";
import { SvgIconOwnProps } from "@mui/material";
import { ConnectionState } from "@/shared/hooks/queue/use-realtime-establishment-queue";
import { EstablishmentQueueItem } from "../types/establishments";

export const sortEstablishmentQueue = (
  establishmentQueue: GetEstablishmentQueueResponseData
) => establishmentQueue.sort((a, b) => (a.position > b.position ? 1 : -1));

export const translateConnectionState = (connectionState: ConnectionState) =>
  ({
    CONNECTED: {
      message: "Conexão em tempo real estabelecida.",
      icon: VerifiedUser,
      color: "success" as SvgIconOwnProps["color"],
    },
    DISCONNECTED: {
      message: "Conexão em tempo real desconectada, recarregue a página.",
      icon: RemoveModerator,
      color: "error" as SvgIconOwnProps["color"],
    },
    LOADING: {
      message: "Estabelecendo conexão em tempo real",
      icon: Policy,
      color: "warning" as SvgIconOwnProps["color"],
    },
  }[connectionState]);

export function calculateTotalOfRevenue(
  establishmentQueue: EstablishmentQueueItem[]
) {
  return establishmentQueue.reduce((queueAcc, queue) => {
    const totalAmountServiceTypes = queue.serviceTypes.reduce(
      (serviceTypeAcc, serviceType) => {
        return serviceTypeAcc + serviceType.amount;
      },
      0
    );
    return queueAcc + totalAmountServiceTypes;
  }, 0);
}
