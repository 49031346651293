import { listLinkInvites } from "@/shared/services/barx-api/companies/link-invites/list";
import { fetchUserAttributes } from "aws-amplify/auth";

export const menuManageInvitesLoader = async () => {
  const { email } = await fetchUserAttributes();

  if (!email) throw new Error("Email not provided");

  const { data } = await listLinkInvites({ linkedEmail: email });

  return { linkInvites: data };
};
