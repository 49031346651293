import { Establishment } from "@/shared/types/establishments";
import { LinkInvite } from "@/shared/types/invite-link";
import { ChevronRight } from "@mui/icons-material";
import { ListItemButton, ListItemText, Typography, Chip } from "@mui/material";
import { format } from "date-fns";

type Props = {
  establishment: Establishment | (Establishment & { linkInvite: LinkInvite });
  onClick(establishment: Establishment): void;
};

export const ListItem = ({ establishment, onClick }: Props) => {
  const { establishmentId, name, createdAt } = establishment;

  return (
    <ListItemButton
      key={establishmentId}
      onClick={() => onClick(establishment)}
    >
      <ListItemText>
        <Typography fontWeight="bold" className="overflow-hidden text-ellipsis">
          {name}{" "}
          {"linkInvite" in establishment ? (
            <Chip
              size="small"
              variant="outlined"
              label="Vinculado"
              color="default"
            />
          ) : (
            ""
          )}
        </Typography>
        <Typography variant="body2" className="text-gray-500">
          Criado em: {format(createdAt, "dd/MM/yyyy")}
        </Typography>
      </ListItemText>

      <ChevronRight className="text-gray-500" />
    </ListItemButton>
  );
};
