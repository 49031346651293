import { LastWeekSummary } from "../components/last-week-summary";
import { ContentByPeriod } from "../components/content-by-period";

export function EstablishmentPerformance() {
  return (
    <div className="flex flex-col gap-y-8 p-4">
      <LastWeekSummary />
      <ContentByPeriod />
    </div>
  );
}
