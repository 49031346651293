import { FC } from "react";
import { useCreateEstablishment } from "@/shared/hooks/establishment/use-create-establishment";
import { Drawer, IconButton, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CloseOutlined } from "@mui/icons-material";
import { FormStepper } from "./components/form-stepper";
import { allSchemas } from "./components/schemas";
import { EstablishmentFields } from "./components/types";
import { PremiumChip } from "@/shared/components/premium-chip";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { useUserCompany } from "@/contexts/user/hook";
import { useInvalidateGetEstablishments } from "@/shared/hooks/establishment/use-get-establishments";

type CreateEstablishmentDrawerProps = {
  open: boolean;
  onClose(): void;
  hasEstablishment: boolean;
};

const CreateEstablishmentDrawer: FC<CreateEstablishmentDrawerProps> = ({
  onClose,
  open,
  hasEstablishment,
}) => {
  const { hasSubscription } = useUserCompany();
  const { mutate: createEstablishment } = useCreateEstablishment();
  const invalidateGetEstablishments = useInvalidateGetEstablishments();
  const form = useForm({
    resolver: yupResolver(allSchemas),
    defaultValues: {
      establishmentName: "",
      location: {},
      workingHour: {},
      configs: {
        appointmentDuration: "60",
      },
    },
  });
  const { handleSubmit, reset } = form;
  const { showSnackbar } = useGlobalSnackbar();

  function handleClose() {
    reset();
    onClose();
  }

  async function handleSubmitForm({
    establishmentName,
    workingHour,
    location,
    configs,
  }: EstablishmentFields) {
    createEstablishment(
      { name: establishmentName, workingHour, location, configs },
      {
        onSuccess() {
          showSnackbar({
            message: "Espaço criado",
            severity: "success",
          });
          invalidateGetEstablishments();
          handleClose();
        },
        onError() {
          showSnackbar({
            message: "Erro ao criar espaço",
            severity: "error",
          });
        },
      }
    );
  }

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <div className="p-4 flex flex-col gap-y-6 w-screen max-w-[300px] sm:max-w-md">
        <header className="flex justify-between items-center">
          <Typography variant="h5" fontWeight="bold">
            Criar novo espaço
          </Typography>
          <IconButton onClick={handleClose} color="error">
            <CloseOutlined />
          </IconButton>
        </header>

        {!hasEstablishment || hasSubscription ? (
          <FormProvider {...form}>
            <form
              onSubmit={handleSubmit(handleSubmitForm)}
              className="flex flex-col gap-y-4"
            >
              <FormStepper />
            </form>
          </FormProvider>
        ) : (
          <>
            <Typography variant="body1" textAlign="center">
              Assine o plano{" "}
              <IconButton href="/admin/menu">
                <PremiumChip />
              </IconButton>
              <br />
              para gerenciar mais de um espaço
            </Typography>
          </>
        )}
      </div>
    </Drawer>
  );
};

export default CreateEstablishmentDrawer;
