import { ChevronLeftRounded } from "@mui/icons-material";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { ForgotPasswordForm } from "../components/forgot-password-form";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { useNavigate } from "react-router-dom";

export function AuthForgotPassword() {
  const navigate = useNavigate();

  return (
    <div className="flex w-full justify-center items-center">
      <Card className="w-full max-w-[400px]">
        <CardContent>
          <div className="flex flex-col gap-y-4">
            <Button
              variant="text"
              onClick={() => navigate(ROUTES_PATH.AUTH)}
              className="w-max"
            >
              <div className="flex items-center">
                <ChevronLeftRounded />
                <Typography variant="body2">Voltar</Typography>
              </div>
            </Button>

            <Typography variant="h5" className="mb-2" fontWeight="bold">
              Esqueceu a senha
            </Typography>

            <ForgotPasswordForm />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
