import { PropsWithChildren } from "react";
import { ReactQueryProvider } from "../react-query/provider";
import { MuiThemeProvider } from "../mui/provider";
import { GlobalSnackbarProvider } from "../global-snackbar/provider";
import { DatePickerProvider } from "../date-picker/provider";
import { GlobalPageLoadingProvider } from "../global-page-loading/provider";

export const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <ReactQueryProvider>
      <MuiThemeProvider>
        <DatePickerProvider>
          <GlobalSnackbarProvider>
            <GlobalPageLoadingProvider>{children}</GlobalPageLoadingProvider>
          </GlobalSnackbarProvider>
        </DatePickerProvider>
      </MuiThemeProvider>
    </ReactQueryProvider>
  );
};
