import { useState } from "react";
import { Header } from "../components/header";
import { ManageAttending } from "../components/attending";
import { Alert, AlertTitle } from "@mui/material";
import { EnableNotification } from "../components/enable-notification";
import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { EstablishmentPerformance } from "@/modules/performance/pages";

export const Establishment = () => {
  const { establishment, linkInvite } = useEstablishmentContext();
  const [tab, setTab] = useState(0);

  const handleTab = (currentTab: number) => {
    setTab(currentTab);
  };

  return (
    <>
      {linkInvite ? (
        <Alert severity="info">Você é convidado neste espaço</Alert>
      ) : null}

      {establishment ? <Header onTabChange={handleTab} /> : null}
      {tab === 0 ? (
        <>
          {linkInvite && linkInvite?.status !== "ACCEPTED" ? (
            <Alert severity="warning">
              <AlertTitle>Convite cancelado</AlertTitle>O convite para este
              espaço está atualmente cancelado. Você ainda pode visualizar o seu
              desempenho realizado e os clientes que atendeu.
            </Alert>
          ) : (
            <div className="flex flex-col gap-y-6">
              <EnableNotification />
              <ManageAttending />
            </div>
          )}
        </>
      ) : null}
      {tab === 1 ? <EstablishmentPerformance /> : null}
    </>
  );
};
