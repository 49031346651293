import { PropsWithChildren } from "react";
import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import { brown, indigo } from "@mui/material/colors";
import { ptBR } from "@mui/x-date-pickers/locales";

type Params = {
  systemPreference: "light" | "dark";
};

const theme = ({ systemPreference }: Params) =>
  createTheme(
    {
      typography: {
        fontFamily: '"Montserrat Variable", sans-serif',
      },
      components: {
        MuiIconButton: {
          defaultProps: {
            color: "inherit",
          },
        },
        MuiAppBar: {
          defaultProps: {
            color: "inherit",
          },
        },
      },
      palette: {
        mode: systemPreference,
        primary: brown,
        secondary: indigo,
      },
    },
    ptBR
  );

export const MuiThemeProvider = ({ children }: PropsWithChildren) => {
  const isDarkTheme = useMediaQuery("(prefers-color-scheme: dark")
    ? "dark"
    : "light";

  const loadedTheme = theme({ systemPreference: isDarkTheme });

  return <ThemeProvider theme={loadedTheme}>{children}</ThemeProvider>;
};
