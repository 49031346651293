import {
  SnackbarOrigin,
  AlertColor,
  AlertPropsColorOverrides,
} from "@mui/material";
import { createContext, ReactNode } from "react";
import { OverridableStringUnion } from "@mui/types";

type ContextProps = {
  showSnackbar: (params: {
    anchorOrigin?: SnackbarOrigin;
    severity?: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>;
    message: ReactNode;
    timeout?: number; // Adicionando timeout como um parâmetro opcional
  }) => void;
};

export const GlobalSnackbarContext = createContext<ContextProps>({
  showSnackbar: () => {},
});
