import { ResetPasswordOutput, resetPassword } from "aws-amplify/auth";
import { useMutation } from "@tanstack/react-query";

type UseForgotPasswordPayload = {
  email: string;
};

export function useResetPassword() {
  return useMutation<ResetPasswordOutput, Error, UseForgotPasswordPayload>({
    mutationFn: ({ email }) => resetPassword({ username: email }),
  });
}
