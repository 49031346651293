import { FC } from "react";
import { Drawer, IconButton, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CloseOutlined } from "@mui/icons-material";
import { useCreateServiceType } from "@/shared/hooks/establishment/service-types/use-create-service-type";
import { useInvalidateListServiceTypes } from "@/shared/hooks/establishment/service-types/use-list-service-types";
import { useCurrentUser } from "@/shared/hooks/auth/use-user-attributes";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { ServiceTypeForm } from "./service-type-form";
import { serviceTypeSchema } from "./schema";
import { ServiceTypeInputs } from "./types";

type Props = {
  open: boolean;
  onClose(): void;
  establishmentId: string;
};

export const CreateServiceTypeDrawer: FC<Props> = ({
  onClose,
  open,
  establishmentId,
}) => {
  const { data: user } = useCurrentUser();
  const companyId = user.userId;
  const methods = useForm<ServiceTypeInputs>({
    resolver: yupResolver(serviceTypeSchema),
  });
  const { handleSubmit, reset } = methods;
  const { mutate: createServiceType, isPending } = useCreateServiceType();
  const invalidateListServiceTypesQuery = useInvalidateListServiceTypes();
  const { showSnackbar } = useGlobalSnackbar();

  function handleClose() {
    reset({ amount: null, name: "", consumedItems: [] });
    onClose();
  }

  async function submit({ name, amount, consumedItems }: ServiceTypeInputs) {
    createServiceType(
      { establishmentId, name, amount: amount!, consumedItems },
      {
        onSuccess() {
          showSnackbar({
            message: "Serviço criado com sucesso.",
            severity: "success",
          });
          invalidateListServiceTypesQuery({
            companyId,
            establishmentId,
          });
          handleClose();
        },
        onError() {
          showSnackbar({
            message: "Erro ao criar serviço, tente novamente.",
            severity: "error",
          });
        },
      }
    );
  }

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <div className="p-4 flex flex-col gap-y-6 w-screen max-w-[300px] sm:max-w-md">
        <header className="flex justify-between items-center">
          <Typography variant="h5" fontWeight="bold">
            Criar novo serviço
          </Typography>
          <IconButton onClick={handleClose} color="error">
            <CloseOutlined />
          </IconButton>
        </header>

        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(submit)}
            className="flex flex-col gap-y-4"
          >
            <ServiceTypeForm />

            <LoadingButton
              variant="contained"
              type="submit"
              loading={isPending}
            >
              Criar serviço
            </LoadingButton>
          </form>
        </FormProvider>
      </div>
    </Drawer>
  );
};

export default CreateServiceTypeDrawer;
