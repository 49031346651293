import { useGlobalPageLoading } from "@/contexts/global-page-loading/hook";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { useSignUp } from "@/shared/hooks/auth/use-sign-up";
import { useAddCompanyIntoBarbershopGroup } from "@/shared/hooks/companies/use-add-company-into-barbershop-group";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { AuthError } from "aws-amplify/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { object, ObjectSchema, ref, string } from "yup";

type Inputs = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

const schema: ObjectSchema<Inputs> = object({
  email: string().email("E-mail inválido").required("Campo obrigatório"),
  password: string()
    .min(8, "Deve ter no mínimo 8 caracteres")
    .required("Campo obrigatório"),
  passwordConfirmation: string()
    .min(8, "Deve ter no mínimo 8 caracteres")
    .oneOf([ref("password")], "Senha diferente.")
    .required("Campo obrigatório"),
});

export const RegisterForm = () => {
  const navigate = useNavigate();
  const { mutate: signUp, status } = useSignUp();
  const { register, formState, handleSubmit } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });
  const { showSnackbar } = useGlobalSnackbar();
  const { pageTransition } = useGlobalPageLoading();
  const {
    mutate: addCompanyIntoBarbershopGroup,
    status: barbershopGroupStatus,
  } = useAddCompanyIntoBarbershopGroup();

  const submit = (formData: Inputs) => {
    const { email, password, passwordConfirmation } = formData;
    signUp(
      { email, password, passwordConfirmation },
      {
        onSuccess({ isSignUpComplete, nextStep, userId }) {
          if (!userId) throw new Error("User ID not provided.");
          showSnackbar({
            message: "Conta criada! Redirecionando...",
            severity: "success",
          });

          if (isSignUpComplete) {
            pageTransition(() => {
              navigate(ROUTES_PATH.AUTH, { replace: true });
            });
          } else {
            if (nextStep.signUpStep === "CONFIRM_SIGN_UP") {
              addCompanyIntoBarbershopGroup(
                { companyId: userId },
                {
                  onSuccess() {
                    const searchParams = new URLSearchParams([
                      [`email`, email],
                    ]);
                    pageTransition(() => {
                      navigate(
                        `${ROUTES_PATH.AUTH_REGISTER_CONFIRM}?${searchParams}`
                      );
                    });
                  },
                  onError() {
                    showSnackbar({
                      message:
                        "Falha adicionar conta no grupo de barbearias, tente novamente.",
                      severity: "error",
                    });
                  },
                }
              );
              return;
            }

            throw new Error(
              "Next step does not implemented! Contact the suporte."
            );
          }
        },
        onError(err) {
          const error = err as AuthError;

          if (error.name === "UsernameExistsException") {
            showSnackbar({
              message: "Uma conta com este e-mail já existe.",
              severity: "warning",
            });
            return;
          }

          showSnackbar({
            message: "Falha ao criar sua conta, tente novamente.",
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-y-4">
      <TextField
        {...register("email")}
        label="E-mail"
        type="email"
        placeholder="Digite seu e-mail"
        autoComplete="Username"
        error={!!formState.errors.email}
        helperText={formState.errors.email?.message}
      />

      <TextField
        {...register("password")}
        label="Senha"
        autoComplete="new-password"
        type="password"
        placeholder="Digite sua senha"
        error={!!formState.errors.password}
        helperText={formState.errors.password?.message}
      />

      <TextField
        {...register("passwordConfirmation")}
        label="Confirmar senha"
        type="password"
        placeholder="Digite novamente sua senha"
        autoComplete="new-password"
        error={!!formState.errors.passwordConfirmation}
        helperText={formState.errors.passwordConfirmation?.message}
      />

      <div className="flex w-full justify-end">
        <LoadingButton
          variant="contained"
          type="submit"
          loading={status === "pending" || barbershopGroupStatus === "pending"}
          disabled={status === "success" || barbershopGroupStatus === "success"}
        >
          Criar
        </LoadingButton>
      </div>
    </form>
  );
};
