import { useGlobalPageLoading } from "@/contexts/global-page-loading/hook";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { useResendSignUpCode } from "@/shared/hooks/auth/use-resend-sign-up-code";
import { useSignUpConfirmation } from "@/shared/hooks/auth/use-sign-up-confirmation";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { AuthError } from "aws-amplify/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { object, ObjectSchema, string } from "yup";

type Props = {
  email: string;
};

type Inputs = {
  email: string;
  confirmationCode: string;
};

const schema: ObjectSchema<Inputs> = object({
  email: string().email("E-mail inválido").required("Campo obrigatório"),
  confirmationCode: string()
    .min(6, "Mínimo de 6 caracteres")
    .required("Campo obrigatório"),
});

export const RegisterConfirmForm = ({ email }: Props) => {
  const navigate = useNavigate();
  const { handleSubmit, formState, register } = useForm<Inputs>({
    defaultValues: {
      email,
    },
    resolver: yupResolver(schema),
  });
  const { mutate: signUpConfirmation, status } = useSignUpConfirmation();
  const { mutate: resendSignUpCode, status: resendSignUpCodeStatus } =
    useResendSignUpCode();
  const { showSnackbar } = useGlobalSnackbar();
  const { pageTransition } = useGlobalPageLoading();

  const handleResendSignUpCode = () => {
    if (!email) {
      throw new Error("Email was not provided.");
    }

    resendSignUpCode(
      { email },
      {
        onSuccess() {
          showSnackbar({
            message: "Código enviado. Verifique seu e-mail.",
            severity: "success",
          });
        },
        onError(err) {
          const error = err as AuthError;

          if (error.name === "InvalidParameterException") {
            showSnackbar({
              message: "Usuário já confirmado. Vá para a página de Entrar.",
              severity: "warning",
            });
            return;
          }

          showSnackbar({
            message: "Falha ao enviar código, tente novamente.",
            severity: "error",
          });
        },
      }
    );
  };

  const submit = ({ email, confirmationCode }: Inputs) => {
    signUpConfirmation(
      { email, confirmationCode },
      {
        onSuccess({ nextStep }) {
          showSnackbar({
            message:
              "Sua conta foi confirmada. Redirecionando para página de Entrar...",
            severity: "success",
          });

          if (nextStep.signUpStep === "DONE") {
            pageTransition(() => {
              navigate(ROUTES_PATH.AUTH, { replace: true });
            });
            return;
          }
        },
        onError(err) {
          const error = err as AuthError;

          if (error.name === "CodeMismatchException") {
            showSnackbar({
              message: "Código inválido informado.",
              severity: "error",
            });
            return;
          }

          if (error.name === "NotAuthorizedException") {
            showSnackbar({
              message: "Conta já confirmada. Vá para página de Entrar.",
              severity: "warning",
            });
            return;
          }

          showSnackbar({
            message: "Falha ao confirmar sua conta, tente novamente.",
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-y-4">
      <TextField
        {...register("email")}
        label="E-mail"
        defaultValue={email}
        type="email"
        placeholder="Digite seu e-mail"
        autoComplete="email"
        disabled
        error={!!formState.errors.email}
        helperText={formState.errors.email?.message}
      />

      <TextField
        {...register("confirmationCode")}
        label="Código de confirmação"
        autoComplete="confirmation-code"
        placeholder="ex: 721721"
        error={!!formState.errors.confirmationCode}
        helperText={formState.errors.confirmationCode?.message}
      />

      <div className="flex w-full justify-end gap-x-2">
        <LoadingButton
          type="button"
          onClick={handleResendSignUpCode}
          loading={resendSignUpCodeStatus === "pending"}
          disabled={status === "pending" || status === "success"}
        >
          Reenviar código
        </LoadingButton>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={status === "pending"}
          disabled={status === "success"}
        >
          Confirmar
        </LoadingButton>
      </div>
    </form>
  );
};
