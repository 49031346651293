import { apiPublic } from "@/shared/services/api";
import { ListServiceTypeOutput } from "@/shared/services/barx-api/establishments/service-types/list";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

type UseListServiceTypesParams = {
  companyId: string;
  establishmentId: string;
};

type CacheKeyValues = UseListServiceTypesParams;

type UseInvalidateListServiceTypesParams = UseListServiceTypesParams;

const mountCacheKey = ({ companyId, establishmentId }: CacheKeyValues) => [
  "service-types",
  companyId,
  establishmentId,
];

export const useListServiceTypes = ({
  companyId,
  establishmentId,
}: UseListServiceTypesParams) =>
  useSuspenseQuery({
    queryKey: mountCacheKey({ companyId, establishmentId }),
    queryFn: async () =>
      (await apiPublic()).get<ListServiceTypeOutput>(
        `/companies/${companyId}/establishments/${establishmentId}/service-types`
      ),
  });

export const useInvalidateListServiceTypes = () => {
  const queryClient = useQueryClient();

  return ({
    companyId,
    establishmentId,
  }: UseInvalidateListServiceTypesParams) => {
    return queryClient.invalidateQueries({
      queryKey: mountCacheKey({ companyId, establishmentId }),
    });
  };
};
