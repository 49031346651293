import { useGlobalPageLoading } from "@/contexts/global-page-loading/hook";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { useResetPassword } from "@/shared/hooks/auth/use-reset-password";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { object, ObjectSchema, string } from "yup";

type Inputs = {
  email: string;
};

const schema: ObjectSchema<Inputs> = object({
  email: string().email("E-mail inválido").required("Campo obrigatório"),
});

export const ForgotPasswordForm = () => {
  const { mutate: forgotPassword, status } = useResetPassword();
  const navigate = useNavigate();
  const { showSnackbar } = useGlobalSnackbar();
  const { pageTransition } = useGlobalPageLoading();
  const { formState, handleSubmit, register } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const submit = ({ email }: Inputs) => {
    forgotPassword(
      { email },
      {
        onSuccess() {
          showSnackbar({
            message: `E-mail de recuperação enviado, verifique o e-mail ${email}.`,
            severity: "success",
          });

          pageTransition(() => {
            const searchParams = new URLSearchParams([["email", email]]);
            navigate(
              `${ROUTES_PATH.AUTH_FORGOT_PASSWORD_CONFIRM}?${searchParams}`
            );
          });
        },
        onError() {
          showSnackbar({
            message: "Falha ao recuperar senha, tente novamente.",
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-y-4">
      <TextField
        {...register("email")}
        label="E-mail"
        autoComplete="email"
        error={!!formState.errors.email}
        helperText={formState.errors.email?.message}
      />

      <div className="flex w-full justify-end items-center gap-x-4">
        <LoadingButton
          type="submit"
          variant="contained"
          loading={status === "pending"}
        >
          Recuperar senha
        </LoadingButton>
      </div>
    </form>
  );
};
