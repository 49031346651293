import { API_URL } from "@/shared/constants/env";
import { authenticatedAxios } from "../fetch";

export const deleteEstablishment = async ({
  establishmentId,
}: {
  establishmentId: string;
}) => {
  return (await authenticatedAxios()).delete(
    `${API_URL}/admin/establishments/${establishmentId}`
  );
};
