import { useGlobalPageLoading } from "@/contexts/global-page-loading/hook";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { useDeleteEstablishment } from "@/shared/hooks/establishment/use-delete-establishment";
import { useInvalidateGetEstablishments } from "@/shared/hooks/establishment/use-get-establishments";
import { Establishment } from "@/shared/types/establishments";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

type DeleteEstablishmentDialogProps = {
  open: boolean;
  onClose(deleted?: boolean): void;
  establishment?: Establishment;
};

const DeleteEstablishmentDialog = ({
  open,
  onClose,
  establishment,
}: DeleteEstablishmentDialogProps) => {
  const { showSnackbar } = useGlobalSnackbar();
  const { mutate, isPending } = useDeleteEstablishment();
  const invalidateGetEstablishments = useInvalidateGetEstablishments();

  const DialogActionsContent = () => {
    if (!establishment) throw new Error("establishment prop not passed.");
    const navigate = useNavigate();
    const { pageTransition } = useGlobalPageLoading();

    const handleDelete = () =>
      mutate(
        { establishmentId: establishment.establishmentId },
        {
          onSuccess() {
            showSnackbar({
              message: "Espaço deletado com sucesso.",
              severity: "success",
            });

            invalidateGetEstablishments();

            pageTransition(() => {
              navigate(ROUTES_PATH.ESTABLISHMENTS, { replace: true });
            });

            onClose(true);
          },
          onError() {
            showSnackbar({
              message: "Erro ao deletar espaço.",
              severity: "error",
            });
          },
        }
      );

    return (
      <DialogActions>
        <Button disabled={isPending} onClick={() => onClose()}>
          Cancelar
        </Button>
        <LoadingButton loading={isPending} color="error" onClick={handleDelete}>
          Deletar espaço
        </LoadingButton>
      </DialogActions>
    );
  };

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Deletar espaço</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tem certeza que deseja deletar este espaço?
        </DialogContentText>
      </DialogContent>

      {establishment ? <DialogActionsContent /> : null}
    </Dialog>
  );
};

export default DeleteEstablishmentDialog;
