import { Header } from "../components/header";

import { ListInvites } from "../components/list-invites";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { useUserCompany } from "@/contexts/user/hook";
import { useLoaderData } from "react-router-dom";
import { LinkInvitesOutput } from "@/shared/services/barx-api/companies/link-invites/list";
import { ROUTES_PATH } from "@/shared/constants/routes";

export function ManageInvites() {
  const { linkInvites } = useLoaderData() as { linkInvites: LinkInvitesOutput };
  const { companyAttributes } = useUserCompany();

  const { email, given_name } = companyAttributes ?? {};
  const canSeeInvites = !!given_name && !!email;

  return (
    <>
      <Header />

      <div>
        <div className="p-4">
          <Typography color="GrayText">
            Você pode aceitar ou recusar convites para participar de outras
            barbearias.
          </Typography>
          <Typography color="GrayText">
            Ao aceitar um convite, você poderá visualizar e atender os clientes
            da barbearia que te convidou.
          </Typography>
        </div>

        {!canSeeInvites ? (
          <Alert
            severity="warning"
            action={
              <Button href={ROUTES_PATH.MENU_PROFILE}>Atualizar perfil</Button>
            }
          >
            <AlertTitle>Complete o seu perfil</AlertTitle>
            <Typography variant="body2">
              Para aceitar convites, complete seu perfil.
            </Typography>

            {linkInvites.length ? (
              <Typography variant="body2" fontWeight="bold">
                Você tem {linkInvites.length} convite(s) para visualizar.
              </Typography>
            ) : null}
          </Alert>
        ) : null}

        {!linkInvites.length ? (
          <Alert severity="info">
            <Typography variant="body2">
              Você não tem convites pendentes.
            </Typography>
          </Alert>
        ) : null}

        {canSeeInvites ? (
          <div className="mx-4">
            {linkInvites.map((invite) => (
              <ListInvites
                key={invite.originEstablishmentId}
                invite={invite}
                email={email}
              />
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
}
