import { ResendSignUpCodeOutput, resendSignUpCode } from "aws-amplify/auth";
import { useMutation } from "@tanstack/react-query";

type UseResendSignUpPayload = {
  email: string;
};

export function useResendSignUpCode() {
  return useMutation<ResendSignUpCodeOutput, unknown, UseResendSignUpPayload>({
    mutationFn: ({ email }) => resendSignUpCode({ username: email }),
  });
}
