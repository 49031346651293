import { DragEvent, DragEventHandler, RefObject } from "react";

type Params = {
  imageElementRef: RefObject<HTMLDivElement>;
  onDragging(value?: string): void;
  draggingValue?: string;
  currentValue: string;
};

export const useDragAndDrop = ({
  imageElementRef,
  onDragging,
  draggingValue,
  currentValue,
}: Params) => {
  const onDragStart: DragEventHandler<HTMLDivElement> = (event) => {
    if (!imageElementRef.current)
      throw new Error("imageElementRef.current is null");

    imageElementRef.current.style.opacity = "0.4";

    event.dataTransfer.setDragImage(
      imageElementRef.current,
      26,
      imageElementRef.current.clientHeight / 2
    );
    onDragging(currentValue);
  };

  const onDragEnd: DragEventHandler<HTMLDivElement> = (event) => {
    if (!imageElementRef.current)
      throw new Error("imageElementRef.current is null");
    if (!(event.target instanceof HTMLElement)) return;

    imageElementRef.current.style.opacity = "1";

    // Maybe it will not be necessary to remove the border
    // Because we will re-render the component ordering the cards
    const gridElement =
      event.target.closest("div[data-queue-id]")?.parentElement?.parentElement;
    gridElement?.querySelectorAll("div[data-queue-id]").forEach((el) => {
      el.classList.remove("border-4", "border-dashed", "border-orange-400");
    });
    onDragging(undefined);
  };

  const onDragEnter: DragEventHandler<HTMLDivElement> = (event) => {
    if (draggingValue === currentValue) return;
    if (!(event.target instanceof HTMLElement)) return;

    const target = event.target.closest("div[data-queue-id]");
    if (!target) return;

    target?.classList.add("border-4", "border-dashed", "border-orange-400");
  };

  const onDragOver: DragEventHandler<HTMLDivElement> = (event) => {
    if (draggingValue === currentValue) return;
    if (!(event.target instanceof HTMLElement)) return;
    if (!event.target.getAttribute("data-queue-id")) return;

    event.preventDefault();
    return false;
  };

  const onDragLeave: DragEventHandler<HTMLDivElement> = (event) => {
    if (draggingValue === currentValue) return;
    if (!(event.target instanceof HTMLElement)) return;
    if (!event.target.getAttribute("data-queue-id")) return;

    event.target.classList.remove(
      "border-4",
      "border-dashed",
      "border-orange-400"
    );
  };

  const onDrop = (
    event: DragEvent<HTMLDivElement>
  ): { draggingValue?: string; targetQueueId?: string } | void => {
    event.stopPropagation();
    if (!(event.target instanceof HTMLElement)) return;

    const target = event.target.closest("div[data-queue-id]");
    if (!target) return;

    const targetQueueId = target.getAttribute("data-queue-id") || undefined;
    return {
      draggingValue,
      targetQueueId,
    };
  };

  return {
    onDragStart,
    onDragEnter,
    onDragEnd,
    onDragLeave,
    onDrop,
    onDragOver,
  };
};
