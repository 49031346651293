import { Typography } from "@mui/material";

export const EstablishmentClosed = () => {
  return (
    <div className="grid justify-center place-items-center mx-2 mt-10">
      <img
        className="max-h-[200px] sm:max-h-[300px]"
        src="/images/undraw_sleeping.svg"
        alt="Estabelecimento fechado"
      />
      <Typography variant="h6" className="mt-4">
        Estabelecimento fechado
      </Typography>
      <Typography textAlign="center" className="mt-2">
        Seu estabelecimento está fechado hoje, não haverá clientes na fila ou
        agendamentos.
      </Typography>
    </div>
  );
};
