import { confirmResetPassword } from "aws-amplify/auth";
import { useMutation } from "@tanstack/react-query";

type UseForgotPasswordPayload = {
  email: string;
  confirmationCode: string;
  newPassword: string;
};

export function useConfirmPassword() {
  return useMutation<unknown, Error, UseForgotPasswordPayload>({
    mutationFn: ({ email, confirmationCode, newPassword }) =>
      confirmResetPassword({ username: email, confirmationCode, newPassword }),
  });
}
