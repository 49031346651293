import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { useGlobalPageLoading } from "@/contexts/global-page-loading/hook";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { useListAppointments } from "@/shared/hooks/establishment/appointments/use-list-appointments";
import { Establishment } from "@/shared/types/establishments";
import { replaceWithObject } from "@/shared/utils/url";
import { CalendarMonth } from "@mui/icons-material";
import { Tooltip, IconButton, Badge } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  establishment: Establishment;
};

export const PendingAppointmentsButton = ({ establishment }: Props) => {
  const navigate = useNavigate();
  const { linkInvite } = useEstablishmentContext();
  const { pageTransition } = useGlobalPageLoading();

  const { data, hasNextPage, fetchNextPage } = useListAppointments({
    establishmentId: establishment.establishmentId,
    status: "PENDING",
    linkedEmail: linkInvite?.email,
  });

  const appointmentsLength = data.pages.reduce((acc, page) => {
    return acc + page.data.appointments.length;
  }, 0);

  const handleButtonClick = () => {
    pageTransition(() => {
      navigate(
        replaceWithObject(ROUTES_PATH.ESTABLISHMENT_PENDING_APPOINTMENTS, {
          establishmentId: establishment.establishmentId,
        })
      );
    });
  };

  useEffect(() => {
    if (!hasNextPage) return;
    fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return (
    <Tooltip title="Agendamentos pendentes">
      <IconButton onClick={handleButtonClick}>
        <Badge badgeContent={appointmentsLength} color="error">
          <CalendarMonth color="inherit" />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};
