import { Header } from "../components/header";
import { InfoForm } from "../components/info-form";

export function Profile() {
  return (
    <>
      <Header />
      <InfoForm />
    </>
  );
}
