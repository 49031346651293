import { ArrowBack } from "@mui/icons-material";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";

import { TopBarMenu } from "./top-bar-menu";
import { Suspense, useState } from "react";
import { useUserCompany } from "@/contexts/user/hook";
import { PremiumChip } from "@/shared/components/premium-chip";
import { PendingAppointmentsButton } from "./pending-appointments-button";
import { ErrorBoundary } from "@sentry/react";
import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { useGlobalPageLoading } from "@/contexts/global-page-loading/hook";

type Props = {
  onTabChange(currentTab: number): void;
};

export const Header = ({ onTabChange }: Props) => {
  const { hasSubscription } = useUserCompany();
  const { establishment } = useEstablishmentContext();
  const navigate = useNavigate();
  const { pageTransition } = useGlobalPageLoading();
  const [tab, setTab] = useState(0);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    onTabChange(newValue);
  };

  return (
    <AppBar position="sticky" color="inherit">
      <Toolbar>
        <IconButton
          onClick={() =>
            pageTransition(() => {
              navigate(ROUTES_PATH.ESTABLISHMENTS, { replace: true });
            })
          }
          className="mr-2"
          edge="start"
          size="large"
        >
          <ArrowBack />
        </IconButton>

        <Typography
          variant="h6"
          fontWeight="bold"
          component="div"
          className="text-amber-800 dark:text-white overflow-hidden text-ellipsis whitespace-nowrap"
          flexGrow={1}
        >
          {establishment?.name}
        </Typography>

        <ErrorBoundary>
          <Suspense fallback={<CircularProgress size={20} />}>
            <PendingAppointmentsButton establishment={establishment} />
          </Suspense>
        </ErrorBoundary>

        {establishment ? <TopBarMenu establishment={establishment} /> : null}
      </Toolbar>

      <Tabs value={tab} onChange={handleTabChange} variant="fullWidth">
        <Tab label="Atendimento" tabIndex={0} />
        <Tab
          label={
            <>Desempenho {!hasSubscription ? <PremiumChip disabled /> : null}</>
          }
          disabled={!hasSubscription}
          tabIndex={1}
        />
      </Tabs>
    </AppBar>
  );
};
