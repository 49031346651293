import {
  GetEstablishmentQueueResponseData,
  useInvalidateGetEstablishmentQueue,
} from "@/shared/hooks/queue/use-get-establishment-queue";
import { useRemoveClientFromEstablishmentQueue } from "@/shared/hooks/queue/use-remove-client-from-establishment-queue";
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, isToday } from "date-fns";
import { LoadingButton } from "@mui/lab";
import {
  ArrowDownward,
  ArrowUpward,
  CheckCircle,
  DragIndicatorRounded,
  PersonRemove,
  PlayArrow,
} from "@mui/icons-material";
import { DragEventHandler, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { useDragAndDrop } from "./use-drag-and-drop";
import { toCurrency } from "@/shared/utils";
import { captureException } from "@sentry/react";
import { useStartServingClientInQueue } from "@/shared/hooks/queue/use-start-serving-client-in-queue";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { useEstablishmentContext } from "@/contexts/establishment/hook";

type CardItemProps = {
  first?: boolean;
  last?: boolean;
  establishmentQueue: GetEstablishmentQueueResponseData[number];
  onDragging?(queueId?: string): void;
  draggingValue?: string;
  onDropCompleted?(properties: {
    draggingValue: string;
    targetQueueId: string;
  }): void;
  onChangePosition?(movement: "up" | "down", queueId: string): void;
};

export function CardItem({
  first,
  last,
  onDragging = () => {},
  draggingValue,
  onDropCompleted,
  onChangePosition,
  establishmentQueue: {
    clientName,
    createdAt,
    servingAt,
    queueId,
    establishmentId,
    companyId: queueCompanyId,
    serviceTypes = [],
    status: queueStatus,
    linkInvite: queueLinkInvite,
    barber,
  },
}: CardItemProps) {
  const { showSnackbar } = useGlobalSnackbar();
  const { mutate: removeClientMutate, status: clientQueueStatus } =
    useRemoveClientFromEstablishmentQueue();
  const { mutate: startServingClient, status: startServingClientStatus } =
    useStartServingClientInQueue({
      establishmentId,
      queueId,
    });
  const { invalidate: invalidateEstablishmentQueue } =
    useInvalidateGetEstablishmentQueue({
      companyId: queueCompanyId,
      establishmentId,
    });
  const { linkInvite, establishment } = useEstablishmentContext();
  const cardRef = useRef<HTMLDivElement>(null);
  const isTouch = "ontouchstart" in window;

  const {
    onDragStart,
    onDragEnd,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop,
  } = useDragAndDrop({
    draggingValue,
    imageElementRef: cardRef,
    onDragging,
    currentValue: queueId,
  });

  const isCreatedAtToday = isToday(createdAt);
  const joinedDateTime = isCreatedAtToday
    ? format(createdAt, "'entrou às' HH:mm")
    : format(createdAt, "dd/MM/yyyy 'às' HH:mm");

  const handleRemoveClientFromQueue =
    ({ wasServed }: { wasServed: boolean }) =>
    () => {
      removeClientMutate(
        {
          establishmentId,
          queueId,
          wasServed,
          linkInvite: queueLinkInvite || linkInvite,
        },
        {
          onSuccess() {
            showSnackbar({
              message: "Cliente removido da fila.",
              severity: "success",
            });
            invalidateEstablishmentQueue();
          },
          onError() {
            showSnackbar({
              message:
                "Houve um erro ao remover o cliente da fila, tente novamente.",
              severity: "error",
            });
          },
        }
      );
    };

  const handleStartServingClient = () => {
    startServingClient(
      { linkInvite },
      {
        onSuccess() {
          showSnackbar({
            message: "Atendimento iniciado.",
            severity: "success",
          });
          invalidateEstablishmentQueue();
        },
        onError(error) {
          captureException(error);
          showSnackbar({
            message: "Houve um erro ao iniciar o atendimento, tente novamente.",
            severity: "error",
          });
        },
      }
    );
  };

  let cardClassName =
    clientQueueStatus === "success" ? "opacity-40 pointer-events-none" : "";
  if (queueStatus === "SERVING") {
    cardClassName += " border-indigo-300 dark:border-indigo-400";
  }

  const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
    const data = onDrop(event);
    if (!data) return;

    const { draggingValue, targetQueueId } = data;
    if (!draggingValue || !targetQueueId) return;

    onDropCompleted?.({
      draggingValue,
      targetQueueId,
    });
  };

  return (
    <Card
      variant="outlined"
      ref={cardRef}
      key={queueId}
      className={twMerge(
        "group border-gray-200 dark:border-gray-700",
        cardClassName
      )}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      onDragLeave={onDragLeave}
      onDrop={handleDrop}
      onDragOver={onDragOver}
    >
      <CardContent
        className="flex gap-x-4 transition-all"
        data-queue-id={queueId}
      >
        {onChangePosition ? (
          <div className="flex flex-col justify-between">
            {isTouch ? (
              <div>
                <IconButton
                  disabled={first}
                  onClick={() => onChangePosition?.("up", queueId)}
                >
                  <ArrowUpward />
                </IconButton>
              </div>
            ) : (
              <span />
            )}
            {!isTouch ? (
              <div
                draggable
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                className="self-center"
                id={queueId}
              >
                <DragIndicatorRounded />
              </div>
            ) : null}
            {isTouch ? (
              <div>
                <IconButton
                  disabled={last}
                  onClick={() => onChangePosition?.("down", queueId)}
                >
                  <ArrowDownward />
                </IconButton>
              </div>
            ) : (
              <span />
            )}
          </div>
        ) : null}

        <div className="flex flex-1 flex-col gap-x-4 gap-y-4">
          {!isCreatedAtToday ? (
            <Alert severity="warning">
              <Typography variant="body2">
                Faz um ou mais dias que este cliente esta na fila.
              </Typography>
            </Alert>
          ) : null}

          <div className="flex justify-between">
            <div>
              <Typography variant="body1" fontWeight="bold">
                {clientName}
              </Typography>
              <Typography variant="body2" className="text-gray-500">
                {joinedDateTime}
              </Typography>
            </div>

            <Tooltip title="Ganho previsto">
              <Chip
                label={toCurrency(
                  serviceTypes.reduce((prev, curr) => prev + curr.amount, 0)
                )}
                color="default"
                size="small"
              />
            </Tooltip>
          </div>

          {barber ? (
            <div>
              <Typography variant="body2" className="text-gray-500">
                Prefere ser atendido por
              </Typography>
              <Typography variant="body1">
                {barber.givenName} {barber.familyName}
              </Typography>
            </div>
          ) : null}

          {serviceTypes.length ? (
            <div className="flex flex-wrap gap-2">
              {serviceTypes.map((serviceType) => (
                <Chip
                  key={serviceType.serviceTypeId}
                  label={serviceType.name}
                  color="default"
                  size="small"
                />
              ))}
            </div>
          ) : null}

          {queueStatus === "SERVING" ? (
            <Alert severity="info">
              <AlertTitle>Atendimento em andamento</AlertTitle>
              {servingAt ? <>Iniciou às {format(servingAt, "HH:mm")}.</> : null}
            </Alert>
          ) : null}

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-center">
            {queueStatus === "WAITING" ? (
              <>
                <LoadingButton
                  fullWidth
                  className="flex-1 sm:max-w-xs justify-self-center"
                  startIcon={<PlayArrow />}
                  color="secondary"
                  variant="contained"
                  loading={startServingClientStatus === "pending"}
                  disabled={startServingClientStatus === "success"}
                  onClick={handleStartServingClient}
                >
                  Iniciar atendimento
                </LoadingButton>
                <LoadingButton
                  fullWidth
                  className="sm:max-w-xs justify-self-center"
                  startIcon={<PersonRemove />}
                  color="error"
                  variant="outlined"
                  loading={clientQueueStatus === "pending"}
                  disabled={clientQueueStatus === "success"}
                  onClick={handleRemoveClientFromQueue({ wasServed: false })}
                >
                  Retirar da fila
                </LoadingButton>
              </>
            ) : null}
            {queueStatus === "SERVING" &&
            (linkInvite
              ? linkInvite?.linkedCompanyId === queueLinkInvite?.linkedCompanyId
              : establishment.companyId === queueCompanyId) ? (
              <LoadingButton
                fullWidth
                className="flex-1 sm:max-w-xs justify-self-center"
                startIcon={<CheckCircle />}
                color="success"
                variant="contained"
                loading={clientQueueStatus === "pending"}
                disabled={clientQueueStatus === "success"}
                onClick={handleRemoveClientFromQueue({ wasServed: true })}
              >
                Finalizar atendimento
              </LoadingButton>
            ) : null}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
