import { TextLabel } from "@/shared/components/text-label";
import { useAcceptAppointment } from "@/shared/hooks/establishment/appointments/use-accept-appointment";
import { useConcludeAppointment } from "@/shared/hooks/establishment/appointments/use-conclude-appointment";
import { useInvalidateListAppointments } from "@/shared/hooks/establishment/appointments/use-list-appointments";
import { useRejectAppointment } from "@/shared/hooks/establishment/appointments/use-reject-appointment";
import { Appointment, AppointmentStatus } from "@/shared/types/appointments";
import { Cancel, Check } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Chip, Typography } from "@mui/material";
import { useIsMutating } from "@tanstack/react-query";
import { addDays, format, isPast, isToday } from "date-fns";
import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { useAttending } from "@/contexts/attending/hook";

type Props = {
  appointment: Appointment;
  onAppointmentAccepted?(): void;
  onAppointmentRejected?(): void;
  onAppointmentConcluded?(): void;
};

export const AppointmentItem = ({
  appointment,
  onAppointmentAccepted,
  onAppointmentRejected,
  onAppointmentConcluded,
}: Props) => {
  const { linkInvite } = useEstablishmentContext();
  const { selectedDate } = useAttending();
  const { mutate: rejectAppointment, status: rejectAppointmentStatus } =
    useRejectAppointment();
  const { mutate: acceptAppointment, status: acceptAppointmentStatus } =
    useAcceptAppointment();
  const { mutate: concludeAppointment, status: concludeAppointmentStatus } =
    useConcludeAppointment();
  const invalidateListAppointments = useInvalidateListAppointments();
  const { showSnackbar } = useGlobalSnackbar();
  const isMutating = useIsMutating() > 0;

  const handleAcceptAppointment = () => {
    acceptAppointment(
      {
        establishmentId: appointment.establishmentId,
        appointmentId: `${appointment.appointmentAt}~${appointment.appointmentId}`,
        linkedEmail: linkInvite?.email,
      },
      {
        onSuccess() {
          showSnackbar({
            message: "Agendamento confirmado",
            severity: "success",
          });
          invalidateListAppointments({
            establishmentId: appointment.establishmentId,
            startDate: format(selectedDate, "yyyy-MM-dd'T03:00:00'"),
            endDate: format(addDays(selectedDate, 1), "yyyy-MM-dd'T02:59:59'"),
            linkedEmail: linkInvite?.email,
          });
          onAppointmentAccepted?.();
        },
        onError() {
          showSnackbar({
            message: "Erro ao confirmar agendamento",
            severity: "error",
          });
        },
      }
    );
  };

  const handleRejectAppointment = () => {
    rejectAppointment(
      {
        establishmentId: appointment.establishmentId,
        appointmentId: `${appointment.appointmentAt}~${appointment.appointmentId}`,
        linkedEmail: linkInvite?.email,
      },
      {
        onSuccess() {
          showSnackbar({
            message: "Agendamento cancelado",
            severity: "success",
          });
          invalidateListAppointments({
            establishmentId: appointment.establishmentId,
            startDate: format(selectedDate, "yyyy-MM-dd'T03:00:00'"),
            endDate: format(addDays(selectedDate, 1), "yyyy-MM-dd'T02:59:59'"),
            linkedEmail: linkInvite?.email,
          });
          onAppointmentRejected?.();
        },
        onError() {
          showSnackbar({
            message: "Erro ao cancelar agendamento",
            severity: "error",
          });
        },
      }
    );
  };

  const handleConcludeAppointment = () => {
    concludeAppointment(
      {
        establishmentId: appointment.establishmentId,
        appointmentId: `${appointment.appointmentAt}~${appointment.appointmentId}`,
        linkedEmail: linkInvite?.email,
      },
      {
        onSuccess() {
          showSnackbar({
            message: "Agendamento concluído",
            severity: "success",
          });
          invalidateListAppointments({
            establishmentId: appointment.establishmentId,
            startDate: format(selectedDate, "yyyy-MM-dd'T03:00:00'"),
            endDate: format(addDays(selectedDate, 1), "yyyy-MM-dd'T02:59:59'"),
            linkedEmail: linkInvite?.email,
          });
          onAppointmentConcluded?.();
        },
        onError() {
          showSnackbar({
            message: "Erro ao concluir agendamento",
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <Card variant="outlined" className="w-full">
      <CardContent className="flex flex-col gap-3">
        <div className="flex items-center gap-4">
          <Typography fontWeight="bold">
            {format(appointment.appointmentAt, "HH:mm")}
          </Typography>
          <Typography>{appointment.clientName}</Typography>
        </div>

        <TextLabel
          label="Serviços"
          text={
            <div className="flex flex-wrap items-center gap-2 mt-1">
              {appointment.serviceTypes.map((service) => (
                <Chip key={service.serviceTypeId} label={service.name} />
              ))}
            </div>
          }
        />

        {appointment.barber?.givenName ? (
          <TextLabel
            label="Prefere ser atendido por:"
            text={
              <Typography>
                {appointment.barber?.givenName} {appointment.barber?.familyName}
              </Typography>
            }
          />
        ) : null}

        {appointment.status === "ACCEPTED" ? (
          <Typography className="flex items-center gap-2 justify-center">
            <Check color="success" />
            Agendamento confirmado
          </Typography>
        ) : null}

        {appointment.status === "CONCLUDED" ? (
          <Typography
            className="flex items-center gap-2 justify-center"
            color="GrayText"
          >
            <Check color="inherit" />
            Agendamento concluído
          </Typography>
        ) : null}

        {appointment.status === "REJECTED" ? (
          <Typography
            color="GrayText"
            className="flex items-center gap-2 justify-center"
          >
            <Cancel color="inherit" /> Agendamento cancelado
          </Typography>
        ) : null}

        <div className="flex items-center justify-center gap-3">
          {!(["REJECTED", "CONCLUDED"] as AppointmentStatus[]).includes(
            appointment.status
          ) ? (
            <LoadingButton
              loading={rejectAppointmentStatus === "pending"}
              disabled={isMutating}
              color="error"
              onClick={handleRejectAppointment}
              fullWidth
            >
              Cancelar
            </LoadingButton>
          ) : null}

          {appointment.status === "PENDING" ? (
            <LoadingButton
              loading={acceptAppointmentStatus === "pending"}
              disabled={isMutating}
              variant="contained"
              color="success"
              onClick={handleAcceptAppointment}
              fullWidth
            >
              Aceitar
            </LoadingButton>
          ) : null}

          {(isToday(selectedDate) || isPast(selectedDate)) &&
          appointment.status === "ACCEPTED" ? (
            <LoadingButton
              disabled={isMutating}
              loading={concludeAppointmentStatus === "pending"}
              color="success"
              variant="contained"
              fullWidth
              onClick={handleConcludeAppointment}
            >
              Concluir
            </LoadingButton>
          ) : null}
        </div>
      </CardContent>
    </Card>
  );
};
