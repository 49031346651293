"use client";

import { PlayArrowRounded } from "@mui/icons-material";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { useState } from "react";
import { ResumeEstablishmentDialog } from "../top-bar-menu/resume-establishment-dialog";
import { useEstablishmentContext } from "@/contexts/establishment/hook";

export const AlertPausedQueue = () => {
  const { establishment } = useEstablishmentContext();
  const [resumeDialog, setResumeDialog] = useState(false);

  const showResumeDialog = () => setResumeDialog(true);
  const closeResumeDialog = () => setResumeDialog(false);

  if (establishment.status !== "CLOSED") return null;

  return (
    <>
      <Alert
        severity="warning"
        action={
          <Button
            variant="outlined"
            startIcon={<PlayArrowRounded />}
            onClick={showResumeDialog}
          >
            Retomar
          </Button>
        }
      >
        <AlertTitle>Sua fila esta pausada.</AlertTitle>
        Nenhum cliente poderá entrar na fila até que você retome.
        <Typography variant="body2" className="mt-2">
          {establishment.closeMessage}
        </Typography>
      </Alert>

      <ResumeEstablishmentDialog
        open={resumeDialog}
        establishment={establishment}
        onClose={closeResumeDialog}
      />
    </>
  );
};
