import { getWorkingHour } from "@/shared/helpers/working-hour";
import { QueueView } from "./queue-view";
import { BookingView } from "./booking-view";
import { Alert, AlertTitle, CircularProgress } from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import { isToday } from "date-fns";
import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { DatesView } from "./dates-view";
import { EstablishmentClosed } from "./establishment-closed";
import { AttendingProvider } from "@/contexts/attending/provider";
import { useAttending } from "@/contexts/attending/hook";
import { FabCreateBooking } from "./fab-create-booking";

export function ManageAttending() {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const { establishment } = useEstablishmentContext();

  return (
    <AttendingProvider establishment={establishment}>
      <div className="flex flex-col gap-y-6">
        <DatesView
          establishment={establishment}
          onSelectedDate={setSelectedDate}
          disablePast={false}
        />
        <AttendingView customDate={selectedDate} />
      </div>
    </AttendingProvider>
  );
}

const AttendingView = ({ customDate }: { customDate?: Date }) => {
  const { establishment } = useEstablishmentContext();
  const { setSelectedDate } = useAttending();
  const { attendingType, enabled } = getWorkingHour(establishment, customDate);
  const isCustomDateToday = customDate ? isToday(customDate) : false;

  useEffect(() => {
    if (customDate) setSelectedDate(customDate);
  }, [customDate, setSelectedDate]);

  if (!enabled)
    return (
      <>
        <EstablishmentClosed />
        <FabCreateBooking />
      </>
    );

  switch (attendingType) {
    case "QUEUE":
      return (
        <Suspense fallback={<CircularProgress className="mx-auto mt-8" />}>
          {isCustomDateToday ? (
            <QueueView />
          ) : (
            <Alert severity="info">
              <AlertTitle>Atendimento por fila</AlertTitle>
              Não é possível visualizar o atendimento por fila para uma data
              diferente de hoje.
            </Alert>
          )}
        </Suspense>
      );
    case "BOOKING":
      return (
        <Suspense fallback={<CircularProgress className="mx-auto mt-8" />}>
          <BookingView />
        </Suspense>
      );
    default:
      return null;
  }
};
