import { Button, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { EstablishmentWorkingHourInputs } from "./establishment-working-hour-inputs";
import { EstablishmentLocationInputs } from "./establishment-location-inputs";
import { useFormContext } from "react-hook-form";
import { EstablishmentInfoInputs } from "./establishment-info";
import { useState } from "react";
import { firstStepSchema, secondStepSchema, thirdStepSchema } from "./schemas";
import { LoadingButton } from "@mui/lab";
import { useIsMutating } from "@tanstack/react-query";

const steps = [
  {
    label: "Nome do estabelecimento",
    component: EstablishmentInfoInputs,
    schema: firstStepSchema,
  },
  {
    label: "Horário de funcionamento",
    component: EstablishmentWorkingHourInputs,
    schema: secondStepSchema,
  },
  {
    label: "Localização",
    component: EstablishmentLocationInputs,
    schema: thirdStepSchema,
  },
];

export const FormStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { watch } = useFormContext();
  const totalSteps = steps.length - 1;

  const nextStep = () => {
    setActiveStep((prev) => prev + 1);
  };

  const backStep = () => {
    setActiveStep((prev) => prev - 1);
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel>{step.label}</StepLabel>
          <StepContent>
            <div className="flex flex-col gap-y-4">
              <step.component />
              <StepsControl
                totalSteps={totalSteps}
                activeStep={activeStep}
                isValid={step.schema.isValidSync(watch())}
                onBack={backStep}
                onNext={nextStep}
              />
            </div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

const StepsControl = ({
  isValid,
  onNext,
  onBack,
  activeStep,
  totalSteps,
}: {
  totalSteps: number;
  activeStep: number;
  isValid: boolean;
  onNext(): void;
  onBack(): void;
}) => {
  const isMutating = useIsMutating() > 0;
  const { formState } = useFormContext();

  return (
    <div className="flex justify-end gap-x-3">
      {activeStep > 0 ? <Button onClick={onBack}>Voltar</Button> : null}
      {totalSteps !== activeStep ? (
        <Button variant="contained" disabled={!isValid} onClick={onNext}>
          Próximo
        </Button>
      ) : (
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isMutating}
          disabled={!formState.isValid}
        >
          Finalizar
        </LoadingButton>
      )}
    </div>
  );
};
